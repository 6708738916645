@use 'src/media' as media;
@use 'src/variables' as variables;

.notificationWrapper {
  position: fixed;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  filter: drop-shadow(10px 10px 100px rgba(0, 0, 0, 0.5));
}
.notificationSmall {
  width: 501px;
  height: 103px;
}
.notificationBig {
  width: 618px;
  height: 409px;
}
.parallelepiped {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('./images/parallelepipedDesktop.svg');

  @include media.phone {
    background-image: url('./images/parallelepipedPhone.svg');
  }
}
.bigBg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('./images/bigBg.svg');

  @include media.phone {
    background-image: url('./images/bigBgPhone.svg');
  }
}

.plateBg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 474px;
  height: 92px;
  padding: 18px 20px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;

  background: variables.$bgWhite;
  border-radius: 30px;

  p {
    font-weight: 700;

    @include variables.fs16;

    text-align: center;
    @include variables.titleColor();

    @include media.phone {
      @include variables.fs14;
    }
  }

  @include media.phone {
    width: 335px;
    height: 63px;
    gap: 8px;
  }
}
.plateBgBig {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 580px;
  height: 370px;
  padding: 70px 20px 60px 20px;
  display: flex;
  flex-direction: column;
  gap: 55px;
  justify-content: space-between;
  align-items: center;

  background: variables.$bgWhite;
  border-radius: 30px;

  p {
    font-weight: 700;

    @include variables.fs18;

    text-align: center;
    @include variables.titleColor();

    @include media.phone {
      @include variables.fs14;
    }
  }

  @include media.phone {
    width: 294px;
    height: 187px;
    padding: 30px 10px;
    gap: 8px;
  }
}
.imgCheck {
  @include media.phone {
    width: 20px;
    height: 20px;
  }
}
.imgSmile {
  @include media.phone {
    width: 22px;
    height: 22px;
  }
}

.exclamation {
  width: 50px;
  height: 56px;

  @include media.phone {
    width: 28px;
    height: 31px;
  }
}
.exit {
  width: fit-content;
  height: 19px;

  img {
    width: 20px;
    height: 19px;
  }

  @include media.phone {
    height: 15px;

    img {
      width: 16px;
      height: 15px;
    }
  }
}
.exitBig {
  position: absolute;
  top: 20px;
  right: 20px;
  width: fit-content;
  height: 20px;

  img {
    width: 21px;
    height: 20px;
  }
}

.notificationLogout {
  width: 620px;
  height: 297px;
}
.logoutBg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('./images/logoutBg.svg');

  @include media.phone {
    background-image: url('./images/logoutBgPhone.svg');
  }
}
.plateBgLogout {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 580px;
  height: 266px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  align-items: center;

  background: variables.$bgWhite;
  border-radius: 30px;

  p {
    font-weight: 700;

    @include variables.fs18;

    text-align: center;
    @include variables.titleColorAlt;

    @include media.phone {
      @include variables.fs16;
    }
  }

  @include media.phone {
    width: 336px;
    height: 192px;
    gap: 20px;
  }
}
.buttonsWrapper {
  width: 440px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @include media.phone {
    width: 310px;
  }

  button {
    font-weight: 500;
  }
}
