// Investors
.layout_Tokens
    .layout__investors
        gap: 2.75rem
        display: grid
        @include mediaMinWidthTablet
            grid-template-columns: 20.5rem auto
            gap: 4rem
        @include mediaMinWidthDesktop
            grid-template-columns: 25rem auto
            gap: 3.5rem
        @include mediaMinWidthDesktopBig
            grid-template-columns: 30rem auto
            gap: 14.5rem
    .investors__layout
        gap: 1.25rem
        display: flex
        flex-direction: column
    .investors__header,
    .investors__main
        max-width: 35rem
    .investors__text
        color: $colorPrimary800
    .investors__footer
        margin-top: auto
    .investors__tabs
        display: flex
        flex-direction: column
        .tabs__header
            position: relative
            &:not(:last-child)
                margin-bottom: 1rem
            &::before
                content: ""
                position: absolute
                bottom: 0
                left: 0
                width: 100%
                height: .125rem
                background-color: $colorFifth100
            .tabs__list
                gap: 3.25rem
                padding-bottom: 1rem
                display: flex
                overflow: hidden
                overflow-x: auto
                @include mediaMinWidthDesktop
                    gap: 3.75rem
            .tabs__item
                color: $colorFifth400
                font-size: 1rem
                line-height: 1.25rem
                font-weight: 500
                cursor: pointer
                position: relative
                @include transition
                @include mediaMinWidthMobile
                    font-size: 1.125rem
                    line-height: 1.125rem
                @include mediaMinWidthDesktop
                    font-size: 1.25rem
                    line-height: 1.25rem
                &::before
                    content: ""
                    position: absolute
                    bottom: -1rem
                    left: 0
                    width: 0
                    height: .125rem
                    background-color: $colorSecondary1000
                    @include transition
                &:hover,
                &.tabs__item_active
                    color: $colorFifth1000
                    &::before
                        width: 100%
        .tabs__main
            &:not(:last-child)
                margin-bottom: 1.5rem
        .tabs__link
            color: $colorFifth1000
            font-size: 1rem
            line-height: 1.25rem
            font-weight: 500
            gap: 1rem
            display: flex
            align-items: center
            &:hover
                .link__ico
                    transform: translateX(.25rem)
            .link__ico
                width: 1.25rem
                height: 1.25rem
                transform: translateX(0)
                @include transition
        

        
