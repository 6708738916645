@use '../../../../media' as media;

.section {
  display: grid;
  width: 100%;
  height: fit-content;
  padding-bottom: 16px;
  gap: 10px;

  //где-то нужен отступ 10px, а где-то 20px - во втором случае получаем через нулевую строку
  grid-template-rows: 190px min-content 0px 190px min-content;

  @include media.phone {
    grid-template-rows: 112px min-content 0px 112px min-content;
  }
  @include media.phoneV {
    grid-template-rows: 200px min-content 0px 112px min-content;
  }
}

.mainLeftSection {
  grid-template-columns: 850px 0px 330px;
  // a, b, c, d - не существующие блоки - пустота
  grid-template-areas:
    'mainImage a firstSideImage'
    'mainImage a firstSideText'
    'mainImage a b'
    'mainImage a secondSideImage'
    'mainText a secondSideText';

  @include media.mobileDevices {
    grid-template-columns: auto 0px 40%;
  }
  @include media.phoneV {
    grid-template-columns: 1fr 0px 1fr;
    grid-template-areas:
      'mainImage mainImage mainImage'
      'mainText mainText mainText'
      'b b b'
      'firstSideImage c secondSideImage'
      'firstSideText c secondSideText';
  }
}

.mainRightSection {
  grid-template-columns: 330px 0px 850px;
  grid-template-areas:
    'firstSideImage c mainImage'
    'firstSideText c mainImage'
    'd c mainImage'
    'secondSideImage c mainImage'
    'secondSideText c mainText';

  @include media.mobileDevices {
    grid-template-columns: 40% 0px auto;
  }
  @include media.phoneV {
    grid-template-columns: 1fr 0px 1fr;
    grid-template-areas:
      'mainImage mainImage mainImage'
      'mainText mainText mainText'
      'b b b'
      'firstSideImage c secondSideImage'
      'firstSideText c secondSideText';
  }
}

.mainImage {
  grid-area: mainImage;
}

.firstSideImage {
  grid-area: firstSideImage;
}

.secondSideImage {
  grid-area: secondSideImage;
}

.mainText {
  grid-area: mainText;
  height: fit-content;
}

.firstSideText {
  grid-area: firstSideText;
  height: fit-content;
}

.secondSideText {
  grid-area: secondSideText;
  height: fit-content;
}
