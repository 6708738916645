@use 'src/media' as media;
@use 'src/variables' as variables;

.wrapper {
  width: 100%;
  height: fit-content;
  margin-top: 10px;

  border-radius: 20px;
  background: variables.$blockBorderColor;
  border: 2px solid variables.$blockBorderColor;

  @include media.phone {
    margin-top: 20px;
  }
}

.chartTable {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 62% 1px 1fr;
  grid-template-rows: repeat(2, 25px 1px 236px 1px) 25px;
  grid-template-areas:
    'c l title1'
    'c l l1'
    'c l window1'
    'c l l2'
    'c l title2'
    'c l l3'
    'c l window2'
    'c l l4'
    'c l title3';

  background-color: variables.$mainColorForButtons;
  border-radius: 18px;

  @include media.tablet {
    grid-template-columns: 58% 1px 1fr;
  }
  @include media.phone {
    display: none;
  }
}

.chart {
  grid-area: c;
  padding: 10px;

  background-color: variables.$bgWhite;

  border-radius: 18px 0px 0px 18px;
}
.phoneOnly {
  display: none;

  .chart {
    height: 100%;
    border-radius: 18px 18px 18px 18px;
  }

  @include media.phone {
    display: block;
    height: 555px;
  }
}

.firstRowWrapper {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-family: 'Inter';
  @include variables.fs18;
}

.elementWrapper {
  display: flex;
  align-items: center;
  gap: 10px;

  @include media.phone {
    gap: 4px;
  }

  span {
    @include variables.fs18;

    @include media.phone {
      @include variables.fs16;
    }
  }
}
.elementIcon {
  max-width: 30px;
  max-height: 30px;
}

.chartContainer {
  height: 491px;
  width: 100%;
}

.chart-page {
  height: 100% !important;

  .chart-container {
    height: 100% !important;
    overflow: visible !important;
  }

  .group-wWM3zP_M- {
    height: 100% !important;
  }

  .layout__area--left {
    position: relative !important;
    height: 100% !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;

    .inner-wWM3zP_M- {
      position: relative !important;
      height: auto !important;
      display: flex !important;
      flex-direction: column !important;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 3px;

      &:hover {
        background: #555;
      }
    }

    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
  }
}
