@use 'src/media' as media;
@use 'src/variables' as variables;

.planets {
  display: grid;
  grid-template-columns: 50%;
  grid-template-rows: min-content auto min-content;
  place-content: center;
  place-items: center;
  gap: 60px;
  margin-top: calc(-40px - 70px);
  margin-bottom: calc(-40px - 70px);
  height: 876px;
  background-image: url('../../../public/images/404bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  h3 {
    font-weight: 400;
    @include variables.fs18;
  }
  img,
  svg {
    width: 100%;
    height: auto;
  }
  button {
    width: 414px;
    @include media.phoneV {
      width: 298px;
    }
  }

  @include media.mobileDevices {
    grid-template-columns: 85%;
  }
  @include media.tablet {
    margin-top: -20px;
  }
}
