@import '../../helper/media';
@import '../../helper/variables';
// steps
.p2p {
  .steps {
    .steps__header,
    .steps__main,
    .steps__footer {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }

    .steps__header {
      .steps__list {
        margin: 0 -0.375rem;
        width: calc(100% + 0.75rem);
        display: flex;
        justify-content: center;
        @include mediaMinWidthMobile {
          margin: 0 -0.5rem;
          width: calc(100% + 1rem);
        }
      }

      .steps__item {
        width: 33.3%;
        padding: 0 0.375rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        @include mediaMinWidthMobile {
          padding: 0 1.25rem;
        }

        &:not(:first-child) {
          &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 0.125rem;
            background-color: #eeeeee;
            top: 1rem;
            right: 50%;
          }
        }

        &.steps__item_active {
          &:before {
            background-color: $colorPrimary400;
          }

          .steps__index {
            color: $colorWhite;
            background-color: $colorPrimary400;

            .index__text {
              color: $colorWhite;
            }
          }

          .steps__title {
            .title__text {
              color: $colorPrimary400;
            }
          }
        }
      }

      .steps__index {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        color: #777a83;
        background-color: #eeeeee;
        box-shadow: 0 0 0 0.25rem $colorPrimary100;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
        z-index: 2;
        @include transition;

        &:not(:last-child) {
          margin-bottom: 4px;
        }

        .index__text {
          font-size: 1.25rem;
          line-height: 1.25rem;
          font-weight: 500;
          display: block;
        }
      }

      .steps__title {
        color: #777a83;
        font-weight: 500;
        text-align: center;
        @include transition;

        .title__text {
          font-size: 0.75rem;
          line-height: 0.75rem;
          display: block;
          @include mediaMinWidthTablet {
            font-size: 1rem;
            line-height: 1.25rem;
            font-weight: 500;
          }
        }
      }
    }
  }
}
