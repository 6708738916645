$primaryColor: radial-gradient(150.69% 118.33% at 123.17% 100%, #c6fff1 0%, #e7c8ff 100%);

@mixin fs32() {
  font-size: 32px;
  line-height: 39px;
}

@mixin fs26() {
  font-size: 26px;
  line-height: 31px;
}

@mixin fs20() {
  font-size: 20px;
  line-height: 24px;
}

@mixin fs18() {
  font-size: 18px;
  line-height: 22px;
}

@mixin fs16() {
  font-size: 16px;
  line-height: 19px;
}

@mixin fs14() {
  font-size: 14px;
  line-height: 17px;
}

@mixin fs12() {
  font-size: 12px;
  line-height: 15px;
}

@mixin fs12Second() {
  font-size: 12px;
  line-height: 13px;
}

@mixin titleColor() {
  background: #8c00fd;
  background: linear-gradient(135deg, #8c00fd 0%, #a800c0 100%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

@mixin titleColorAlt() {
  background: #7f00e2;
  background: radial-gradient(145.02% 191.6% at 123.17% 100%, #003629 0%, #7f00e2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

@mixin primaryColor() {
  background: radial-gradient(150.69% 118.33% at 123.17% 100%, #c6fff1 0%, #e7c8ff 100%);
}

@mixin hoverBgSet($color) {
  @media (hover: hover) {
    &:hover {
      background: $color;
    }
  }
  &:active {
    background: $color;
  }
}

@mixin hoverLinkSet() {
  @media (hover: hover) {
    &:hover {
      background: radial-gradient(150.69% 118.33% at 123.17% 100%, #003629 0%, #7f00e2 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }
  &:active {
    background: radial-gradient(150.69% 118.33% at 123.17% 100%, #003629 0%, #7f00e2 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
}

$mainColorForButtons: #efebff;
$mainColorForButtonBorder: #e7c8ff;
$hoverColorForButtons: linear-gradient(135deg, #8c00fd 0%, #a800c0 100%);
$hoverActiveColorForButtons: linear-gradient(135deg, #7600d7 0%, #8f00a2 100%);
$clickColorForButtons: radial-gradient(145.02% 191.6% at 123.17% 100%, #003629 0%, #7f00e2 100%);
$clickActiveColorForButtons: radial-gradient(145.02% 191.6% at 123.17% 100%, #00281e 0%, #6700b7 100%);
$buttonActiveShadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
$blockBorderColor: #abaeef;
$inputBgDisabled: #efefef;
$bgWhite: #ffffff;
$bgLight: #f8f9fd;
$fontBlack: #000000;
$fontGreen: #00d09e;
$buttonGreenHover: #00ba8d;
$fontRed: #ff004d;
$buttonRedHover: #dd0043;
$grey: #666;

$colorWhite: rgba(255, 255, 255, 1);
$colorTransparent: rgba(255, 255, 255, 0.65);
// primary color
$colorPrimary100: rgba(247, 247, 247, 1);
$colorPrimary200: rgba(32, 38, 57, 0.2);
$colorPrimary300: rgba(32, 38, 57, 0.5);
$colorPrimary400: rgba(32, 38, 57, 1);
$colorPrimary500: #16192c;
// secondary color
$colorSecondary300: rgba(0, 208, 158, 0.05);
$colorSecondary400: rgba(26, 239, 188, 1);
$colorSecondary500: rgba(0, 208, 158, 1);
// tertiary color
$colorTertiary300: rgba(255, 0, 76, 0.05);
$colorTertiary400: rgba(255, 82, 133, 1);
$colorTertiary500: rgba(255, 0, 76, 1);
// quarty color
$colorQuartyty400: rgba(202, 116, 254, 1);
$colorQuartyty400: rgba(167, 23, 255, 1);

// fonts
$mainFont: 'Roboto', sans-serif;

// mixin
@mixin transition() {
  transition: all 0.4s ease-in-out;
}
@mixin transform() {
  transform: translate(-50%, -50%);
}
@mixin transform-x() {
  transform: translateX(-50%);
}
@mixin transform-y() {
  transform: translateY(-50%);
}
@mixin transform-none() {
  transform: translate(0, 0);
}

@mixin box-shadow() {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.03);
}
@mixin box-shadowSecondary() {
  box-shadow: 0 0.2rem 0.75rem rgba(0, 0, 0, 0.25);
}
