@import 'src/media';
@import 'src/variables';
.converter {
  background-color: $colorWhite;
  border-radius: 1.5rem;
  padding: 1.5rem;
  @include mediaMinWidthDesktop {
    padding: 2rem;
  }

  .formular__group {
    &:not(:last-child) {
      margin-bottom: 2.5rem;
    }
  }

  .converter__order {
    strong {
      font-weight: 600;
    }

    .order__revert {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem auto;
      cursor: pointer;

      img,
      svg {
        width: 1rem;
        height: auto;
      }
    }

    .group__list {
      margin-bottom: -1rem;
      display: flex;
      flex-direction: column;
    }

    .group__item {
      color: $colorPrimary300;
      font-size: 1rem;
      line-height: 1.25rem;
      border-radius: 1.5rem;
      padding: 1.5rem;
      margin-bottom: 1rem;
      background-color: $colorPrimary100;
      min-height: 8.75rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .order__header {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }

    .order__main {
      .order__list {
        flex-direction: column;
        align-items: flex-start;
        @include desktop {
          flex-direction: row;
          align-items: flex-end;
        }
      }
    }

    .order__list {
      margin: 0 -0.5rem -0.5rem;
      width: calc(100% + 1rem);
      display: flex;
      justify-content: space-between;
    }

    .order__item {
      padding: 0 0.5rem;
      margin-bottom: 0.5rem;

      &:nth-child(2) {
        text-align: right;
      }
    }

    .order__price {
      .price__header {
        &:not(:last-child) {
          margin-bottom: 0.25rem;
        }
      }

      .price__main {
        .price__input {
          border: 0;
          color: $colorPrimary400;
          background: none;
          font-size: 2rem;
          line-height: 2.5rem;
          font-weight: 400;
          font-family: 'Roboto', sans-serif;
          padding: 0;
          width: 100%;
          border-radius: 0;
          margin: 0;
          text-overflow: ellipsis;
          box-sizing: border-box;
          appearance: none;
          outline: none;

          &::-webkit-input-placeholder,
          .form-item__input::-moz-placeholder,
          &:-ms-input-placeholder,
          &t::-ms-input-placeholder,
          &::placeholder {
            opacity: 1;
            color: $colorPrimary300;
          }
        }
      }
    }

    .order__symbol {
      padding: 0.5rem;
      background-color: $colorWhite;
      display: flex;
      align-items: center;
      border-radius: 2rem;
      cursor: pointer;
      @include box-shadow;

      .symbol__media,
      .symbol__title,
      .symbol__action {
        &:not(:last-child) {
          margin-right: 0.5rem;
        }
      }

      .symbol__media {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        display: flex;
        align-items: flex-start;

        img,
        svg {
          width: 100%;
          height: 100%;
        }
      }

      .symbol__title {
        color: $colorPrimary400;
        font-weight: 600;
      }

      .symbol__action {
        width: 1rem;
        height: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;

        img,
        svg {
          width: 0.75rem;
          height: auto;
        }
      }
    }
  }

  .converter__currency {
    .currency__search {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }

    .currency__groups {
      display: flex;
      flex-direction: column;
      max-height: 450px;
      overflow-y: auto;
      padding-right: 10px;

      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background: $colorPrimary100;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(167, 23, 255, 0.4);
        border-radius: 3px;
        opacity: 0.5;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: rgba(167, 23, 255, 0.5);
      }
    }

    .currency__group {
      display: flex;
      align-items: center;
      padding: 10px 0;

      &:not(:last-child) {
        border-bottom: 1px solid $colorPrimary100;
      }
      &:hover {
        cursor: pointer;
        background-color: $colorPrimary100;
      }
    }

    .currency__media {
      width: 3rem;
      min-width: 3rem;
      height: 3rem;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      &:not(:last-child) {
        margin-right: 1rem;
      }

      img,
      svg {
        width: 100%;
        height: 100%;
      }
    }

    .currency__main {
      width: 100%;
    }

    .currency__list {
      display: flex;
      flex-direction: column;
    }

    .currency__item {
      color: $colorPrimary300;
      font-size: 1rem;
      line-height: 1.125rem;
      font-weight: 400;
      display: flex;
      justify-content: space-between;

      &.currency__item_second {
        color: $colorPrimary400;
        font-weight: 600;
      }

      &:not(:last-child) {
        margin-bottom: 0.25rem;
      }
    }

    .currency__text {
      &:nth-child(2) {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        display: block;
        text-align: right;
      }
    }

    @media (hover: none) and (pointer: coarse) {
      .currency__groups {
        &::-webkit-scrollbar {
          width: 6px;
        }
      }
    }
  }

  .converter__cart {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    .cart__revert {
      width: 3rem;
      height: 1.5rem;
      margin: 1.25rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .cart__list {
      display: flex;
      flex-direction: column;
    }

    .cart__item {
      display: flex;
    }

    .cart__media {
      width: 3rem;
      min-width: 3rem;
      height: 3rem;
      display: flex;
      align-items: flex-start;

      &:not(:last-child) {
        margin-right: 1rem;
      }

      img,
      svg {
        width: 100%;
        height: 100%;
      }
    }

    .cart__main {
      color: $colorPrimary400;
      font-size: 1.25rem;
      line-height: 1.25rem;
      font-weight: 600;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .cart__text {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  .converter__checkout {
    padding: 1.5rem;
    border-radius: 0.75rem;
    background-color: $colorPrimary100;

    .checkout__list {
      display: flex;
      flex-direction: column;
    }

    .checkout__item {
      font-size: 1rem;
      line-height: 1.25rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      &.checkout__item_second {
        .checkout__text {
          color: $colorPrimary400;

          &:nth-child(1) {
            font-weight: 600;
          }

          &:nth-child(2) {
            font-size: 2rem;
            line-height: 2rem;
            font-weight: 400;
          }
        }
      }
    }

    .checkout__text {
      color: $colorPrimary300;
      white-space: nowrap;

      &:nth-child(2) {
        font-weight: 600;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
      }

      &:not(:last-child) {
        margin-right: 0.5rem;
        @include mediaMinWidthMobile {
          min-width: 40%;
        }
      }

      &.checkout__text_second {
        color: $colorSecondary500;
      }
    }
  }
}
