@import '../../helper/media';
@import '../../helper/variables';
// data
.data {
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
  .data__list {
    margin-bottom: -0.5rem;

    &.data__list_second {
      margin-bottom: -1.5rem;
      .data__item {
        margin-bottom: 1.5rem;
      }
    }
    &.data__list_tertiary {
      margin-bottom: -1.25rem;
      .data__item {
        margin-bottom: 1.25rem;
      }
    }
    &.data__list_row {
      .data__item {
        margin-bottom: 0.5rem;
        display: flex;
        flex-direction: column;
        @include mediaMinWidthMobile {
          flex-direction: row;
          align-items: center;
        }
      }
      .data__header {
        &:not(:last-child) {
          margin-bottom: 0.25rem;
          @include mediaMinWidthMobile {
            margin-bottom: 0;
            margin-right: 0.5rem;
          }
        }
      }
      .data__main {
        @include mediaMinWidthMobile {
          margin-left: auto;
        }
        &:not(:last-child) {
          margin-right: 0.25rem;
        }
      }

      &.data__list_row-only {
        .data__item {
          flex-direction: row;
          align-items: center;
        }
        .data__header {
          &:not(:last-child) {
            margin-bottom: 0;
            margin-right: 1rem;
          }
        }
        .data__main {
          margin-left: auto;
        }
      }
    }
    &.data__list_column {
      margin: 0 -1.25rem -1.25rem 0;
      display: flex;
      flex-wrap: wrap;
      .data__item {
        display: flex;
        flex-direction: column;
        margin: 0 1.25rem 1.25rem 0;
      }
      .data__header {
        &:not(:last-child) {
          margin-bottom: 0.25rem;
        }
        &.data__header_secondary {
          &:not(:last-child) {
            margin-bottom: 0.75rem;
          }
        }
      }
      .data__main {
        &:not(:last-child) {
          margin-bottom: 0.25rem;
        }
      }
    }
  }

  .data__item {
    margin-bottom: 1.5rem;
    &.data__item_second {
      padding: 0.5rem 0.5rem 0.5rem 2rem;
      border-radius: 1rem;
      height: 2rem;
      background-color: $colorWhite;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: $colorTertiary300;
      }
      .data__header {
        .data__title {
          color: $colorPrimary500;
          font-size: 0.875rem;
          line-height: 1rem;
        }
      }
    }
    &.data__item_tiny {
      .data__header {
        &:not(:last-child) {
          margin-bottom: 0;
          margin-right: 0.5rem;
        }
        .data__title {
          color: $colorPrimary300;
          font-size: 0.75rem;
          line-height: 0.75rem;
        }
      }
    }
  }
  .data__header {
    display: flex;
    .data__title {
      color: $colorPrimary300;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-family: $secondFont;
    }
  }
  .data__main {
    display: flex;
    align-items: center;
    .data__text {
      color: $colorPrimary400;
      font-size: 1rem;
      line-height: 1.25rem;
      font-weight: 500;
      letter-spacing: -0.1px;
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
      &.data__text_second {
        color: $colorQuartyty500;
        font-size: 1.25rem;
        line-height: 1.25rem;
      }
    }
    .data__ico {
      width: 1rem;
      min-width: 1rem;
      height: 1rem;
      cursor: pointer;
      &:hover {
        img,
        svg {
          path {
            fill: $colorQuartyty300;
          }
        }
      }
      img,
      svg {
        width: 100%;
        height: 100%;
        path {
          fill: $colorPrimary300;
          @include transition;
        }
      }
    }
  }
  .data__toggle {
    .data__text {
      color: $colorPrimary300;
      font-size: 0.75rem;
      line-height: 1.25rem;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: $colorPrimary700;
      }
    }
  }
}
.p2p__chat_second {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  .control__list {
    display: flex;
    gap: 0.75rem;
  }
  .btn {
    min-width: 10rem;
    @include mediaMinWidthMobile {
      min-width: 14rem;
    }
  }
}
