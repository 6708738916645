@import '../../helper/media';
@import '../../helper/variables';
// info
.appeal__info {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;
  @include mediaMinWidthMobile {
    grid-template-columns: 3rem auto;
  }
  @include mediaMinWidthTablet {
    grid-template-columns: 3rem auto 18rem;
  }
  .info__media {
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
  }
  .info__footer {
    @include mediaMinWidthMobile {
      padding: 0 3rem;
      display: grid;
      grid-column: span 2;
    }
    @include mediaMinWidthTablet {
      padding: 0;
      grid-column: unset;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn {
      width: auto;
      @include mediaMinWidthTablet {
        width: 100%;
      }
    }
  }
  .info__main {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.75rem;
  }
  .info__title {
    .title__text {
      color: $colorPrimary400;
      font-size: 1.25rem;
      line-height: 1.5rem;
      font-weight: 500;
    }
  }
  .info__data {
    margin: 0 !important;
    ul {
      color: $colorPrimary400;
      font-size: 0.875rem;
      line-height: 1rem;
      opacity: 0.5;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0.75rem;
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
  .info__help {
    color: $colorQuartyty500;
    font-size: 0.875rem;
    line-height: 1rem;
    display: grid;
    grid-template-columns: 1rem auto;
    grid-gap: 1rem;
  }
}

.appeal__help {
  width: 100%;
  max-width: 35rem;
  margin: 0 auto 1.5rem;
  display: grid;
  justify-content: center;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  @include mediaMinWidthMobile {
    grid-template-columns: 1fr 1fr;
  }
}
