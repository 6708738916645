@use 'src/media' as media;
@use 'src/variables' as variables;

.wrapper {
  width: 965px;
  height: fit-content;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 20px;
  background: variables.$bgWhite;
  border: 1px solid variables.$blockBorderColor;

  @include media.mobileDevices {
    width: 100%;
  }
  @include media.phoneV {
    padding: 10px;
  }
}

.firstRow {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  button {
    width: 163px;
  }
}

.title {
  @include variables.fs32;
  font-weight: 400;
  color: #000000;

  @include media.phoneH {
    @include variables.fs20;
  }
  @include media.phoneV {
    @include variables.fs18;
  }
}

.table {
  display: grid;
  grid-template-columns: repeat(7, auto);
  gap: 0 4px;
  margin-bottom: 20px;

  overflow: hidden;
  @include media.phone {
    grid-template-columns: repeat(6, auto);
    margin-bottom: 10px;
  }
  @include media.phoneV {
    grid-template-columns: repeat(4, auto);
    margin: 0 -5px 10px -5px;
  }
}
.titleBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;

  background: variables.$mainColorForButtons;
  border-radius: 20px 20px 0px 0px;
}
.cell {
  width: 100%;
  height: 51px;
  display: flex;
  align-items: center;

  @include variables.fs12;

  &:not(.titleCell) {
    word-break: break-all;
    @include media.tablet {
      max-width: 138px;
    }
    @include media.phone {
      max-width: 120px;
    }
    @include media.phoneV {
      justify-content: center;
    }
  }
}
button.cell {
  @include variables.fs14;
  color: variables.$fontRed;
}
.phoneHidden {
  @include media.phone {
    display: none;
  }
}
.phoneVHidden {
  @include media.phoneV {
    display: none;
  }
}
.titleCell {
  height: 64px;

  font-weight: 500;
  @include variables.fs14;
  line-height: 13px;

  &:nth-of-type(1) {
    padding-left: 10px;

    @include media.phoneV {
      padding-left: 5px;
    }
  }
}
.line {
  height: 1px;
  grid-column: 1 / 8;

  background: radial-gradient(96.1% 93.99% at 0% 2.73%, #ccfff3 0%, #c985ff 100%);

  @include media.phone {
    grid-column: 1 / 7;
  }
  @include media.phoneV {
    grid-column: 1 / 5;
  }
}
