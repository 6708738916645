// Header

.layout__header
  .container
    width: 85rem
    max-width: calc(100vw - 2rem)
  .btn
    color: #ffffff
    background-color: #202639
    border: 0.0625rem solid #202639
    font-size: 1rem
    line-height: 1rem
    font-weight: 500
    padding: 0.75rem
    min-width: 2.5rem
    height: 2.5rem
    border-radius: 2rem
    margin: 0
    text-decoration: none
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    outline: none
    text-align: center
    appearance: none
    @include transition
    &:hover 
      color: #202639
      background: #ffffff
      border-color: #202639
      text-decoration: none