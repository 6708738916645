@use 'src/media' as media;
@use 'src/variables' as variables;

.wrapper {
  width: 100%;
  height: fit-content;
  margin-top: 26px;
}

.borderWrapper {
  position: relative;
  width: 100%;
  padding-bottom: 0.016px;
  z-index: 2;

  border-radius: 10px 20px 20px 20px;

  @include media.phoneV {
    padding-top: 8px;
    border-radius: 0px 20px 20px 20px;
  }
}

.mask {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 42px;
  display: grid;
  grid-template-columns: 210px 10px 5px 10px 190px 10px 5px 10px 190px 10px auto;
  grid-template-areas: 'button1 mask1 rec0 mask2 button2 mask3 rec4 mask5 button2 mask6 rec';

  @include media.phone {
    height: 34px;
    grid-template-columns: 136px 10px 8px 10px 78px 10px 8px 10px 78px 10px auto;
  }
}

.white {
  background: variables.$bgLight;
}
.rec {
  grid-area: rec;
}
.rec2 {
  grid-area: rec2;
}
.maskRotate {
  transform: rotate(-90deg);
}

.navWrapper {
  height: 42px;
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: 220px 210px 210px auto;
  gap: 5px;

  z-index: 1;

  @include media.phone {
    grid-template-columns: 146px 98px 98px auto;

    gap: 8px;
  }

  .navButton {
    width: 100%;
  }

  .navButtonActive {
    width: 100%;
  }
}

.navButton {
  height: 42px;
  padding-top: 5px;
  margin: 0;

  @include variables.fs20;

  background: variables.$mainColorForButtons;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid variables.$mainColorForButtonBorder;
  border-bottom: 0;

  @include media.phone {
    height: 34px;
    @include variables.fs14;
  }
}

.navButtonActive {
  height: 44px;
  padding-top: 0px;

  background: variables.$bgWhite;
  border-radius: 8px 8px 0px 0px;
  border: 2px solid variables.$blockBorderColor;
  border-bottom: 0;

  span {
    font-weight: 600;

    background: linear-gradient(135deg, #8c00fd 0%, #a800c0 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  @include media.phone {
    height: 36px;
  }
}

.phoneVNavButtonsWrapper {
  display: none;
  @include media.phoneV {
    width: 100%;
    height: 35px;
    display: grid;
    justify-content: space-around;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);

    font-weight: 400;
    @include variables.fs12;
  }
}
.phoneVNavButton {
  height: 17px;
  span {
    color: #000;
  }
}
.phoneVNavButtonActive {
  span {
    position: relative;
    font-weight: 600;
    white-space: nowrap;

    @include variables.titleColor;
  }
  span:before {
    content: '';
    position: absolute;
    top: calc(100% + 3px);
    width: 100%;
    left: 0;
    height: 2px;

    background: variables.$hoverColorForButtons;
  }
}

.content {
  background: variables.$bgWhite;
  border-radius: 0px 20px 20px 20px;
  border: 2px solid variables.$blockBorderColor;

  @include media.phone {
    margin-top: -8px;
  }
}
.mask20 {
  position: absolute;
  top: -2px;
  right: 0;
  width: 20px;
  height: 20px;
}

.row {
  z-index: 2;
  position: relative;
  width: calc(100% + 2px);
  height: 29px;
  padding: 0 20px;
  display: grid;

  grid-template-columns: 18% 18% 22% 24% 15%;

  background: variables.$bgWhite;

  h3 {
    align-self: center;

    font-weight: 500;
    @include variables.fs18;

    @include media.phoneH {
      @include variables.fs14;
    }
    @include media.phoneV {
      @include variables.fs12;
    }
  }

  span {
    align-self: center;

    font-weight: 400;
    @include variables.fs14;

    @include media.phone {
      @include variables.fs12;
    }
    @include media.phoneV {
      font-size: 11px;
    }
  }

  &.green .type {
    color: #00d09e;
  }
  &.red .type {
    color: #ff004d;
  }

  @include media.phone {
    &.green .price {
      color: #00d09e;
    }
    &.red .price {
      color: #ff004d;
    }
  }

  @include media.tablet {
    grid-template-columns: 13% 14% 25% 27% auto;
  }
  @include media.phoneH {
    padding: 0 10px;
    grid-template-columns: 27% 27% 20% auto;
  }
  @include media.phoneV {
    padding: 0 10px;
    grid-template-columns: repeat(3, 1fr);
  }
}

.row7 {
  //grid-template-columns: 15% 12% 14% 16% 16% 19% 8%;
  grid-template-columns: repeat(7, auto);
  @include media.phoneH {
    //grid-template-columns: 23% 23% 23% 23% 8%;
    grid-template-columns: repeat(4, auto);
  }
  @include media.phoneV {
    //grid-template-columns: 30% 30% 32% 14%;
    grid-template-columns: repeat(3, auto);
  }
}

.type {
  font-weight: 500;

  @include media.phone {
    display: none;
  }
}
.cancelImage {
  align-self: center;
}
.cancelBtn {
  color: #ff004d;
  text-decoration-line: underline;
  cursor: pointer;
}
.mobileOnly {
  display: none;
  @include media.mobileDevices {
    display: block;
  }
}
.mobileHidden {
  @include media.mobileDevices {
    display: none;
  }
}
.phoneHidden {
  @include media.phone {
    display: none;
  }
}
.phoneHHidden {
  @include media.phoneH {
    display: none;
  }
}
.phoneVHidden {
  @include media.phoneV {
    display: none;
  }
}
.phoneOnly {
  display: none;
  @include media.phone {
    display: block;
  }
}
.phoneHOnly {
  display: none;
  @include media.phoneH {
    display: block;
  }
}
.phoneVOnly {
  display: none;
  @include media.phoneV {
    display: block;
  }
}
.title {
  width: calc(100% - 4px);
  height: 47px;
  margin: 2px 2px 0px 2px;
  border-radius: 0px 18px 0px 0px;
  background: variables.$bgWhite;
}

.elementWrapper {
  display: flex;
  gap: 20px;

  @include media.phone {
    gap: 5px;
  }
}

.borderRadius {
  width: calc(100% - 4px);
  margin-left: 2px;
  overflow: hidden;

  background: variables.$bgWhite;
  border-radius: 0px 0px 18px 18px;
}

.viewport {
  height: 462px;

  border-radius: 0px 0px 18px 18px;

  &::-webkit-scrollbar {
    width: 4px; /* ширина scrollbar */
  }
  &::-webkit-scrollbar-track {
    background: variables.$bgWhite;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #7f00e2;
    border-radius: 999px;
  }
}

.coloredRow {
  background: linear-gradient(135deg, #f9f2ff 0%, #fbf2fc 100%);
}
