// Categories
.layout_Tokens
    .layout__categories
        gap: .25rem
        display: grid
        align-items: flex-start
        @include mediaMinWidthTablet
            grid-template-columns: 17.5rem auto
        @include mediaMinWidthDesktop
            grid-template-columns: 21.25rem auto
        .section__tabs
            justify-content: flex-start
    .categories__sidebar
        gap: .75rem
        display: flex
        flex-direction: column
        align-items: flex-start
        @include mediaMinWidthTablet
            gap: 2rem
        .sidebar__header
            padding: 1.25rem
    .categories__nav

        @include mediaMinWidthTablet
            margin: 0 -1.25rem
            width: calc(100% + 2.5rem)
        .nav__list
            gap: 1rem
            max-width: calc(100vw - 3.5rem)
            display: flex
            flex-direction: row
            overflow: hidden
            overflow-x: auto
            @include mediaMinWidthTablet
                gap: 0 
                flex-direction: column
        .nav__item
            gap: 1rem
            padding: .75rem 1rem
            border-radius: 2rem
            flex-shrink: 0
            display: flex
            align-items: center
            background-color: $colorWhite1000
            cursor: pointer
            @include transition
            @include mediaMinWidthTablet
                background-color: transparent
            &:hover,
            &.nav__item_active
                background-color: $colorSecondary1000
                .nav__ico
                    background-color: $colorWhite200
                .nav__text
                    color: $colorWhite1000
            @include mediaMinWidthTablet
                border: 0
                border-radius: 0
                border-bottom: 1px solid $colorFifth100
        .nav__ico
            width: 2rem
            height: 2rem
            font-size: 1rem
            line-height: 1.25rem
            flex-shrink: 0
            display: flex
            justify-content: center
            align-items: center
            border-radius: 50%
            background-color: $colorWhite1000
            @include transition
            @include mediaMinWidthMobile
                width: 2.5rem
                height: 2.5rem
            @include mediaMinWidthTablet
                width: 3.25rem
                height: 3.25rem
                font-size: 1.25rem
                line-height: 1.5rem
        .nav__text
            color: $colorFifth500
            font-size: .875rem
            line-height: 1rem
            font-weight: 500
            @include transition
            @include mediaMinWidthMobile
                font-size: 1rem
                line-height: 1.25rem
            @include mediaMinWidthDesktop
                font-size: 1.25rem
                line-height: 1.5rem

    .categories__item
        gap: 2rem
        display: grid
        @include mediaMinWidthDesktop
            gap: 3.25rem
        .category__header
            display: flex
            justify-content: space-between
        .category__title
            max-width: 32.5rem
        .category__ico
            width: 3.75rem
            height: 3.75rem
            font-size: 2rem
            line-height: 2.5rem
            flex-shrink: 0
            display: flex
            justify-content: center
            align-items: center
            border-radius: 50%
            background-color: $colorWhite1000
            @include transition
            @include mediaMinWidthMobile
                width: 5rem
                height: 5rem
            @include mediaMinWidthTablet
                width: 5.75rem
                height: 5.75rem
        .category__main
            display: grid
            gap: 2rem
            @include mediaMinWidthTablet
                gap: 3rem
    .category__group
        display: grid
        gap: 1.5rem
    .category__steps
        .steps__list
            gap: .25rem
            display: grid
            @include mediaMinWidthMobile
                grid-template-columns: repeat(2, 1fr)
            @include mediaMinWidthTablet
                grid-template-columns: repeat(3, 1fr)
        .steps__item
            &:hover
                .steps__link
                    color: $colorWhite1000
                    background-color: $colorSecondary1000
                    .steps__title
                        background-color: $colorWhite200
                    .steps__arrow
                        fill: $colorWhite1000
        .steps__link
            color: $colorPrimary1000
            gap: .5rem
            padding: 1rem
            min-height: 10rem
            border-radius: .75rem
            justify-content: space-between
            align-items: flex-start
            display: flex
            flex-wrap: wrap
            background-color: $colorWhite1000
            @include transition
            @include mediaMinWidthMobile
                min-height: 11.75rem
            @include mediaMinWidthDesktop
                padding: 1.25rem
                border-radius: 1rem
        .steps__title
            font-size: 1rem
            line-height: 1.25rem
            font-weight: 500
            border-radius: 2rem
            padding: .75rem 1.25rem
            background-color: $colorTertiary400
            @include transition
            @include mediaMinWidthTablet
                font-size: 1.25rem
                line-height: 1.5rem
        .steps__arrow
            width: 1.5rem
            height: 1.5rem
            transform: translateY(.75rem)
            fill: $colorPrimary1000
            @include transition
        .steps__text
            width: 100%
            margin-top: auto

    .category__results
        .results__list
            gap: .5rem
            display: flex
            flex-wrap: wrap
        .results__item
            &:hover
                .results__link
                    color: $colorWhite1000
                    background-color: $colorSecondary1000
        .results__link
            color: $colorPrimary800
            @include transition
    


