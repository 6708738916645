/* quill-wrapper-styles.css */
.quill-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.quill-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 120px;
  max-height: 400px;
  border-radius: 8px;
  overflow: relative;
  transition: box-shadow 0.3s ease;
}

.quill-container-fixed-height {
  min-height: 300px;
}

.quill-container .quill,
.quill-container .ql-container {
  height: calc(100% - 42px);
}

.quill-container .ql-editor {
  height: 100%;
  overflow-y: auto;
}

.quill-container > div {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: auto;
}

.quill-container .ql-container {
  flex-grow: 1;
  overflow: auto;
  background-color: #ffffff;
  padding: 5px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.quill-container:hover {
  box-shadow: 0 0 0 0.5px rgba(167, 23, 255, 1);
}

.quill-container:focus-within {
  box-shadow: 0 0 0 0.5px rgba(167, 23, 255, 1);
}

.quill-wrapper .token-icons {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

.quill-container .ql-toolbar {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: solid 1px #e6e6e6;
}

.quill-container .ql-container {
  border: none;
}

.quill-container .quill {
  width: 100%;
}

.quill-container .ql-editor:focus {
  outline: none;
}

.quill-container .ql-toolbar button,
.quill-container .ql-toolbar .ql-picker {
  margin-right: 5px;
}

.quill-container .ql-toolbar button:hover,
.quill-container .ql-toolbar button:focus {
  color: #06c;
}
.quill-container .ql-editor.ql-blank::before {
  height: 2rem;
  font-style: normal;
  font-weight: bold;
  padding-left: 5px;
  color: rgba(32, 38, 57, 0.4);
  font-family: 'Roboto', sans-serif;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

@media screen and (max-width: 767px) {
  .quill-container .ql-editor.ql-blank::before {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
}
