@import 'src/media';
@import 'src/variables';

.select {
  min-width: 8.75rem;
  position: relative;
  width: 100%;
  .select__btn {
    width: 100%;
    border-radius: 20px;
    border: 1px solid #202639;
    display: flex;
    align-items: flex-start;
  }

  .select__preview {
    width: 100%;
    padding: 3px 20px;
    height: 40px;

    display: flex;
    align-items: center;
  }

  .select__dropdown {
    position: absolute;
    top: 100%;
    min-width: 100%;
    padding: 4px 0;
    left: 0;
    z-index: 3;
  }

  .select__list {
    border-radius: 20px;
    border: 1px solid #202639;
    background-color: #ffffff;
    max-height: 320px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
    @include transition;
  }

  .select__item {
    margin: 0;
    padding: 4px 20px;
    display: flex;
    align-items: center;
    &.select__item_active {
      background-color: #efebff;
    }
  }

  .select__media,
  .select__title,
  .select__arrow {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  .select__media {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .select__title {
    color: #202639;
    font-size: 18px;
    line-height: 32px;
    font-weight: 500;
    white-space: nowrap;
  }

  .select__arrow {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
  }
}