// About
.layout_Tokens
    .layout__about
        gap: .25rem
        display: grid
        @include mediaMinWidthTablet
            grid-template-columns: 1fr 1fr

        .about__banner
            color: $colorWhite1000
            background-color: $colorSecondary1000
            padding: 1.5rem
            border-radius: 1.25rem
            gap: 2rem
            height: 29rem
            position: relative
            display: flex
            flex-direction: column
            justify-content: space-between
            @include mediaMinWidthMobile
                padding: 2rem
            @include mediaMinWidthTablet
                height: 35rem
        .banner__background
            position: absolute
            left: 0
            bottom: 0
            width: 100%
            height: 100%
            display: flex
            justify-content: flex-start
            align-items: flex-end
        
        .banner__header,
        .banner__footer
            position: relative
            z-index: 2
        .banner__footer
            width: 100%  
            @include mediaMinWidthTablet
                width: 26.25rem
        .about__list
            display: grid
            height: 100%
            gap: .25rem
            @include mediaMinWidthMobile
                grid-template-columns: repeat(2, 1fr)
        .about__item
            gap: 2.5rem
            display: flex
            flex-direction: column
            justify-content: space-between
            align-items: flex-start
            @include mediaMinWidthMobile
                gap: 2rem
        .about__text
            max-width: 16rem