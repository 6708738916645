@use 'src/media' as media;
@use 'src/variables' as variables;

.liteProSwitcherWrapper {
  display: flex;
  align-items: center;

  span {
    @include variables.fs18;
  }

  @include media.phone {
    display: none;
  }
}

.firstRowWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media.phone {
    justify-content: flex-end;
  }
}
.firstRowWrapperLanding {
  justify-content: flex-end;
}

.input {
  height: 0;
  width: 0;
  visibility: hidden;

  &:checked + .label {
    background: variables.$blockBorderColor;
  }

  &:checked + .label:after {
    left: calc(100% - 3px);
    transform: translateX(-100%);

    background: radial-gradient(150.69% 118.33% at 123.17% 100%, #003629 0%, #7f00e2 100%);
  }
}

.label {
  position: relative;
  display: block;
  cursor: pointer;
  width: 58px;
  height: 29px;
  margin: 0 10px;
  text-indent: -9999px;
  border-radius: 29px;

  background: variables.$mainColorForButtons;
  box-shadow: 5px 5px 10px rgba(127, 0, 226, 0.2);

  &:after {
    content: '';
    position: absolute;
    width: 23px;
    height: 23px;
    top: 3px;
    left: 3px;

    background: radial-gradient(150.69% 118.33% at 123.17% 100%, #ab49f8 0%, #ab49f8 100%);
    border-radius: 23px;
    transition: 0.3s;
  }

  &:active:after {
    width: 23px;
  }
}

.currencyButtonsWrapper {
  display: flex;
  align-items: center;
  gap: 20px;

  @include media.tablet {
    gap: 15px;
  }
  @include media.phone {
    gap: 5px;
  }
}

.currencyButton {
  width: 178px;
  height: 50px;
  @include variables.fs20;

  color: #000000;
  background: #f6eaff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: 0;
  outline: none;

  @include media.tablet {
    width: 125px;
    height: 30px;
    @include variables.fs18;
  }
  @include media.phone {
    width: 75px;
    height: 30px;
    @include variables.fs14;
  }
}

.currencyButtonActive {
  color: #ffffff;
  background: linear-gradient(135deg, #8c00fd 0%, #a800c0 100%);
}

.phoneHidden {
  @include media.phone {
    display: none;
  }
}

.margin15 {
  margin-top: 15px;
}

.searchAndButton {
  display: flex;
  justify-content: space-between;
  padding-top: 2.5rem;
}

.goToTokenButton {
  padding: 0.5rem;
  color: #000000;
  background: #f6eaff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: 0;
  outline: none;

  cursor: pointer;
  margin: auto auto auto 1rem;
}
