@use 'src/media' as media;
@use 'src/variables' as variables;

.modalBlurBg {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
  background: rgba(102, 102, 102, 0.25);
  backdrop-filter: blur(12.5px);
}
.modalBorderWrapper {
  width: 794px;
  height: fit-content;
  padding: 1px;
  justify-self: center;

  border-radius: 20px;
  background: radial-gradient(150.69% 118.33% at 123.17% 100%, #c6fff1 0%, #e7c8ff 100%);
  box-shadow: 5px 5px 10px rgba(127, 0, 226, 0.2);

  @include media.tablet {
    width: 610px;
  }

  @include media.phoneH {
    width: 540px;
  }
  @include media.phoneV {
    width: 360px;
  }
}
.modal {
  width: 100%;
  height: fit-content;
  padding: 20px;

  border-radius: 19px;
  background: variables.$bgWhite;

  @include media.mobileDevices {
    padding: 15px 10px 10px 10px;
  }
}
.exitButton {
  position: absolute;
  z-index: 4;
  top: 20px;
  right: 20px;

  @include media.phoneV {
    width: 15px;
    height: 15px;
    top: 15px;
    right: 15px;

    img {
      width: 15px;
      height: 15px;
    }
  }
}
.title {
  font-weight: 400;

  @include media.phone {
    @include variables.fs20;
  }
}

.form {
  display: grid;
  gap: 20px;
  margin-top: 30px;

  @include media.phone {
    margin-top: 20px;
  }
}

.textarea {
  resize: none;
  padding: 10px;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.fieldWrapper {
  display: grid;
  gap: 10px;

  span {
    font-weight: 500;
    @include variables.fs18;

    @include media.phoneV {
      @include variables.fs14;
    }
  }

  textarea {
    height: 156px;
  }
}
.formButton {
  width: 163px;
  justify-self: center;
}
