// TOKEN
.layout_Tokens
    .token__form
        display: flex
        flex-direction: column
        position: relative
        grid-gap: 2rem
        @include mediaMinWidthDesktop
            display: grid
            align-items: flex-start
            grid-template-columns: 1fr 1fr
            grid-gap: .25rem
    // Group
    .token__group
        border-radius: 1.25rem
        padding: 1rem .75rem
        background-color: $colorTertiary400
        display: grid
        grid-gap: .75rem
        @include mediaMinWidthMobile
            padding: 1.5rem
            grid-gap: 1.5rem
        @include mediaMinWidthDesktop
            padding: 2rem
            grid-gap: 2rem


        .group__main
            gap: 1rem
            display: grid
            @include mediaMinWidthDesktop
                gap: 2rem
            &.group__main_secondary
                gap: 1rem
    // Token header
    .token__header
        display: flex
        align-items: center
        gap: 1.5rem
        margin-bottom: .5rem
        .token__avatar
            width: 5.75rem
            height: 5.75rem
            gap: .75rem
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            position: relative
            cursor: pointer
            border-radius: .75rem
            background-color: $colorWhite1000
            overflow: hidden
            @include mediaMinWidthMobile
                width: 9rem
                height: 9rem
            @include mediaMinWidthDesktop
                width: 10rem
                height: 10rem
            .avatar__input
                position: absolute
                width: 100%
                height: 100%
                top: 0
                left: 0
                opacity: 0
                cursor: pointer
                z-index: 2
            .avatar__ico
                width: 1rem
                height: 1rem
                @include mediaMinWidthMobile
                    width: 1.125rem
                    height: 1.125rem
                @include mediaMinWidthDesktop
                    width: 1.25rem
                    height: 1.25rem
            .avatar__text
                color: $colorPrimary500
                font-size: .875rem
                line-height: 1rem
                font-weight: 500
                text-align: center
                @include mediaMinWidthMobile
                    font-size: 1.125rem
                    line-height: 1.25rem
                @include mediaMinWidthDesktop
                    font-size: 1.25rem
                    line-height: 1.5rem
        .token__title
            max-width: calc(100% - 10rem - 1.5rem)
            display: flex
            flex-direction: column
            .token__text
                font-weight: 400
                margin-bottom: 0.5rem
            .form__tooltip 
                display: block
                font-size: 0.875rem
                line-height: 1.2
        .token__img 
            width: 100%
            height: 100%
            object-fit: cover
            position: absolute
            top: 0
            left: 0

        // Service
    .token__service
        gap: .5rem
        display: flex
        flex-direction: column
        @include mediaMinWidthDesktop
            gap: 1rem
        &:not(:last-child)
            margin-bottom: 1.5rem
            @include mediaMinWidthMobile
                margin-bottom: 2rem
            @include mediaMinWidthDesktop
                margin-bottom: 3.75rem
        .service__text
            color: $colorPrimary500
            font-size: 1rem
            line-height: 1.25rem
            @include mediaMinWidthMobile
                font-size: 1.125rem
                line-height: 1.5rem
            @include mediaMinWidthDesktop
                font-size: 1.25rem
    // Support
    .token_support
        .support__link
            color: $colorPrimary500
            font-size: .75rem
            line-height: 1.5rem
            font-weight: 700
            gap: .75rem
            display: flex
            align-items: center
            @include mediaMinWidthMobile
                font-size: 1.125rem
            @include mediaMinWidthDesktop
                font-size: 1.25rem
            &:hover
                .support__ico
                    transform: translateX(.5rem)
        .support__tooltip
            width: 2rem
            height: 2rem
        .support__ico
            width: 2rem
            height: 2rem
            transform: translateX(0)
            @include transition

   
    .form__tooltip_error
        color: #ff0000
        font-weight: 600

    .token__avatar_error
        border: 2px solid #ff0000