@use 'src/media' as media;
@use 'src/variables' as variables;

.wrapper {
  width: 100%;
  height: fit-content;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 20px;
  background: variables.$bgWhite;
  border: 1px solid variables.$blockBorderColor;

  @include media.phoneV {
    padding: 10px;
  }
}

.firstRow {
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    margin-bottom: 0;
  }
}
.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    margin-bottom: 0;
  }
  @include media.phoneV {
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }
}

.title {
  margin-bottom: 0;

  @include variables.fs32;
  font-weight: 400;
  color: #000000;

  @include media.phone {
    @include variables.fs20;
  }
}

.balanceBlock {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.subTitle {
  @include variables.fs18;

  @include media.phone {
    @include variables.fs14;
  }
}

.number {
  font-weight: 500;
  @include variables.fs20;

  @include media.phone {
    @include variables.fs16;
  }
}

.headlines {
  @include variables.fs20;
  @include variables.titleColor;

  @include media.phone {
    @include variables.fs16;
  }
}

.tilda {
  margin: 0 12px;

  @include variables.fs20;
  @include variables.titleColor;

  @include media.phone {
    margin: 0;
    @include variables.fs16;
  }
}

.plate {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  border-radius: 10px;
  margin-top: 10px;
  padding: 20px;
  //подобрал аналогичные цвета и заменил тень, чтобы избежать opacity
  background: radial-gradient(107.25% 302.83% at 3.5% 11.32%, #f0fffb 0%, #f1fcfc 93.26%);
  // opacity: 0.3;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.075);

  @include media.phone {
    padding: 12px;
  }

  @include media.phoneV {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    grid-template-areas:
      'avatar avatar'
      'identificator verification'
      'buttons buttons';
  }
}

.groupWrapper {
  display: flex;
  gap: 10px;
  align-items: center;

  grid-area: avatar;

  span {
    @include variables.fs20;
    @include media.tablet {
      @include variables.fs16;
    }
    @include media.phone {
      @include variables.fs12;
    }
  }
  @include media.phone {
    gap: 5px;
  }
  @include media.phoneV {
    flex-direction: column;
    justify-content: center;
  }
}
.avatar {
  width: 40px;
  height: 40px;

  @include media.phone {
    width: 25px;
    height: 25px;
  }
}
.idWrapper {
  display: flex;
  gap: inherit;
  @include media.mobileDevices {
    flex-direction: column;
  }
  @include media.phoneV {
    flex-direction: row;
  }
}
.idValueWrapper {
  display: flex;
  gap: inherit;
  align-items: center;
}

.value {
  @include variables.titleColor;
}

.copyButton {
  width: 17px;
  height: 20px;
  background: transparent;
  border: 0;

  @include media.phone {
    width: 11px;
    height: 13px;
  }

  img {
    width: 17px;
    height: 20px;
    filter: invert(17%) sepia(49%) saturate(4414%) hue-rotate(259deg) brightness(86%) contrast(116%);

    @include media.phone {
      width: 11px;
      height: 13px;
    }
  }
}

.doubleRow {
  height: fit-content;
}
.doubleRow:nth-of-type(1) {
  grid-area: verification;
}
.doubleRow:nth-of-type(2) {
  grid-area: identificator;
}

.firstRow {
  @include variables.fs16;
  color: #000000;

  @include media.tablet {
    @include variables.fs14;
  }
  @include media.phone {
    @include variables.fs12;
  }
}

.secondRow {
  @include variables.fs20;
  background: linear-gradient(135deg, #8c00fd 0%, #a800c0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @include media.tablet {
    @include variables.fs16;
  }
  @include media.phone {
    @include variables.fs12;
  }
}
.plateButtonsWrapper {
  grid-area: buttons;
  display: flex;
  gap: 10px;
  align-items: center;

  @include media.phone {
    gap: 5px;
  }
}
.plateButton {
  margin-top: 0;
  width: 100px;

  @include media.phone {
    @include variables.fs14;
  }

  @include media.phone {
    width: 55px;
  }
  @include media.phoneV {
    width: 100%;
  }
}

.saveButton {
  width: 210px;
  margin-top: 20px;

  text-transform: capitalize;

  @include media.phone {
    width: 170px;
  }
}

.form {
  //мне кажется, что здесь, как во второй форме макета, должно быть 20px
  margin-top: 20px;
}

.labelWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px 10px;

  @include media.phoneV {
    grid-template-columns: repeat(2, 1fr);
  }
}
.labelWrapper:nth-of-type(2) {
  margin-top: 20px;
}
.labelWrapperAlt {
  @include media.phoneV {
    width: 50%;
    grid-template-columns: 1fr;
  }
}

.line {
  width: 100%;
  height: 1px;
  margin-top: 39px;
  margin-bottom: 20px;
  background: radial-gradient(96.1% 93.99% at 0% 2.73%, #ccfff3 0%, #c985ff 100%);
}

.text {
  margin-left: 0;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.paragraph {
  margin-top: 10px;
  margin-bottom: 0;
  @include variables.fs12;
  text-decoration: none;
  list-style-type: none;
  color: #666666;

  img {
    margin-right: 8px;
  }
}

.paragraphFirst {
  margin-top: 20px;
}

.paragraphOther {
  margin-top: 0;
}
