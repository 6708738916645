@import '../../helper/media';
@import '../../helper/variables';
.layout__groups {
  position: relative;
  .groups__background {
    width: 11.25rem;
    height: 11.25rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }
    @include mediaMinWidthTablet {
      position: absolute;
      width: 14.5rem;
      height: 14.5rem;
    }
    &.groups__background_top-right {
      @include mediaMinWidthTablet {
        top: 0;
        right: 0;
      }
    }
    &.groups__background_top-center {
      @include mediaMinWidthTablet {
        top: 0;
        left: 50%;
        @include transform-x;
      }
    }
    &.groups__background_bottom-left {
      @include mediaMinWidthTablet {
        bottom: 0;
        left: 0;
      }
    }
    img,
    svg {
      width: 8.25rem;
      height: 8.25rem;
      object-fit: contain;
      @include mediaMinWidthTablet {
        width: 100%;
        height: 100%;
      }
    }
  }
  .groups__list {
    margin-bottom: -1rem;
    @include mediaMinWidthTablet {
      margin-bottom: -1.25rem;
    }
  }
  .groups__item {
    color: $colorPrimary300;
    margin-bottom: 1rem;
    padding: 1.5rem;
    height: 100%;
    border-radius: 1.25rem;
    background-color: $colorWhite;
    display: flex;
    flex-direction: column;

    @include box-shadow;
    @include mediaMinWidthTablet {
      padding: 2rem;
      margin-bottom: 1.25rem;
    }
    &.groups__item_second-size {
      @include mediaMinWidthTablet {
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }
    &.groups__item_second-color {
      color: $colorPrimary400;
    }
    &.groups__item_second-radius {
      border-radius: 4rem;
    }
    &.groups__item_unset {
      padding: unset;
      background-color: unset;
      box-shadow: unset;
    }
  }

  .groups__media {
    width: auto;
    height: 4rem;
    display: flex;
    align-items: flex-start;
    @include mediaMinWidthTablet {
      height: 10rem;
    }
    &.groups__media_tiny {
      @include mediaMinWidthTablet {
        height: 6.25rem;
        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }
      }
    }
    &.groups__media_large {
      height: 6.875rem;
      @include mediaMinWidthTablet {
        height: 11rem;
      }
    }
  }
  .groups__media,
  .groups__header,
  .groups__main,
  .groups__footer {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
      @include mediaMinWidthTablet {
        margin-bottom: 2rem;
      }
    }
  }
  .groups__info {
    color: $colorWhite;
    background-color: $colorPrimary400;
    border-radius: 1.25rem;
    padding: 1.5rem;
  }
}
