@use 'src/media' as media;

.line {
  width: 100%;
  height: 1px;
  background: radial-gradient(96.1% 93.99% at 0% 2.73%, #ccfff3 0%, #c985ff 100%);
}

.newsLine {
	margin-bottom: 20px;
}