@import '../helper/media';
@import '../helper/variables';
// FOOTER
.layout__footer {
  color: $colorWhite;
  background-color: $colorPrimary400;
  padding: 2rem 0;
  @include mediaMinWidthTablet {
    padding: 4rem 0;
  }
  .container {
    width: 75rem;
    max-width: calc(100vw - 2rem);
    margin: 0 auto;
  }
  .footer__group {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 2rem;
      @include mediaMinWidthMobile {
        margin-bottom: 4rem;
      }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      color: $colorWhite;
    }
  }
}
// LOGO
.footer__logo {
  .logo__link {
    width: 12.5rem;
    display: flex;
    align-items: flex-start;
    @include mediaMinWidthTablet {
      width: 21.75rem;
    }
  }
  img,
  svg {
    width: 100%;
    height: 100%;
  }
}
// NAV
.footer__nav {
  .nav__groups {
    margin-bottom: -2rem;
    display: flex;
    flex-direction: column;
    @include mediaMinWidthTablet {
      flex-direction: row;
    }
  }
  .nav__group {
    margin-bottom: 2rem;
    @include mediaMaxWidthTablet {
      margin-bottom: 1.5rem;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid $colorWhite;
      &.nav__group_open {
        .nav__header {
          margin-bottom: 0.75rem;
        }
        .nav__action {
          transform: rotate(180deg);
        }
        .nav__main {
          max-height: 9999px;
        }
      }
    }
    &:not(:last-child) {
      @include mediaMinWidthTablet {
        margin-right: 1.5rem;
      }
      @include mediaMinWidthDesktop {
        margin-right: 4rem;
      }
    }
  }
  .nav__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include transition;
    &:not(:last-child) {
      @include mediaMinWidthTablet {
        margin-bottom: 0.75rem;
      }
    }
    .nav__title {
      &:not(:last-child) {
        margin-right: 1rem;
      }
      .title__text {
        font-size: 1.125rem;
        line-height: 1.25rem;
        @include mediaMinWidthTablet {
          font-size: 1.25rem;
          line-height: 1.5rem;
          font-weight: 500;
        }
      }
    }
    .nav__action {
      width: 1.5rem;
      height: 1.5rem;
      transform: rotate(0deg);
      display: flex;
      align-items: center;
      justify-content: center;
      @include transition;
      @include mediaMinWidthTablet {
        display: none;
      }
      img,
      svg {
        width: 100%;
        height: 100%;
        fill: $colorWhite;
      }
    }
  }
  .nav__main {
    max-height: 0;
    overflow: hidden;
    @include transition;
    @include mediaMinWidthTablet {
      max-height: 9999px;
    }
  }
  .nav__list {
    display: flex;
    flex-direction: column;
  }
  .nav__item {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
      @include mediaMinWidthTablet {
        margin-bottom: 0.75rem;
      }
    }
  }
  .nav__link {
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.125rem;
    line-height: 1.25rem;
    display: flex;
    @include transition;
    @include mediaMinWidthTablet {
      font-size: 0.875rem;
      line-height: 1rem;
    }
    @include mediaMinWidthDesktop {
      white-space: nowrap;
    }
    &:hover,
    &.active {
      color: $colorQuartyty500;
    }
  }
}
// CONTACT
.footer__contact {
  @include mediaMaxWidthTablet {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid $colorWhite;
  }
  @include mediaMinWidthTablet {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .contact__header {
    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }
  }
  .contact__list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @include mediaMinWidthTablet {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
  .contact__item {
    &:not(:last-child) {
      margin: 0 0.75rem 0.25rem 0;
      @include mediaMinWidthTablet {
        margin-right: 0;
      }
    }
    &.contact__item_second {
      width: 100%;
      text-align: center;
      @include mediaMinWidthMobile {
        width: auto;
        text-align: left;
      }
      &:not(:last-child) {
        margin-bottom: 0.75rem;
      }
      .contact__link {
        font-size: 2rem;
        line-height: 2.5rem;
        @include mediaMinWidthTablet {
          font-size: 1.125rem;
          line-height: 1.25rem;
        }
      }
    }
    &:hover {
      .contact__link {
        color: $colorQuartyty500;
      }
    }
  }
  .contact__link {
    color: $colorWhite;
    font-size: 0.875rem;
    line-height: 1.25rem;
    @include transition;
  }
  .contact__socials {
    width: 100%;
    display: flex;
    justify-content: center;
    @include mediaMinWidthMobile {
      justify-content: flex-start;
    }
    .socials__list {
      margin: 0 -1.5rem -0.5rem 0;
      display: flex;
    }
    .socials__item {
      margin: 0 1.5rem 0.5rem 0;
    }
    .socials__link {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      align-items: flex-start;
      &:hover {
        img,
        svg {
          transform: scale(1.1);
        }
      }
    }
    img,
    svg {
      width: 100%;
      height: 100%;
      transform: scale(1);
      @include transition;
    }
  }
}
// SOCIALS
.footer__socials {
  .socials__groups {
    margin: 0 -0.75rem -1.5rem;
    width: calc(100% + 1.5rem);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include mediaMinWidthTablet {
      margin: 0 -2rem -2rem;
      width: calc(100% + 4rem);
      justify-content: flex-start;
    }
  }
  .socials__group {
    margin-bottom: 1.5rem;
    padding: 0 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include mediaMinWidthTablet {
      margin-bottom: 2rem;
      padding: 0 2rem;
    }
  }
  .socials__header {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
  .socials__location {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
    .location__media {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      align-items: flex-start;
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
      img,
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .location__title {
      .title__text {
        font-size: 0.875rem;
        line-height: 1.5rem;
      }
    }
  }

  .socials__main {
    padding: 0.5rem;
    border-radius: 6.25rem;
    @include box-shadowSecond;
  }
  .socials__list {
    margin: 0 -1.5rem -0.5rem 0;
    display: flex;
  }
  .socials__item {
    margin: 0 1.5rem 0.5rem 0;
  }
  .socials__link {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: flex-start;
    &:hover {
      img,
      svg {
        transform: scale(1.1);
      }
    }
  }
  img,
  svg {
    width: 100%;
    height: 100%;
    transform: scale(1);
    @include transition;
  }
}
// COPYRIGHT
.footer__copyright {
  font-size: 0.875rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  display: flex;
  text-align: center;
  justify-content: center;
  @include mediaMinWidthTablet {
    text-align: right;
    justify-content: flex-end;
  }
}
