@import '../helper/media';
@import '../helper/variables';
/* TABS */
.p2p,
.account,
.layout {
  .tabs {
    margin-bottom: 1.25rem;
    .tabs__header {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 1.5rem;

      .tabs__list {
        padding: 0.25rem;
        background-color: $colorWhite;
        border-radius: 4rem;
        overflow: hidden;
        overflow-x: auto;
        display: flex;
        @include box-shadow;
        @include mediaMinWidthTablet {
        }

        .tabs__item {
          font-size: 0.875rem;
          line-height: 1rem;
          font-weight: 400;
          width: 100%;
          padding: 0.5rem;
          color: $colorPrimary400;
          background-color: $colorWhite;
          border-radius: 4rem;
          display: flex;
          justify-content: center;
          cursor: pointer;
          white-space: nowrap;
          box-shadow: 0 0.25rem 1.5rem transparent;
          @include transition;
          @include mediaMinWidthMobile {
            font-size: 1rem;
            line-height: 1.25rem;
            padding: 0.75rem 2rem;
            font-weight: 600;
            width: auto;
          }
          &:not(:last-child) {
            margin-right: 0.5rem;
          }
          &:hover,
          &.tabs__item_active {
            color: $colorWhite;
            background-color: $colorQuartyty500;
            box-shadow: 0 0.25rem 1.5rem $colorQuartyty200;
            &.tabs__item_buy {
              background-color: $colorSecondary500;
            }
            &.tabs__item_sell {
              background-color: $colorTertiary500;
            }
          }
        }
      }
    }
    .tabs__main {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }

    &.tabs_second {
      .tabs__header {
        .tabs__item {
          @include mediaMinWidthMobile {
            font-size: 0.875rem;
            line-height: 1rem;
            padding: 0.5rem 3rem;
          }
        }
      }
    }
  }
}
