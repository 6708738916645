@use 'src/media' as media;
@use 'src/variables' as variables;

.title {
  max-width: calc(100% - 27px);
  font-weight: 400;

  @include media.phone {
    @include variables.fs20;
  }
  @include media.phoneV {
    max-width: calc(100% - 20px);
  }
}

.form {
  display: grid;
  gap: 10px;
  margin-top: 30px;

  @include media.phone {
    margin-top: 20px;
  }
}

.fieldWrapper {
  display: grid;
  gap: 5px;

  span {
    font-weight: 500;
    @include variables.fs18;

    @include media.phoneV {
      @include variables.fs14;
    }
  }
}
.formButton {
  width: 163px;
  justify-self: center;
}

.keyInfo{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 5px;
}

.key{
  word-break: break-all;
  font-size: 15px;
}
