@use 'src/media' as media;
@use 'src/variables' as variables;

.button {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @include variables.fs18;
  & span {
    @include variables.fs18;
  }

  color: variables.$fontBlack;
  background: variables.$mainColorForButtons;
  border: 1px solid variables.$mainColorForButtonBorder;
  border-radius: 10px;

  @media (hover: hover) {
    &:not(:disabled):hover {
      color: variables.$bgWhite;
      background: variables.$hoverColorForButtons;
      box-shadow: variables.$buttonActiveShadow;
      border: 0;
    }
  }
  &:not(:disabled):active {
    color: variables.$bgWhite;
    background: variables.$clickColorForButtons;
    box-shadow: variables.$buttonActiveShadow;
    border: 0;
  }

  @include media.phone {
    height: 35px;

    @include variables.fs16;

    & span {
      @include variables.fs16;
    }
  }

  @include media.phoneV {
    height: 30px;

    @include variables.fs14;

    & span {
      @include variables.fs14;
    }
  }
}
.buttonWithImagePurple {
  @media (hover: hover) {
    &:not(:disabled):hover {
      img {
        filter: invert(93%) sepia(83%) saturate(4433%) hue-rotate(181deg) brightness(107%) contrast(102%);
      }
    }
  }
  &:not(:disabled):active {
    img {
      filter: invert(93%) sepia(83%) saturate(4433%) hue-rotate(181deg) brightness(107%) contrast(102%);
    }
  }
  img {
    filter: invert(17%) sepia(49%) saturate(4414%) hue-rotate(259deg) brightness(86%) contrast(116%);
  }
}
.buttonOutlined {
  height: 40px;
  display: grid;
  align-items: center;
  background-color: variables.$bgWhite;

  @include variables.fs18;
  border-radius: 10px;
  border: 2px solid variables.$mainColorForButtonBorder;

  @media (hover: hover) {
    &:not(:disabled):hover {
      background: #f8f9fd;
    }
  }
  &:not(:disabled):active {
    background: variables.$mainColorForButtons;
  }

  @include media.phone {
    height: 35px;
    @include variables.fs16;
    & span {
      @include variables.fs16;
    }
  }

  @include media.phoneV {
    height: 30px;
    @include variables.fs14;
    & span {
      @include variables.fs14;
    }
  }
}
.buttonActive {
  font-weight: 600;
  color: variables.$bgWhite;

  background: variables.$hoverColorForButtons;
  border: 0;

  @media (hover: hover) {
    &:not(:disabled):hover {
      color: variables.$bgWhite;
      background: variables.$hoverActiveColorForButtons;
      border: 0;
    }
  }
  &:not(:disabled):active {
    color: variables.$bgWhite;
    background: variables.$clickActiveColorForButtons;
    border: 0;
  }
}
.buttonColored {
  height: 40px;
  font-weight: 500;
  @include variables.fs18;

  color: variables.$bgWhite;
  border-radius: 10px;
  border: 0;

  @include media.phone {
    height: 35px;

    @include variables.fs16;

    & span {
      @include variables.fs16;
    }
  }

  @include media.phoneV {
    height: 30px;

    @include variables.fs14;

    & span {
      @include variables.fs14;
    }
  }
}

.buttonGreen {
  font-weight: 500;

  background: variables.$fontGreen;
  border-radius: 10px;
  border: 0;

  @media (hover: hover) {
    &:hover {
      background: variables.$buttonGreenHover;
    }
  }
  &:active {
    background: variables.$buttonGreenHover;
  }
}

.buttonRed {
  font-weight: 500;

  background: variables.$fontRed;
  border-radius: 10px;
  border: 0;

  @media (hover: hover) {
    &:hover {
      background: variables.$buttonRedHover;
    }
  }
  &:active {
    background: variables.$buttonRedHover;
  }
}
.buttonLogout {
  width: 210px;
  height: 30px;

  font-weight: 500;

  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);

  @media (hover: hover) {
    &:not(:disabled):hover {
      box-shadow: variables.$buttonActiveShadow;
    }
  }
  &:not(:disabled):active {
    box-shadow: variables.$buttonActiveShadow;
  }

  @include media.phone {
    width: 152px;
    height: 29px;
    @include variables.fs16;
  }
}
.input {
  position: relative;
  width: 100%;
  height: 40px;
  padding-left: 20px;

  @include variables.fs18;

  border: 2px solid variables.$blockBorderColor;
  border-radius: 10px;
  outline: none;

  &:focus {
    outline: none;
  }
  &:read-only,
  &:disabled {
    background: variables.$bgLight;
  }

  &::-webkit-input-placeholder {
    color: variables.$grey;
  }
  &:-moz-placeholder {
    color: variables.$grey;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: variables.$grey;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: variables.$grey;
  }
  &::-ms-input-placeholder {
    color: variables.$grey;
  }

  &::placeholder {
    color: variables.$grey;
  }

  @include media.phone {
    height: 35px;
    @include variables.fs16;
  }
  @include media.phoneV {
    height: 30px;
    padding-left: 10px;
    @include variables.fs14;
  }
}

.pointer {
  cursor: pointer;
}

.rotate180 {
  transform: rotate(180deg);
}

.displayNone {
  display: none !important;
}

.swiperWrapper {
  width: 100%;
  height: 100%;
  overscroll-behavior: none;

  :global(.swiper-wrapper) {
    overscroll-behavior: none;
  }

  :global(.swiper-slide) {
    width: 100%;
    height: auto;
  }
}
.scrollbarLeft {
  left: 2px !important;
  right: auto !important;
  background-color: transparent !important;
}
.scrollbarRight {
  left: auto !important;
  right: 2px !important;
  background-color: transparent !important;
}
.scrollbarRight3px {
  left: auto !important;
  right: 3px !important;
  background-color: transparent !important;
}
.scrollbarDragGreen,
.scrollbarRedDrag,
.scrollbarDragPurple,
.scrollbarDragPurpleLight {
  border-radius: 2px;
}
.scrollbarDragGreen {
  background: rgba(0, 208, 158, 0.5);
}
.scrollbarRedDrag {
  background: rgba(255, 0, 77, 0.5);
}
.scrollbarDragPurple {
  background: radial-gradient(145.02% 191.6% at 123.17% 100%, #003629 0%, #7f00e2 100%);
}
.scrollbarDragPurpleLight {
  background: linear-gradient(116.51deg, rgba(222, 215, 251, 0.5) 5.82%, rgba(159, 1, 212, 0.285) 94.31%);
}
.swiperBody {
  width: 100vw;
  height: 100vh;
}
