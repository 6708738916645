@use 'src/media' as media;
@use 'src/variables' as variables;

.wrapper {
  width: 100%;
  height: fit-content;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 20px;
  background: variables.$bgWhite;
  border: 1px solid variables.$blockBorderColor;

  @include media.mobileDevices {
    width: 100%;
  }
  @include media.phoneV {
    padding: 10px;
  }
}

.button {
  width: 163px;
}

.table {
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 0 30px;
  margin-top: 20px;

  overflow: hidden;

  @include media.phoneV {
    grid-template-columns: repeat(3, auto);
  }
}
.titleBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;

  background: variables.$mainColorForButtons;
  border-radius: 20px 20px 0px 0px;
}
.cell {
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;

  font-weight: 400;
  @include variables.fs16;

  @include media.phone {
    @include variables.fs12;
  }

  &:nth-of-type(4n + 1) {
    font-weight: 500;
    margin-left: 20px;

    @include media.mobileDevices {
      margin-left: 10px;
    }
  }

  &:nth-of-type(4n + 4) {
    margin-right: 20px;

    @include media.phoneV {
      margin-left: 0px;
    }
  }
  // &:not(.titleCell) {
  //   word-break: break-all;
  // }
}
.cellStatus {
  font-weight: 600;
  @include variables.fs20;
  color: variables.$fontGreen;

  @include media.phone {
    @include variables.fs16;
  }
}
.phoneHidden {
  @include media.phone {
    display: none;
  }
}
.phoneVHidden {
  @include media.phoneV {
    display: none;
  }
}
.titleCell {
  height: 50px;

  font-weight: 500;
  @include variables.fs18;

  @include media.phone {
    @include variables.fs14;
  }
}
.line {
  height: 1px;
  grid-column: 1 / 5;

  background: radial-gradient(96.1% 93.99% at 0% 2.73%, #ccfff3 0%, #c985ff 100%);

  @include media.phoneV {
    grid-column: 1 / 4;
  }
}
