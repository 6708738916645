@use 'src/media' as media;
@use 'src/variables' as variables;

.tabWrapper {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, 1fr);

  button {
    width: 100%;
  }

  @include media.phoneV {
    width: 100%;
    grid-template-columns: repeat(3, auto);
    gap: 5px;
  }
}

.optionsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @include media.phoneV {
    display: grid;
    gap: 10px;
  }
}


.table {
  position: relative;
  width: calc(100% + 19px * 2);
  margin: -19px;
  margin-top: 16px;
  margin-bottom: -10px;
  display: grid;
  background: variables.$bgWhite;
  border-bottom-left-radius: 19px;
  border-bottom-right-radius: 19px;
  overflow: hidden;
  @include media.mobileDevices {
    margin-bottom: -19px;
  }
  @include media.phoneV {
    margin: -9px;
    margin-top: 5px;
    width: calc(100% + 9px * 2);
  }
}

.row {
  width: 100%;
  height: fit-content;
  padding: 1px 19px;
  display: grid;
  justify-content: space-between;
  align-items: center;
}

.rowHistory {
  grid-template-columns: 14% 9% 8% 11% 10% 43%;
}

.rowHistoryCash {
  grid-template-columns: 14% 18% 5% 10% 10% 8% 17%;
}

.rowTurnover {
  grid-template-columns: 9% 12% 13% 13% 21% 9% 9%;
}

.rowMobile {
  justify-content: flex-start;
  gap: 20px;
  grid-template-columns: 40% auto;
}

.rowMobileTitleWrapper {
  display: flex;
  gap: 20px;
}

.imgRotate {
  transform: rotate(-90deg);
}

.grey {
  color: variables.$grey;
}

.titleRow {
  height: fit-content;
  padding-top: 8px;
  padding-bottom: 8px;

  @include media.mobileDevices {
    justify-content: space-between;
    grid-template-columns: auto auto;
    @include variables.fs16;
  }
}

.cell {
  width: 100%;
  height: fit-content;
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  color: variables.$fontBlack;
  // white-space: nowrap;

  font-weight: 400;
  @include variables.fs14;
}

.cellColoredBg {
  background: linear-gradient(135deg, #f9f2ff 0%, #faf2fc 100%);
}

.tableTitle {
  font-weight: 500;
  @include variables.fs14;
  @include media.mobileDevices {
    @include variables.fs16;
  }
}

.hiddenWrapper {
  height: 0;
  overflow: hidden;
}

.TxID {
  @include variables.titleColor;
}

.cancelled {
  color: variables.$fontRed;
}

.credited {
  color: variables.$fontGreen;
}

.cancelButton {
  height: 15px;
  @include variables.fs14;
  color: variables.$grey;
  @include media.mobileDevices {
    height: 13px;
    @include variables.fs12;
  }
}

.line {
  height: 3px;
  margin: 0 20px;
  background: variables.$clickColorForButtons;
}

.lineMobile {
  height: 1px;
  background: variables.$blockBorderColor;
}

.paginationWrapper {
  width: 420px;
  height: fit-content;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @include media.phoneH {
    width: 350px;
  }
  @include media.phoneV {
    width: 330px;
  }
}

.paginationCenterWrapper {
  height: fit-content;
  display: flex;
  place-content: center;
  gap: 10px;

  @include media.phone {
    gap: 8px;
  }
}

.arrowButton {
  aspect-ratio: 1;
  display: grid;
  place-content: center;

  img {
    filter: invert(14%) sepia(65%) saturate(7464%) hue-rotate(274deg) brightness(92%) contrast(127%);
    @include media.phone {
      width: 8.75px;
      height: 12.25px;
    }

    @include media.phoneV {
      width: 7.5px;
      height: 10.5px;
    }
  }
}

.arrowRight {
  transform: rotate(180deg);
  @media (hover: hover) {
    &:not(:disabled):hover {
      box-shadow: none;

      img {
        filter: invert(100%) sepia(5%) saturate(7479%) hue-rotate(279deg) brightness(116%) contrast(105%);
      }
    }
  }

  &:not(:disabled):active {
    box-shadow: none;

    img {
      filter: invert(100%) sepia(5%) saturate(7479%) hue-rotate(279deg) brightness(116%) contrast(105%);
    }
  }
}

.pageNumber {
  width: 30px;
  font-size: 16px;
  line-height: 36px;
  place-self: center;
  text-align: center;

  cursor: pointer;

  @include media.phone {
    width: 26px;
    @include variables.fs14;
  }
}

.pageNumberActive {
  @include variables.titleColor;
}

.ellipsisWrapper {
  width: 40px;
  @include media.phone {
    width: 35px;
  }

  @include media.phoneV {
    width: 30px;
  }
}

.ellipsis {
  padding: 0;
  text-align: center;
  @include variables.fs16;
  border: 2px solid variables.$mainColorForButtonBorder;
}

.notification1Figure {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(8px) translateY(-8px);
  display: grid;
  place-items: center;
  place-content: center;

  background-color: variables.$blockBorderColor;
  color: variables.$bgWhite;
  border-radius: 10px;
  @include variables.fs14;

  @include media.phoneV {
    width: 16px;
    height: 16px;
    transform: translateX(4px) translateY(-6px);
    @include variables.fs12;
  }
}

.notificationMoreThan1Figure {
  width: fit-content;
  padding: 0 4px;
}

.section {
  width: 100%;
}

.section .section__subsection:not(:last-child) {
  margin-bottom: 40px;
  @include media.mobileDevices {
    margin-bottom: 20px;
  }
}

.section .section__header {
  text-align: center;
}

.section .section__header:not(:last-child) {
  margin-bottom: 20px;
}

.section .section__title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  color: var(--active-menu-color) !important;
  @include media.mobileDevices {
    font-size: 12px;
    line-height: 14px;
  }
}

.section .wysiwyg {
  color: #000000;
}

.section .reference {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.section .reference .reference__list {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.section .reference .reference__item {
  width: 100%;
}

.section .reference .reference__item:not(:last-child) {
  margin-bottom: 20px;
}

.section .reference .reference__header {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  width: 100%;
  margin-bottom: 12px;
  @include media.mobileDevices {
    font-size: 12px;
    line-height: 14px;
  }
}

.section .reference .reference__layout {
  display: flex;
  align-items: flex-end;
}

.section .reference .reference__main {
  width: 100%;
  margin-right: 20px;
}

.section .reference .reference__field {
  font-size: 18px;
  line-height: 24px;
  padding: 8px 20px;
  font-weight: 400;
  height: 40px;
  background-color: rgba(102, 102, 102, .1);
  border-radius: 12px;
  border: 0;
  width: 100%;
  font-family: "Inter";
  resize: none;
  @include media.mobileDevices {
    font-size: 12px;
    line-height: 18px;
    height: 32px;
  }

  &.reference__field_second {
    height: 280px;
    @include media.mobileDevices {
      height: 140px;
    }
  }
}

.section .reference .reference__action {
  padding: 8px 40px;
  border-radius: 12px;
  width: 200px;
  background-color: #00D09E;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @include media.mobileDevices {
    padding: 8px;
    width: 32px;
    height: 32px;
    border-radius: 12px;
    background-color: #EFEBFF;
  }
}

.section .reference .reference__action .reference__ico {
  display: none;
  width: 16px;
  height: 16px;
  @include media.mobileDevices {
    display: block;
  }
}

.section .reference .reference__action .reference__text {
  color: #ffffff;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  @include media.mobileDevices {
    display: none;
  }
}

.section .rowTabSecondary {
  grid-template-columns: 40% 40% 20%;
  @include media.mobileDevices {
    grid-template-columns: 33.3% 33.3% 33.3%;
  }
}

.section .rowTabTertiary {
  grid-template-columns: 40% 40% 20%;
  @include media.mobileDevices {
    grid-template-columns: 33.3% 33.3% 33.3%;
  }
}

.section .wysiwyg {
  font-size: 18px;
  line-height: 40px;
  @include media.mobileDevices {
    font-size: 12px;
    line-height: 14px;
  }
}

.table {
  margin: 0;
  width: 100%;

  .cell {
    @include media.mobileDevices {
      font-size: 12px;
      line-height: 14px;
    }
  }
}

.tabWrapper {
  width: 100%;
  margin-bottom: 40px;
  @include media.mobileDevices {
    margin-bottom: 20px;
  }

  button {
    font-weight: 400;
    @include media.mobileDevices {
      font-size: 12px;
      line-height: 14px;
    }
  }
}