@use 'src/media' as media;
@use 'src/variables' as variables;

.wrapper {
  width: 965px;
  height: fit-content;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 20px;
  background: variables.$bgWhite;
  border: 1px solid variables.$blockBorderColor;

  @include media.mobileDevices {
    width: 100%;
  }
  @include media.phoneV {
    padding: 10px;
  }
}

.title {
  margin-bottom: 20px;

  @include variables.fs32;
  font-weight: 400;
  color: #000000;

  @include media.phoneH {
    margin-bottom: 17px;
    @include variables.fs20;
  }
  @include media.phoneV {
    margin-bottom: 10px;
    @include variables.fs18;
  }
}

.buttonWrapper {
  position: relative;
  width: fit-content;
  //height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 500;
  @include variables.fs18;
  border: 0px;

  p {
    @include variables.titleColorAlt;
  }

  @include media.tablet {
    @include variables.fs16;
  }
  @include media.phoneH {
    @include variables.fs14;
  }
  @include media.phoneV {
    @include variables.fs14;
  }
}

.triangle {
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
}

.table {
  position: relative;
  z-index: 0;
}
.tableRow {
  position: relative;
  z-index: 1;
  display: grid;
  align-items: center;
  grid-template-columns: 1.3fr repeat(3, 1fr) 156px;

  font-weight: 500;
  @include variables.fs18;

  @include media.tablet {
    grid-template-columns: 1.3fr repeat(3, 1fr) 146px;
  }
  @include media.phoneH {
    grid-template-columns: 1.3fr repeat(2, 1fr) 130px;
    @include variables.fs14;
  }
  @include media.phoneV {
    grid-template-columns: 1.3fr 1fr 127px;
    @include variables.fs12;
  }
}

.tableTitleRow {
  position: relative;
  z-index: 2;
  grid-template-rows: 50px;
  border-radius: 20px 20px 0px 0px;

  background: variables.$mainColorForButtons;

  @include media.phone {
    grid-template-rows: 30px;
  }
}

.tableCurrencyRow {
  grid-template-rows: 59px;
  margin-top: -1px;

  border-top: 1px solid;
  border-bottom: 1px solid;
  border-image: var(--border-color) 1;

  &:last-child {
    border-bottom: unset;
  }

  @include media.phone {
    grid-template-rows: 42px;
  }
}

.tableFirstTitle {
  padding-left: 10px;
}

.phoneHidden {
  @include media.phone {
    display: none;
  }
}
.phoneVertHidden {
  @include media.phoneV {
    display: none;
  }
}
