@use '../../../media' as media;
@use '../../../variables' as variables;

.wrapper {
  display: grid;
  grid-template-columns: 770px 330px;
  width: 100%;
  gap: 100px;

  @include media.tablet {
    gap: 20px;
    grid-template-columns: 70% auto;
  }

  @include media.phone {
    gap: 40px;
    grid-template-columns: 100%;
  }
}

.mainNews {
  width: 100%;
  margin-bottom: 80px;

  @include media.phone {
    margin-bottom: 0px;
  }
}

.paragraph {
  @include variables.fs20;
  line-height: 200%;
  text-align: justify;

  @include media.mobileDevices {
    line-height: 150%;
  }
  @include media.phone {
    @include variables.fs14;
  }
}

.image {
  width: 100%;
  margin: 20px 0;
  aspect-ratio: 1.5;

  object-fit: cover;

  border-radius: 20px;
}

.dateAuthorWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.aboveLine {
  @include variables.fs16;
  margin-bottom: 10px;

  @include media.phoneH {
    @include variables.fs14;
  }
}

.alignRight {
  text-align: right;
}

.shareWrapper {
  display: flex;
  margin-top: 20px;
  gap: 20px;
  align-items: center;

  p {
    display: block;
    font-weight: 600;
    @include variables.fs20;
    margin-bottom: 0;
  }
}

.shareLink {
  display: grid;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  margin: 0;
  padding: 0;

  img {
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
    object-fit: cover;
  }
}

.sideNews {
  display: flex;
  flex-direction: column;
}

.sideNewsWrapper {
  display: grid;
  gap: 20px;
  margin-bottom: 30px;
  overflow-x: hidden;

  @include media.phone {
    grid-template-columns: repeat(3, 1fr);
  }
  @include media.phoneV {
    grid-template-columns: repeat(2, 1fr);
  }
}

.newsWrapper {
  width: 100%;
  height: fit-content;
}

.loadButton {
  width: 262px;
  margin-bottom: 20px;
  display: none;

  @include media.phone {
    align-self: center;
    display: block;
  }
}
