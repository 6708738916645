@use '../../media' as media;

.titleWrapper {
  z-index: 1;
  display: flex;
  width: 100%;
  height: fit-content;

  align-items: center;
  justify-content: space-between;

  margin-bottom: 40px;

  h2 {
    margin-bottom: 0;
  }

  @include media.mobileDevices {
    margin-bottom: 20px;
  }
}

.loadButtonWrapper {
  z-index: 1;
  justify-content: space-evenly;

  padding-bottom: 20px;

  h2 {
    z-index: 1;
  }
}

.loadButton {
  width: 262px;
  @include media.mobileDevices {
    width: 200px;
  }
}
