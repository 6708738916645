// Investments
.layout_Tokens
  .layout__investments
      gap: .25rem
      display: grid
      @include mediaMinWidthTablet
          grid-template-columns: 1fr 1fr
      .investments__layout
          gap: .25rem
          display: flex
          flex-direction: column
      .investments__main
          gap: 3rem
          height: 100%
          display: flex
          flex-direction: column
          justify-content: space-between
          @include mediaMinWidthMobile
              gap: 7rem
      .investments__text   
          color: $colorPrimary800
          strong
              position: relative
      .investments__media
          display: flex
          justify-content: center
          align-items: flex-end
          img
              max-width: 16rem
              transform: translateY(1rem)
              @include mediaMinWidthMobile
                  max-width: 32rem
              @include mediaMinWidthTablet
                  max-width: 100%
                  transform: translateY(1.5rem)
              @include mediaMinWidthDesktop
                  transform: translateY(2rem)
      .investments__footer
          margin-top: auto
          gap: .25rem
          display: flex
          flex-direction: column


.animet-text 
  
  margin: -2rem 0 0
  height: 50px
  line-height: 60px
  overflow: hidden
  padding: 0
  display: inline-block
  transform: translateY(.75rem)
  @include mediaMinWidthMobile
    height: 55px
    transform: translateY(.55rem)
  @include mediaMinWidthTablet
    height: 52px  
  @include mediaMinWidthDesktop
    height: 56px

.animet-text li 
  position: relative
  top: 0
  animation: slide 15s steps(6) infinite
@keyframes slide
  100% 
    top: -360px
.animet-text li span 
  position: relative
  margin: 10px 0
  line-height: 40px
  overflow: hidden
  background: linear-gradient(to right, #A717FF, #FF1292)
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent
.animet-text li span::after 
  content: ""
  position: absolute
  top: 0
  left: 0
  height: 40px
  width: 100%
  background: $colorTertiary400
  border-left: 3px solid #FF1292
  animation: typing 2.5s steps(15)  infinite
  @include mediaMinWidthMobile
    top: -.125rem
    height: 60px
@keyframes typing 
  20%,
  30% 
      left: calc(100% + 0px)
  90% 
      left: calc(100% + 0px)
  100% 
      left: 0
    
