// Statistics
.layout_Tokens
    .layout__statistics
        .statistics__list
            gap: .25rem
            display: grid
            grid-template-columns: repeat(2, 1fr)
            @include mediaMinWidthTablet
                grid-template-columns: repeat(4, 1fr)
        .statistics__item
            gap: 1.5rem
            display: flex
            flex-direction: column
            align-items: flex-start
            justify-content: space-between
            @include mediaMinWidthDesktop
                gap: 2rem
        .statistics__text
            font-size: 3.5rem
            line-height: 3.5rem
            @include mediaMinWidthMobile
                font-size: 4.5rem
                line-height: 4.5rem
            @include mediaMinWidthDesktop
                font-size: 5rem
                line-height: 5rem
        &.layout__statistics_secondary
            .statistics__item
                gap: 4.5rem
                @include mediaMinWidthMobile
                    gap: 7.5rem
                @include mediaMinWidthDesktop
                    gap: 8.258rem