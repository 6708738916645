// LAYOUT
.layout_Tokens
  font-size: 1rem
  line-height: 1.25rem
  min-height: 100vh
  overflow: hidden
  overflow-y: auto
  @include mediaMinWidthMobile
    font-size: 1.125rem
    line-height: 1.5rem
  @include mediaMinWidthDesktop
    font-size: 1.25rem
    line-height: 1.5rem
  &.layout_Tokens_secodnary
    background-color: $colorTertiary400
  .layout__main
    flex-grow: 1
