$mediaMinWidthDesktop: 1280px;
$mediaMaxWidthDesktop: 1279px;
$mediaMinWidthTablet: 841px;
$mediaMaxWidthTablet: 842px;
$mediaMinWidthMobile: 580px;
$mediaMaxWidthMobile: 579px;

/*mediaWidthDesktop-1201*/
@mixin mediaMinWidthDesktop {
  @media (min-width: #{$mediaMinWidthDesktop}) {
    @content;
  }
}
@mixin mediaMaxWidthDesktop {
  @media (max-width: #{$mediaMaxWidthDesktop}) {
    @content;
  }
}
/*WidthTabletLand-992*/
@mixin mediaMinWidthTablet {
  @media screen and (min-width: #{$mediaMinWidthTablet}) {
    @content;
  }
}
@mixin mediaMaxWidthTablet {
  @media screen and (max-width: #{$mediaMaxWidthTablet}) {
    @content;
  }
}

/*WidthTabletLand-580*/
@mixin mediaMinWidthMobile {
  @media screen and (min-width: #{$mediaMinWidthMobile}) {
    @content;
  }
}
@mixin mediaMaxWidthMobile {
  @media screen and (max-width: #{$mediaMaxWidthMobile}) {
    @content;
  }
}
