@import '../../helper/media';
@import '../../helper/variables';
// USER
.p2p__user {
  // DATASET
  .user__database {
    &:not(:last-child) {
      margin-bottom: 2rem;
      @include mediaMinWidthTablet {
        margin-bottom: 3rem;
      }
    }
    .database__header {
      @include box;
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
    .database__about {
      margin-bottom: 2rem;
      display: flex;
      flex-direction: column;
      @include mediaMinWidthMobile {
        flex-direction: row;
        align-items: center;
      }
      @include mediaMinWidthTablet {
        margin-bottom: 0;
      }
      &:not(:last-child) {
        margin-bottom: 1.5rem;
        @include box-shadow;
      }
      .about__avatar {
        width: 2.5rem;
        height: 2.5rem;
        margin-bottom: 0.5rem;
        display: flex;
        align-items: flex-start;
        @include mediaMinWidthMobile {
          margin-bottom: 0;
          margin-right: 1rem;
        }
      }
      .about__title {
        margin-bottom: 0.5rem;
        .title__text {
          color: $colorQuartyty500;
          font-size: 1.25rem;
          line-height: 1.25rem;
          font-weight: 500;
        }
      }
      .about__data {
        .data__list {
          width: calc(100% + 1.5rem);
          margin: 0 -1.5rem -0.5rem 0;
          display: flex;
          flex-wrap: wrap;
        }
        .data__item {
          color: $colorSecondary500;
          margin: 0 1.5rem 0.5rem 0;
          display: flex;
          align-items: center;
          &.data__item_secondary {
            color: $colorPrimary300;
            .data__media {
              img,
              svg {
                fill: $colorPrimary300;
              }
            }
          }
        }
        .data__media {
          width: 0.75rem;
          height: 0.75rem;
          display: flex;
          align-items: flex-start;
          transform: translateY(-0.125rem);
          &:not(:last-child) {
            margin-right: 0.25rem;
          }
          img,
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .database__action {
      @include mediaMinWidthTablet {
        height: 100%;
        display: flex;
        align-items: center;
      }
      .action__list {
        margin: 0 -1rem -1rem;
        width: calc(100% + 2rem);
        display: flex;
        flex-wrap: wrap;
        @include mediaMinWidthTablet {
          justify-content: flex-end;
        }
      }
      .action__item {
        margin: 0 1rem 1rem 0;
        width: 100%;
        @include mediaMinWidthMobile {
          width: auto;
        }
      }
    }
    .database__groups {
      .groups__col {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      .groups__list{
        margin-bottom: -1.25rem;
      }
      .groups__item {
        margin-bottom: 1.25rem;
        font-size: 0.75rem;
        line-height: 1rem;
        min-height: 7.125rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include box();
        @include mediaMinWidthTablet {
          min-height: 0;
          flex-direction: row;
          align-items: center;
        }
      }

      .group__header {
        &:not(:last-child) {
          margin-bottom: 0.75rem;
          @include mediaMinWidthTablet {
            margin-bottom: 0;
            margin-right: 0.25rem;
          }
        }
        .group__title {
          &:not(:last-child) {
            margin-bottom: 0.5rem;
          }
          .title__text {
            color: $colorPrimary300;
          }
        }
        .group__data {
          .data__list {
            display: flex;
            @include mediaMinWidthTablet {
              justify-content: space-between;
            }
          }
          .data__item {
            display: flex;
            align-items: center;
            &:not(:last-child) {
              margin-right: 0.5rem;
            }
            &.data__item_buy {
              color: $colorSecondary400;
            }
            &.data__item_sell {
              color: $colorTertiary400;
            }
            .data__text {
              font-weight: 500;
            }
          }
          .data__media {
            width: 0.75rem;
            height: 0.75rem;
            display: flex;
            align-items: flex-start;
            &:not(:last-child) {
              margin-right: 0.25rem;
            }
            img,
            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .group__main {
        background: none;
        padding: 0;
        .group__value {
          .data__text {
            color: $colorPrimary400;
            font-size: 1.25rem;
            line-height: 1.25rem;
            font-weight: 500;
            white-space: nowrap;
          }
        }
      }

      .groups__action {
        margin-top: auto;
        margin-bottom: 1.25rem;
      }
    }
    .mob-order-5 {
      @include mediaMaxWidthMobile {
        order: 5;
      }
    }
    .mob-order-6{
      @include mediaMaxWidthMobile {
        order: 6;
      }
    }
  }
  // PAYMENT
  .user__payments {
    border-radius: 1.5rem;
    overflow: hidden;
    @include box-shadow;
    .payments__header {
      padding: 1.5rem;
      background-color: $colorWhite;
      border-bottom: 1px solid $colorPrimary600;
      display: flex;
      @include mediaMinWidthTablet {
        align-items: center;
      }
      .payments__description {
        color: $colorPrimary300;
        font-size: 0.875rem;
        line-height: 1.2rem;
        margin-bottom: 1rem;
        @include mediaMinWidthTablet {
          margin-bottom: 0;
        }
        h1,
        h2,
        h3 {
          color: $colorPrimary400;
        }
      }
      .payments__action {
        @include mediaMinWidthTablet {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
        .action__list {
          margin: 0 -1rem -1rem;
          width: calc(100% + 2rem);
          display: flex;
          flex-wrap: wrap;
        }
        .action__item {
          margin: 0 1rem 1rem 0;
        }
      }
    }
    .payments__main {
      padding: 1.5rem;
      background-color: $colorWhite;
    }
    .payments__list {
      display: flex;
      flex-direction: column;
    }
    .payments__item {
      display: flex;
      flex-direction: column;
      @include mediaMinWidthMobilePort {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
      &:not(:last-child) {
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
        border-bottom: 1px solid $colorPrimary600;
      }
      .payment__layout {
        &:not(:last-child) {
          margin-bottom: 1.5rem;
          @include mediaMinWidthMobilePort {
            margin-bottom: 0;
            margin-right: 1rem;
          }
        }
      }
      .payment__header {
        &:not(:last-child) {
          margin-bottom: 1.5rem;
          @include mediaMinWidthMobilePort {
            margin-bottom: 0.5rem;
          }
        }
        .payment__title {
          margin-bottom: 0.5rem;
          @include mediaMinWidthMobilePort {
            margin-bottom: 0;
            margin-right: 1rem;
          }
          .title__text {
            color: $colorQuartyty500;
            font-size: 1.125rem;
            line-height: 1.5rem;
          }
        }
      }
      .payment__action {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .action__list {
          margin: 0 -0.25rem -1rem;
          width: calc(100% + 0.5rem);
          display: flex;
          @include mediaMinWidthTablet {
            margin: 0 -1rem -1rem;
            width: calc(100% + 2rem);
          }
        }
        .action__item {
          padding: 0 0.25rem;
          width: 100%;
          margin-bottom: 1rem;
          @include mediaMinWidthTablet {
            padding: 0 1rem;
            width: auto;
          }
        }
        .btn {
          width: 100%;
          @include mediaMinWidthTablet {
            width: auto;
          }
        }
      }
      .payment__data {
        .data__list {
          margin: 0 -1.25rem -0.5rem;
          width: calc(100% + 2.5rem);
          display: flex;
          flex-direction: column;
          @include mediaMinWidthMobilePort {
            margin-bottom: -1rem;
            flex-direction: row;
            flex-wrap: wrap;
          }
        }
        .data__item {
          margin-bottom: 0.5rem;
          padding: 0 1.25rem;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          @include mediaMinWidthMobilePort {
            margin-bottom: 1rem;
            max-width: 14rem;
            flex-direction: column;
            justify-content: flex-start;
          }
        }
        .data__title {
          &:not(:last-child) {
            margin-bottom: 0.25rem;
          }
          .title__text {
            color: $colorPrimary300;
            font-size: 0.875rem;
            line-height: 1rem;
          }
        }
        .data__value {
          .data__text {
            color: $colorPrimary400;
            font-size: 1rem;
            line-height: 1.25rem;
            font-weight: 500;
          }
        }
      }
    }
  }
  // TESTIMONIALS
  .user__testimonials {
    @include box;
    .testimonials__header {
      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem;
      border-bottom: 1px solid $colorPrimary600;
      display: flex;
      flex-direction: column;
      @include mediaMinWidthTablet {
        flex-direction: row;
        align-items: center;
      }
    }
    .testimonials__rating {
      margin-bottom: 1.5rem;
      display: flex;
      align-items: center;
      @include mediaMinWidthTablet {
        margin-bottom: 0;
      }
      .rating__title {
        &:not(:last-child) {
          margin-right: 1.5rem;
        }
        .title__text {
          color: $colorPrimary300;
          font-size: 0.875rem;
          line-height: 1rem;
        }
      }
      .rating__value {
        .data__text {
          color: $colorPrimary400;
          font-size: 1.25rem;
          line-height: 1.5rem;
          font-weight: 500;
        }
      }
    }
    .testimonials__statistics {
      @include mediaMinWidthTablet {
        margin-left: auto;
      }
      .statistics__list {
        width: calc(100% + 0.5rem);
        margin: 0 -0.5rem -0.5rem 0;
        display: flex;
      }
      .statistics__item {
        font-size: 1rem;
        line-height: 1.25rem;
        font-weight: 500;
        padding: 0.75rem 0.5rem;
        margin: 0 0.5rem 0.5rem 0;
        border-radius: 1.5rem;
        &.statistics__item_like {
          background-color: $colorSecondary300;
        }
        &.statistics__item_dislike {
          background-color: $colorTertiary300;
        }
      }
    }
    .testimonials__list {
      display: flex;
      flex-direction: column;
    }
    .testimonials__item {
      font-size: 0.875rem;
      line-height: 1rem;
      display: flex;
      flex-direction: column;
      @include mediaMinWidthTablet {
        flex-direction: row;
      }
      &:not(:last-child) {
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
        border-bottom: 1px solid $colorPrimary600;
      }
      .testimonial__header {
        margin-bottom: 1.5rem;
        display: flex;
        align-items: center;
        @include mediaMinWidthTablet {
          margin-bottom: 0;
        }
      }
      .testimonial__avatar {
        width: 2.5rem;
        height: 2.5rem;
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
      .testimonial__data {
        display: flex;
        flex-direction: column;
        .testimonial__name {
          &:not(:last-child) {
            margin-bottom: 0.25rem;
          }
          .name__text {
            color: $colorQuartyty500;
            font-size: 1.25rem;
            line-height: 1.5rem;
            font-weight: 500;
          }
        }
        .testimonial__date {
          .date__text {
            color: $colorPrimary300;
            font-size: 0.875rem;
            line-height: 1rem;
            white-space: nowrap;
          }
        }
      }
      .testimonial__main {
        display: flex;
      }
      .testimonial__media {
        width: 0.75rem;
        min-width: 0.75rem;
        height: 0.75rem;
        display: flex;
        align-items: flex-start;
        &:not(:last-child) {
          margin-right: 1.5rem;
        }
      }
    }
  }
  // BLACKLIST
  .user__blacklist {
    @include box;
    .blacklist__list {
      display: flex;
      flex-direction: column;
    }
    .blacklist__item {
      &:not(:last-child) {
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
        border-bottom: 1px solid $colorPrimary600;
      }
    }
    .blacklist__data {
      margin-bottom: 1rem;
      @include mediaMinWidthTablet {
        margin-bottom: 0;
      }
      .data__list {
        margin: 0 -1.25rem -0.5rem;
        width: calc(100% + 2.5rem);
        @include mediaMinWidthTablet {
          margin-bottom: -1rem;
        }
      }
      .col {
        @include mediaMaxWidthTablet {
          &:nth-child(2) {
            order: 3;
          }
        }
      }
      .data__item {
        margin-bottom: 0.5rem;
        padding: 0 1.25rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
      .data__title {
        &:not(:last-child) {
          margin-bottom: 0.25rem;
        }
        .title__text {
          color: $colorPrimary300;
          font-size: 0.875rem;
          line-height: 1rem;
        }
      }
      .data__value {
        .data__text {
          color: $colorPrimary400;
          font-size: 1rem;
          line-height: 1.25rem;
          font-weight: 500;
        }
      }
    }
    .blacklist__action {
      @include mediaMinWidthTablet {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}
