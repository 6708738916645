@use 'src/media' as media;
@use 'src/variables' as variables;

.currencyPairs {
  @include media.phoneV {
    margin: 0 -10px;
    padding: 0 10px 20px 10px;
    display: flex;
    gap: 20px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
}

.currencyPairBlocksWrapper {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(12, calc((100% - 10px * 11) / 12));
  grid-template-areas:
    'c1 c1 c2 c2 c3 c3 c4 c4 c5 c5 c6 c6 '
    'v1 v1 v1 c7 c7 c8 c8 c9 c9 v2 v2 v2';
  justify-content: center;
  gap: 10px;

  flex-wrap: wrap;

  @include media.tablet {
    gap: 5px;
    grid-template-columns: repeat(20, calc((100% - 5px * 19) / 20));
    grid-template-areas:
      'c1 c1 c1 c1 c2 c2 c2 c2 c3 c3 c3 c3 c4 c4 c4 c4 c5 c5 c5 c5'
      'v1 v1 c6 c6 c6 c6 c7 c7 c7 c7 c8 c8 c8 c8 c9 c9 c9 c9 v2 v2';
  }
  @include media.phoneH {
    gap: 10px;
    grid-template-columns: repeat(20, calc((100% - 10px * 19) / 20));
    grid-template-areas:
      'c1 c1 c1 c1 c2 c2 c2 c2 c3 c3 c3 c3 c4 c4 c4 c4 c5 c5 c5 c5'
      'v1 v1 c6 c6 c6 c6 c7 c7 c7 c7 c8 c8 c8 c8 c9 c9 c9 c9 v2 v2';
  }
  @include media.phoneV {
    gap: 10px;
    grid-template-columns: repeat(3, calc((100% - 10px * 2) / 3));
    grid-template-areas:
      'c1 c2 c3'
      'c4 c5 c6'
      'c7 c8 c9';
  }
}
.currencyPairBlocksWrapper4Columns {
  grid-template-columns: repeat(4, calc((100% - 10px * 4) / 5));

  @include media.phoneV {
    grid-template-columns: repeat(4, 98px);
  }
}

.currencyPairBlock {
  width: 100%;
  height: 46px;
  padding-left: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;

  overflow: hidden;

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  cursor: pointer;

  @include media.mobileDevices {
    padding: 10px;
  }

  img {
    align-self: center;
    width: 178px;
    height: 40px;

    @include media.tablet {
      width: 104px;
      height: 23px;
    }
    @include media.phone {
      width: 78px;
      height: 17px;
    }
  }
  span {
    width: fit-content;
  }

  &:nth-of-type(1) {
    grid-area: c1;
  }
  &:nth-of-type(2) {
    grid-area: c2;
  }
  &:nth-of-type(3) {
    grid-area: c3;
  }
  &:nth-of-type(4) {
    grid-area: c4;
  }
  &:nth-of-type(5) {
    grid-area: c5;
  }
  &:nth-of-type(6) {
    grid-area: c6;
  }
  &:nth-of-type(7) {
    grid-area: c7;
  }
  &:nth-of-type(8) {
    grid-area: c8;
  }
  &:nth-of-type(9) {
    grid-area: c9;
  }
}

.currencyPairBlockGreen {
  background: #dbfff6;
}
.currencyPairBlockRed {
  background: #fee1ea;
}
.currencyPairBlockActive {
  background: linear-gradient(135deg, #8c00fd 0%, #a800c0 100%);

  .currencyPair,
  .currencyPairValue {
    color: #ffffff;
  }
}

.currencyPairPofit {
  width: min-content;
  height: min-content;
  position: absolute;
  z-index: 2;
  top: 50%;
  right: 10px;
  padding: 3px 1.5px;
  transform: translateY(-50%);

  font-weight: 600;
  vertical-align: middle;
  @include variables.fs12;

  box-shadow: 5px 5px 10px rgba(127, 0, 226, 0.2);
  border-radius: 23px;

  @include media.phone {
    display: none;
  }
}
.currencyPairProfitGreen {
  background: #f1fffc;
  color: #0e8568;
}
.currencyPairProfitRed {
  background: #fefbfc;
  color: #ff004d;
}
.currencyPairProfitGreenActive {
  background: #f6edfd;
  color: #0e8568;
}
.currencyPairProfitRedActive {
  background: #f6edfd;
  color: #ff004d;
}

.currencyPair {
  white-space: nowrap;
  font-weight: 500;
  @include variables.fs18;

  @include media.mobileDevices {
    @include variables.fs14;
  }
  @include media.phone {
    align-self: center;
  }
}

.currencyPairValue {
  margin-top: 12px;
  margin-bottom: 5px;

  font-weight: 500;
  @include variables.fs32;

  @include media.mobileDevices {
    @include variables.fs16;
  }
}

.currencyPairChartGreenActive {
  filter: invert(95%) sepia(87%) saturate(5852%) hue-rotate(82deg) brightness(104%) contrast(105%);
}
.currencyPairChartGreen {
  filter: invert(36%) sepia(78%) saturate(1340%) hue-rotate(138deg) brightness(97%) contrast(87%);
}
.currencyPairChartRedActive {
  filter: invert(11%) sepia(100%) saturate(6858%) hue-rotate(338deg) brightness(101%) contrast(103%);
}
.currencyPairChartRed {
  filter: invert(11%) sepia(100%) saturate(6858%) hue-rotate(338deg) brightness(101%) contrast(103%);
}
