.layout_Tokens
  // Form item
  .form__item
    display: grid
    position: relative
    grid-gap: .75rem
  // Form heder
  .form__header
    .form__title
      color: $colorPrimary500
      font-size: 1rem
      line-height: 1.25rem
      font-weight: 400
      cursor: pointer
      @include transition
      &:hover
        color: $colorSecondary500
  // Form main
  .form__main
    gap: .125rem
    display: flex
    flex-direction: column
  // Form footer
  .form__footer
    .form__tooltip
      color: $colorPrimary500
      font-size: .75rem
      line-height: 1.25rem
      font-weight: 400
      @include mediaMinWidthMobile
        font-size: .875rem
      @include mediaMinWidthDesktop
        font-size: 1rem

  // Form field
  .form__field
    border-radius: .5rem
    padding: .75rem
    gap: .5rem
    background-color: $colorWhite1000
    border: 1px solid $colorFifth200
    display: flex
    align-items: center
    @include transition
    @include mediaMinWidthMobile
      padding: 1rem
    @include mediaMinWidthDesktop
      padding: 1.25rem
    &:hover
      border-color: $colorSecondary1000
    &:not(.form__field_secondary)
      justify-content: space-between
    &.form__field_secondary
      align-items: flex-start
    &.form__field_tiny
      padding: .75rem
    .field__touch
      width: 1.5rem
      height: 2rem
      flex-shrink: 0
      display: flex
      justify-content: center
      align-items: center
      cursor: move
      svg
        width: 100%
        height: 100%
    .field__header
      gap: .75rem
      display: flex
      align-items: center
    .field__ico
      width: 2rem
      height: 2rem
      flex-shrink: 0
      display: none
      @include mediaMinWidthMobile
        display: flex
    .field__title
      color: $colorPrimary500
      font-size: .75rem
      line-height: 2rem
      font-weight: 500
      @include mediaMinWidthMobile
        font-size: .875rem
      @include mediaMinWidthDesktop
        font-size: 1rem
    .field__main
      gap: .125rem
      margin-left: auto
      display: flex
      align-items: center
  // Form button
  .form__btn
    max-width: 4.5rem
    @include mediaMinWidthMobile
      max-width: 5.75rem
    @include mediaMinWidthDesktop
      max-width: 7.5rem
  // Form ico
  .form__ico
    width: 1.5rem
    height: 1.5rem
    flex-shrink: 0
    cursor: pointer
    transform: scale(1)
    @include transition
    &.form__ico_secondary
      width: 1.25rem
      height: 1.25rem
      &:hover
        transform: scale(1.1)
    &:hover
      transform: scale(1.1)
  // Form input
  .form__input
    color: $colorPrimary1000
    font-weight: 500
    font-family: $mainFont
    width: 100%
    border: 0
    height: 2rem
    text-overflow: ellipsis
    box-sizing: border-box
    appearance: none
    outline: none
    @include transition
    &:not(.form__input_secondary)
      font-size: .875rem
      line-height: 1.5rem
      @include mediaMinWidthMobile
        font-size: 1.125rem
      @include mediaMinWidthDesktop
        font-size: 1.25rem
    &.form__input_secondary
      padding: .25rem .75rem
      font-size: .75rem
      line-height: 1.25rem
      border-radius: .375rem
      background-color: $colorTertiary400
      @include mediaMinWidthMobile
        font-size: .875rem
      @include mediaMinWidthDesktop
        font-size: 1rem
    &:disabled
      opacity: .5
    &::placeholder
      opacity: 1
      color: $colorPrimary500

    // Form textarea
    &.form__input_textarea
      width: 100%
      height: 6.875rem
      resize: none

    &.form__input_textareaSecondary
      @include mediaMinWidthDesktop
        height: 14.5rem
  .form__dropdown
    padding: .75rem 0
    border-radius: .375rem
    width: 100%
    background-color: $colorWhite1000
    position: absolute
    top: calc(100% + .5rem)
    left: 0
    box-shadow: 0px 4px 32px 0px #00000014

  select
    background-image: url("../../img/arrowBottom.png")
    background-repeat: no-repeat
    background-position: 98%
    background-size: auto 1.5rem

// Стилизация скролла
::-webkit-scrollbar
  width: .25rem
  height: 0

::-webkit-scrollbar-track
  background: transparent

::-webkit-scrollbar-thumb
  background-color: $colorSecondary400

  &:hover
    background-color: $colorSecondary400

