@import 'src/media';
@import 'src/variables';


  .about {
    .about__picture {
      margin-bottom: 2em;
      max-width: 36.25rem;
      display: flex;
      align-items: flex-start;
      @include mediaMinWidthMobile {
        margin-bottom: 0;
      }
    }
    .about__main {
      @include mediaMinWidthMobile {
        max-width: 30rem;
      }
    }
  }


.phoneOnly {
  @include mediaMinWidthDesktop {
    display: none!important;
  }
}
.desktopOnly {
  @include mediaMaxWidthDesktop {
    display: none!important;
  }
}