@use '../../../../media' as media;
@use '../../../../variables' as variables;

.image {
  width: 100%;
  height: 100%;

  border-radius: 20px;

  object-fit: cover;
}

.textWrapper {
  display: grid;
  width: 100%;
  height: fit-content;
  gap: 10px;

  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    'date author'
    'title title';

  @include media.phone {
    gap: 5px;
  }
}

.date {
  grid-area: date;
  width: auto;
  margin: 0;

  @include variables.fs16;
  @include media.phone {
    @include variables.fs12;
  }
}
.author {
  grid-area: author;
  width: auto;
  margin: 0;

  text-align: right;
  @include variables.fs16;
  @include media.phone {
    @include variables.fs12;
  }
}
.title {
  grid-area: title;
  width: 100%;

  font-weight: 600;
  @include variables.fs20;
  @include media.phone {
    @include variables.fs14;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}
