// Banner
.layout_Tokens
    .layout__banner
        color: $colorWhite1000
        background-color: $colorSecondary1000
        padding: 1rem
        border-radius: 1.25rem
        gap: 2rem
        height: 26rem
        position: relative
        display: flex
        flex-direction: column
        justify-content: space-between
        overflow: hidden
        @include mediaMinWidthMobile
            padding: 2rem
        .banner__media
            position: absolute
            right: 0
            bottom: 0
            width: 100%
            height: 100%
            display: flex
            justify-content: flex-end
            align-items: flex-end
            img
                height: 100%
                width: auto
        .banner__header
            max-width: 26.5rem
        .banner__header,
        .banner__footer
            position: relative
            z-index: 2

        .banner__footer
            width: 100%  
            @include mediaMinWidthTablet
                width: 26.25rem