// Base
.layout_Tokens
  .container
    width: 85rem
    max-width: calc(100vw - 2rem)
    margin: 0 auto
    @include mediaMinWidthTablet
      max-width: calc(100vw - 4rem)
  .pb-0
    padding-bottom: 0!important
  .layout__box
    padding: 1rem
    border-radius: .75rem
    background-color: $colorTertiary400
    @include mediaMinWidthMobile
        padding: 1.5rem
    @include mediaMinWidthDesktop
        padding: 2rem
        border-radius: 1.25rem
    &.layout__box_secondary
      padding: .875rem
      @include mediaMinWidthMobile
        padding: 1rem
      @include mediaMinWidthDesktop
        padding: 1.25rem
    &.layout__box_light
      background-color: $colorWhite1000
  .layout__tag
    font-weight: 500
    padding: .5rem 1rem
    border-radius: 2rem
    background-color: $colorWhite1000
    display: block
    @include mediaMinWidthMobile
        font-size: 1rem
        line-height: 1.5rem
        padding: .75rem 1.25rem
    @include mediaMinWidthDesktop
        font-size: 1.25rem
        line-height: 1.5rem