// Cooperation
.layout_Tokens
    .layout__cooperation
        gap: .25rem
        display: grid
        @include mediaMinWidthTablet
            grid-template-columns: 1fr 1fr
        .cooperation__banner
            position: relative
        .banner__wrapper
            color: $colorWhite1000
            background-color: $colorSecondary1000
            padding: 1.5rem
            border-radius: 1.25rem
            gap: 2rem
            position: relative
            display: flex
            flex-direction: column
            justify-content: space-between
            @include mediaMinWidthMobile
                padding: 2rem
                height: 35rem
                max-width: 46vw
                height: 26rem
                position: absolute
            @include mediaMinWidthTablet
                &.banner__wrapper_fixed
                    position: fixed
                    top: 0
                &.banner__wrapper_bottom
                    bottom: 0
                    top: auto
                    position: absolute
    

            .banner__background
                position: absolute
                left: 0
                bottom: 0
                width: 100%
                height: 100%
                display: flex
                justify-content: flex-start
                align-items: flex-end
            .banner__header,
            .banner__footer
                position: relative
                z-index: 2
            .banner__footer
                width: 100%  
                @include mediaMinWidthTablet
                    width: 26.25rem
        .cooperation__list
            display: grid
            gap: .25rem
        .cooperation__item
            gap: 2.5rem
            display: flex
            flex-direction: column
            justify-content: space-between
            align-items: flex-start
            position: relative
            @include mediaMinWidthMobile
                padding-right: 14.5rem
                gap: 4rem
            @include mediaMinWidthDesktop
                gap: 5.5rem
        .cooperation__text
            max-width: 25rem
        .cooperation__img
            width: 12.5rem
            height: 12.5rem
            margin: 0 auto -.75rem
            @include mediaMinWidthMobile
                position: absolute
                right: 0
                bottom: 0
                margin: 0
                width: 14.5rem
                height: 15.5rem
                object-fit: contain
                object-position: bottom
                









