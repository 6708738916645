@import '../helper/media';
@import '../helper/variables';
// button
.layout__header,
.p2p,
.p2p__modal,
.account,
.layout {
  .btn {
    color: $colorWhite;
    background-color: $colorPrimary400;
    border: 0.0625rem solid $colorPrimary400;
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 500;
    padding: 0.75rem;
    min-width: 2.5rem;
    height: 2.5rem;
    border-radius: 2rem;
    margin: 0;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
    text-align: center;
    appearance: none;
    @include transition;
    &.btn_secondary-padding {
      @include mediaMinWidthMobile {
        padding: 0.75rem 2rem;
      }
    }
    &:hover {
      color: $colorPrimary400;
      background: $colorWhite;
      border-color: $colorPrimary400;
      text-decoration: none;
      .btn__ico {
        path {
          fill: $colorPrimary400;
        }
      }
    }

    &:disabled {
      color: $colorWhite;
      border-color: $colorPrimary300;
      background-color: $colorPrimary300;
    }

    &.btn_revert {
      color: $colorPrimary400;
      background-color: $colorWhite;
      border-color: $colorPrimary400;

      &:hover {
        color: $colorWhite;
        background-color: $colorPrimary400;
        border-color: $colorPrimary400;
      }

      &:disabled {
        color: $colorWhite;
        border-color: $colorPrimary300;
        background-color: $colorPrimary300;
      }
    }
    &.btn_secondary {
      color: $colorWhite;
      background-color: $colorSecondary500;
      border-color: $colorSecondary500;

      &:hover {
        color: $colorWhite;
        background-color: $colorSecondary400;
        border-color: $colorSecondary400;
      }

      &:disabled {
        color: $colorPrimary400;
        background-color: $colorSecondary300;
        border-color: $colorSecondary300;
      }

      &.btn_revert {
        color: $colorSecondary400;
        background-color: transparent;
        border-color: $colorSecondary400;

        &:hover {
          color: $colorWhite;
          background-color: $colorSecondary500;
          border-color: $colorSecondary500;
        }

        &:disabled {
          color: $colorPrimary400;
          background-color: $colorSecondary300;
          border-color: $colorSecondary300;
        }
      }
    }
    &.btn_tertiary {
      color: $colorWhite;
      background-color: $colorTertiary500;
      border-color: $colorTertiary500;

      &:hover {
        color: $colorTertiary600;
        background-color: transparent;
        border-color: $colorTertiary600;
      }

      &:disabled {
        color: $colorPrimary400;
        background-color: $colorTertiary300;
        border-color: $colorTertiary300;
      }

      &.btn_revert {
        color: $colorTertiary600;
        background-color: transparent;
        border-color: $colorTertiary600;

        &:hover {
          color: $colorWhite;
          background-color: $colorTertiary500;
          border-color: $colorTertiary500;
        }

        &:disabled {
          color: $colorPrimary400;
          background-color: $colorTertiary300;
          border-color: $colorTertiary300;
        }
      }
    }
    &.btn_quartyty {
      color: $colorWhite;
      background-color: $colorQuartyty500;
      border-color: $colorQuartyty500;

      &:hover {
        color: $colorWhite;
        background-color: $colorQuartyty400;
        border-color: $colorQuartyty400;
      }

      &:disabled {
        color: $colorPrimary400;
        background-color: $colorQuartyty300;
        border-color: $colorQuartyty300;
      }

      &.btn_revert {
        color: $colorQuartyty500;
        background-color: transparent;
        border-color: $colorQuartyty500;

        &:hover {
          color: $colorWhite;
          background-color: $colorQuartyty500;
          border-color: $colorQuartyty500;
        }

        &:disabled {
          color: $colorPrimary400;
          background-color: $colorTertiary300;
          border-color: $colorTertiary300;
        }
      }
    }

    &.btn_sixty {
      color: $colorWhite;
      background-color: $colorSecondary500;
      border-color: $colorSecondary500;

      &:hover {
        color: $colorWhite;
        background-color: $colorSecondary400;
        border-color: $colorSecondary400;
      }

      &:disabled {
        color: $colorPrimary400;
        background-color: $colorSecondary300;
        border-color: $colorSecondary300;
      }

      &.btn_revert {
        color: $colorSecondary500;
        background-color: transparent;
        border-color: $colorSecondary500;

        &:hover {
          color: $colorWhite;
          background-color: $colorSecondary500;
          border-color: $colorSecondary500;
        }

        &:disabled {
          color: $colorPrimary400;
          background-color: $colorSecondary300;
          border-color: $colorSecondary300;
        }
      }
    }

    &.btn_secondary-radius {
      border-radius: 0.625rem;
    }
    &.btn_m-circly {
      @include mediaMaxWidthMobile {
        width: 2.5rem;
        height: 2.5rem;
        padding: 0;
        .btn__text {
          display: none;
        }
      }
    }
    &.btn_large {
      height: 4.5rem;
      border-width: 0.125rem;
      box-shadow: 0 0.25rem 1.5rem rgba(0, 0, 0, 0.15);
    }
  }

  .btn__ico,
  .btn__text {
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
  .btn__ico {
    path {
      @include transition;
    }
    img,
    svg {
      width: 1rem;
      min-width: 1rem;
      height: 1rem;
    }
  }
}
.btn-round {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 0;
}
