// Steps
.layout_Tokens
    .layout__steps
        gap: .25rem
        display: flex
        flex-direction: column
        .steps__list
            gap: .25rem
            display: grid
            @include mediaMinWidthMobile
                grid-template-columns: repeat(2, 1fr)
            @include mediaMinWidthTablet
                grid-template-columns: repeat(4, 1fr)
        .steps__item
            gap: 1.5rem
            display: flex
            flex-direction: column
            align-items: flex-start
            @include mediaMinWidthMobile
                gap: 2rem


        .steps__img
            width: 17.5rem
            height: 18.5rem
            margin: auto auto -.5rem
            object-fit: contain
            @include mediaMinWidthMobile
                margin-bottom: -1rem
            @include mediaMinWidthDesktop
                margin-bottom: -1.25rem
    















