@import '../../helper/media';
@import '../../helper/variables';
// VERIFICATION
.layout__verification {
  .verification__list {
    display: flex;
    flex-direction: column;
  }
  .verification__item {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
    &:not(:last-child) {
      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem;
      border-bottom: 1px solid $colorPrimary600;
    }
  }
  .verification__header {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
    justify-content: center;
    @include mediaMinWidthMobile {
      grid-template-columns: auto auto;
      align-items: center;
      grid-gap: 0;
      justify-content: space-between;
    }
    &.verification__header_secondary {
      grid-template-columns: 1fr;
      grid-gap: 1.5rem;
      @include mediaMinWidthDesktop {
        grid-template-columns: auto auto;
        grid-gap: 0;
      }
      .verification__title {
        margin-right: auto;
        @include mediaMinWidthDesktop {
          margin-right: unset;
        }
      }
      .verification__action {
        justify-content: center;
        @include mediaMinWidthDesktop {
          justify-content: flex-start;
        }
        .btn {
          min-width: 17.75rem;
          width: auto;
          @include mediaMinWidthDesktop {
            width: 100%;
          }
        }
      }
    }
  }
  .verification__title {
    margin-right: auto;
    display: grid;
    grid-template-columns: auto 1.5rem;
    grid-gap: 0.5rem;
    @include mediaMinWidthMobile {
      margin-right: unset;
    }
    .verification__text {
      margin: 0;
    }
    .verification__status {
      margin-right: auto;
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      align-items: flex-start;
    }
  }

  .verification__action {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
    justify-content: center;
    @include mediaMinWidthMobile {
      justify-content: flex-start;
      flex-wrap: nowrap;
    }
    .btn {
      min-width: 17.75rem;
      @include mediaMinWidthMobile {
        width: 100%;
      }
    }
  }
  .verification__main {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
  }
}
// verification__data
.verification__data {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;
  justify-content: center;
  @include mediaMinWidthMobile {
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
  }
}
// verification__advantages
.verification__advantages {
  .advantages__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.75rem;
  }
  .advantages__item {
    color: $colorPrimary700;
    font-size: 0.875rem;
    line-height: 1rem;
    display: grid;
    grid-template-columns: 1.125rem auto;
    grid-gap: 0.75rem;
    align-items: center;
  }
}
// verification__time
.verification__time {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.25rem;
  align-items: center;
  order: -1;
  @include mediaMinWidthMobile {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
    text-align: right;
    order: unset;
  }
  .time__title {
    color: $colorPrimary700;
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: right;
  }
  .time__value {
    color: $colorPrimary400;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
  }
}
// verification__box
.verification__box {
  .box__list {
    display: grid;
    grid-gap: 1.25rem;
    grid-template-columns: 1fr;
    @include mediaMinWidthMobile {
      grid-template-columns: repeat(2, 16.25rem);
    }
    &.box__list_secondary {
      @include mediaMinWidthMobile {
        grid-template-columns: repeat(2, 21rem);
      }
    }
  }
  .box__item {
    color: $colorPrimary400;
    background-color: $colorPrimary100;
    border-radius: 1.5rem;
    padding: 1.5rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.75rem;
  }
  .box__main {
    font-size: 0.875rem;
    line-height: 1rem;
    .wysiwyg {
      p,
      ul,
      li {
        &:not(:last-child) {
          margin-bottom: 0.75rem;
        }
      }
    }
  }
  .box__data {
    .data__list {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0.75rem;
      margin: 0;
    }
    .data__item {
      margin: 0;
      display: grid;
      justify-content: space-between;
      grid-template-columns: auto auto;
      grid-gap: 0.75rem;
    }
    .data__text {
      color: $colorPrimary700;
    }
    .data__value {
      color: $colorPrimary400;
    }
  }
}

// verification__formular
.verification__formular {
  margin: 0 !important;
  display: grid;
  grid-gap: 1.25rem;
  .formular__group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.25rem;
    @include mediaMinWidthMobile {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .form-item {
    margin: 0;
  }
}

// verification__information
.verification__information {
  color: $colorPrimary700;
  font-size: 0.875rem;
  line-height: 1rem;
}

// verification__examples
.verification__examples {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.75rem;
  .examples__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.25rem;
    @include mediaMinWidthMobile {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .examples__item {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
  }
  .examples__media {
    border-radius: 1.5rem;
    padding-top: 50%;
    width: 14rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .examples__footer {
    display: flex;
    justify-content: center;
  }
  .examples__text {
    color: #20263950;
    text-align: center;
    font-weight: 500;
  }
}
// account__instructions
.account__instructions {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;
  @include mediaMinWidthMobile {
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  .instructions__header {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
    @include mediaMinWidthMobile {
      grid-column: span 2;
    }
  }
  .instructions__description {
    color: $colorPrimary700;
    font-size: 0.875rem;
    line-height: 1rem;
    ul,
    ol,
    p {
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
    strong {
      color: $colorTertiary500;
      font-size: 1rem;
      line-height: 1rem;
      font-weight: 500;
    }
  }
  .instructions__main {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
    @include mediaMinWidthMobile {
      grid-template-columns: 17.875rem auto;
    }
  }
  .instructions__information {
    color: $colorQuartyty500;
    font-size: 0.875rem;
    line-height: 1rem;
    @include mediaMinWidthMobile {
      grid-column: span 2;
    }
  }
  .instructions__steps {
    @include mediaMinWidthMobile {
      grid-column: span 2;
    }
    .steps__list {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-gap: 0.5rem;
    }
    .steps__item {
      font-size: 2rem;
      line-height: 2rem;
      width: 3rem;
      height: 3.625rem;
      border-radius: 2rem;
      border: 1px solid $colorPrimary700;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
  .instructions__media {
    width: 19.5rem;
    height: 17.5rem;
    margin: 0 auto;
    order: -1;
    display: flex;
    align-items: flex-start;
    @include mediaMinWidthMobile {
      order: 2;
      margin: 0;
    }
    img,
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
