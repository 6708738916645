$colorWhite: rgba(255, 255, 255, 1);
// Primary color
$colorMain100: #20263910;
$colorMain200: #20263920;
$colorMain300: #20263930;
$colorMain400: #20263940;
$colorMain500: #20263980;
$colorMain600: #20263960;
$colorMain700: #20263970;
$colorMain800: #202639cc;
$colorMain900: #20263990;
$colorMain1000: #202639;
// Secondary color
$colorSecondary100: #a717ff10;
$colorSecondary200: #a717ff20;
$colorSecondary300: #a717ff30;
$colorSecondary400: #a717ff40;
$colorSecondary500: #a717ff50;
$colorSecondary600: #a717ff60;
$colorSecondary700: #a717ff70;
$colorSecondary800: #a717ff80;
$colorSecondary900: #a717ff90;
$colorSecondary1000: #a717ff;
// Tertiary color
$colorTertiary1000: #f7f7f7;
// fonts
$mainFont: 'Roboto', sans-serif;
$secondFont: 'Inter', sans-serif;
// mixin
@mixin transition() {
  transition: all 0.4s ease-in-out;
}
@mixin transform() {
  transform: translate(-50%, -50%);
}
@mixin transform-x() {
  transform: translateX(-50%);
}
@mixin transform-y() {
  transform: translateY(-50%);
}
@mixin transform-none() {
  transform: translate(0, 0);
}
