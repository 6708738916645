// Primary color
$colorPrimary40: rgba(32,38,57,.04) // #202639
$colorPrimary100: rgba(32,38,57,.1)
$colorPrimary200: rgba(32,38,57,.2)
$colorPrimary300: rgba(32,38,57,.3)
$colorPrimary400: rgba(32,38,57,.4)
$colorPrimary500: rgba(32,38,57,.5)
$colorPrimary600: rgba(32,38,57,.6)
$colorPrimary700: rgba(32,38,57,.7)
$colorPrimary800: rgba(32,38,57,.8)
$colorPrimary900: rgba(32,38,57,.9)
$colorPrimary1000: rgba(32,38,57,1)
// Secondary color
$colorSecondary100: rgba(167,23,255,.1) // #a717ff
$colorSecondary200: rgba(167,23,255,.2)
$colorSecondary300: rgba(167,23,255,.3)
$colorSecondary400: rgba(167,23,255,.4)
$colorSecondary500: rgba(167,23,255,.5)
$colorSecondary600: rgba(167,23,255,.6)
$colorSecondary700: rgba(167,23,255,.7)
$colorSecondary800: rgba(167,23,255,.8)
$colorSecondary900: rgba(167,23,255,.9)
$colorSecondary1000: rgba(167,23,255,1)
// Tertiary color
$colorTertiary400: rgba(247,247,247,1) // #f7f7f7
// Green color
$colorGreen400: #00B31D // #00B31D
// Red color
$colorRed400: #EF454A // #EF454A
// Fifth color
$colorFifth40: rgba(30,30,30,.04) // #1E1E1E
$colorFifth60: rgba(30,30,30,.06)
$colorFifth100: rgba(30,30,30,.1)
$colorFifth200: rgba(30,30,30,.2)
$colorFifth300: rgba(30,30,30,.3)
$colorFifth400: rgba(30,30,30,.4)
$colorFifth500: rgba(30,30,30,.5)
$colorFifth600: rgba(30,30,30,.6)
$colorFifth700: rgba(30,30,30,.7)
$colorFifth800: rgba(30,30,30,.8)
$colorFifth900: rgba(30,30,30,.9)
$colorFifth1000: rgba(30,30,30,1)
// White color
$colorWhite100: rgba(255,255,255,.1) // #ffffff
$colorWhite200: rgba(255,255,255,.2)
$colorWhite300: rgba(255,255,255,.3)
$colorWhite400: rgba(255,255,255,.4)
$colorWhite500: rgba(255,255,255,.5)
$colorWhite600: rgba(255,255,255,.6)
$colorWhite700: rgba(255,255,255,.7)
$colorWhite800: rgba(255,255,255,.8)
$colorWhite900: rgba(255,255,255,.9)
$colorWhite1000: rgba(255,255,255,1)
// Sixty color
$colorSixty1000:   rgba(50,50,50,1)// #	#323232
// Fonts
$mainFont: "Roboto", sans-serif 
// mixin
@mixin transition()
  transition: all 0.4s ease-in-out
@mixin transform()
  transform: translate(-50%, -50%)
@mixin transform-x()
  transform: translateX(-50%)
@mixin transform-y()
  transform: translateY(-50%)
@mixin transform-none()
  transform: translate(0 , 0)