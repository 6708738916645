@import '../helper/media';
@import '../helper/variables';
// ACCORDION
.p2p,
.layout {
  .accordion {
    .accordion__item {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }

      &.accordion__item_active {
        .accordion__header {
          &:not(:last-child) {
            margin-bottom: 0.5rem;
          }

          .accordion__title {
            .title__text {
              color: $colorQuartyty500;
            }
          }

          .accordion__action {
            transform: rotate(180deg);
          }
        }

        .accordion__main {
          opacity: 1;
          max-height: 9999px;
          visibility: visible;
        }
      }

      .accordion__header {
        display: flex;
        cursor: pointer;
        @include transition;

        &:not(:last-child) {
          margin-bottom: 0;
        }

        .accordion__title {
          &:not(:last-child) {
            margin-right: 1rem;
          }

          .title__text {
            color: $colorPrimary500;
            font-size: 1rem;
            line-height: 1rem;
            font-weight: 500;
            @include transition;
            @include mediaMinWidthMobile {
              font-size: 1.25rem;
              line-height: 1.5rem;
            }
          }
        }

        .accordion__action {
          width: 1.5rem;
          min-width: 1.5rem;
          height: 1.5rem;
          margin-left: auto;
          transform: rotate(0deg);
          @include transition;

          img,
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }

      .accordion__main {
        font-size: .875rem;
        line-height: 1rem;
        opacity: 0;
        max-height: 0;
        visibility: hidden;
        @include transition;
        @include mediaMinWidthMobile {
          font-size: 1rem;
          line-height: 1.5rem;
        }
      }
    }
  }
}