@import '../../helper/media';
@import '../../helper/variables';
// ELEMENTS
.account__tooltip {
  .tooltip__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include mediaMinWidthTablet {
      align-items: flex-start;
    }
  }
  .tooltip__item {
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 500;
    padding: 0.75rem;
    border-radius: 1.5rem;
    &.tooltip__item_tertiary {
      background-color: #ff004c14;
    }
  }
}
