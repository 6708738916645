
// RESET
html, body, div, span, applet, object, iframe,main,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, button, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video
  margin: 0
  padding: 0
  border: 0
  font: inherit
  outline: none
  vertical-align: baseline
  box-sizing: border-box

main, article, aside, details, figcaption, figure,
footer, header, menu, nav, section
  display: block

html
  font: 400 4vw/1.5rem $mainFont
  @include mediaMinWidthMobile
    font-size: 1.9vw
  @include mediaMinWidthTablet
    font-size: 1.333vw
  @include mediaMinWidthDesktop
    font-size: 16px
body
  color: $colorPrimary1000
  background-color: $colorWhite1000
  -webkit-font-smoothing: antialiased
  -webkit-text-size-adjust: none
html,
body
  min-height: 100%
.layout_Tokens
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6
    font-weight: 500
    a
      color: $colorPrimary1000
      text-decoration: none
      &:hover
        text-decoration: underline
    strong
      background: linear-gradient(to right, #A717FF, #FF1292)
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent
  h1,
  .h1
    font-size: 1.5rem
    line-height: 1.5rem
    @include mediaMinWidthMobile
      font-size: 2.5rem
      line-height: 3rem
    @include mediaMinWidthDesktop
      font-size: 3rem
      line-height: 3.5rem
  h2,
  .h2
    font-size: 1.375rem
    line-height: 1.875rem
    @include mediaMinWidthMobile
      font-size: 2.25rem
      line-height: 2.75rem
    @include mediaMinWidthDesktop
      font-size: 2.625rem
      line-height: 3.125rem
  h3,
  .h3
    font-size: 1.25rem
    line-height: 1.75rem
    @include mediaMinWidthMobile
      font-size: 1.75rem
      line-height: 2.25rem
    @include mediaMinWidthDesktop
      font-size: 2rem
      line-height: 2.125rem
  h4,
  .h4
    font-size: 1.125rem
    line-height: 1.625rem
    @include mediaMinWidthDesktop
      font-size: 1.5rem
      line-height: 1.75rem
  h5,
  .h5
    font-size: 1rem
    line-height: 1.5rem
    @include mediaMinWidthDesktop
      font-size: 1.25rem
      line-height: 1.5rem
  h6,
  .h6
    font-size: 1rem
    line-height: 1.5rem

  img,
  svg
    outline: none
    max-width: 100%
    max-height: 100%
    object-fit: cover
    height: auto
  a
    color: $colorFifth1000
    text-decoration: none
  strong
    font-weight: 500
  ul,
  ol
    list-style: none
