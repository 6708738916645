@use 'src/media' as media;
@use 'src/variables' as variables;

.wrapper {
  width: 100%;
  height: fit-content;
  margin-top: 10px;

  border-radius: 20px;
  background: variables.$blockBorderColor;
  border: 2px solid variables.$blockBorderColor;

  @include media.phone {
    margin-top: 20px;
  }
}

.chartTable {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 62% 1px 1fr;
  grid-template-rows: repeat(2, 25px 1px 236px 1px) 25px;
  grid-template-areas:
    'c l title1'
    'c l l1'
    'c l window1'
    'c l l2'
    'c l title2'
    'c l l3'
    'c l window2'
    'c l l4'
    'c l title3';

  background-color: variables.$mainColorForButtons;
  border-radius: 18px;

  @include media.tablet {
    grid-template-columns: 58% 1px 1fr;
  }
  @include media.phone {
    display: none;
  }
}

.chart {
  grid-area: c;
  padding: 10px;

  background-color: variables.$bgWhite;

  border-radius: 18px 0px 0px 18px;
}
.phoneOnly {
  display: none;

  .chart {
    height: 100%;
    border-radius: 18px 18px 18px 18px;
  }

  @include media.phone {
    display: block;
    height: 555px;
  }
}

.firstRowWrapper {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-family: 'Inter';
  @include variables.fs18;
}

.elementWrapper {
  display: flex;
  align-items: center;
  gap: 10px;

  @include media.phone {
    gap: 4px;
  }

  span {
    @include variables.fs18;

    @include media.phone {
      @include variables.fs16;
    }
  }
}
