@use 'src/media' as media;
@use 'src/variables' as variables;

.modalBlurBg {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;

  overflow-y: auto;
  background: rgba(102, 102, 102, 0.25);
  backdrop-filter: blur(12.5px);
}
.modalBorderWrapper {
  height: fit-content;
  margin: auto 0;
  padding: 40px 0;
  justify-self: center;
}
:global(.modal604px) {
  width: 604px;
  @include media.phoneH {
    width: 520px;
  }
  @include media.phoneV {
    width: calc(100% - 20px);
  }
}
:global(.modal794px) {
  width: 794px;

  @include media.tablet {
    width: 610px;
  }

  @include media.phoneH {
    width: 520px;
  }
  @include media.phoneV {
    width: 340px;
  }
}
:global(.modal1200px) {
  width: 1200px;

  @include media.tablet {
    width: 748px;
  }

  @include media.phoneH {
    width: 540px;
  }
  @include media.phoneV {
    width: 340px;
  }
}
:global(.modal480px) {
  width: 480px;

  @include media.tablet {
    width: 480px;
  }

  @include media.phoneH {
    width: 480px;
  }
  @include media.phoneV {
    width: calc(100vw - 2rem);
  }
}

.modal {
  width: 100%;
  height: fit-content;
  padding: 24px;

  border-radius: 24px;
  background: variables.$bgWhite;
}
.exitButton {
  position: absolute;
  z-index: 4;
  top: 20px;
  right: 20px;
  img {
    width: 20px;
    height: 20px;
  }

  @include media.phoneV {
    top: 15px;
    right: 15px;
    width: 15px;
    height: 15px;

    img {
      width: 15px;
      height: 15px;
    }
  }
}
.title {
  max-width: calc(100% - 25px);
  font-weight: 400;

  @include media.phone {
    @include variables.fs20;
  }
  @include media.phoneV {
    max-width: calc(100% - 20px);
  }
}
