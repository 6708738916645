@use 'src/media' as media;
@use 'src/variables' as variables;

.wrapper {
  width: 965px;
  height: fit-content;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 20px;
  background: variables.$bgWhite;
  border: 1px solid variables.$blockBorderColor;

  @include media.mobileDevices {
    width: 100%;
  }
  @include media.phoneV {
    padding: 10px;
  }
}

.firstRow {
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    margin-bottom: 0;
  }

  @include media.phoneV {
    gap: 19px;
    flex-direction: column;
  }
}

.title {
  margin-bottom: 0;
  @include variables.fs32;
  font-weight: 400;
  color: #000000;
  white-space: nowrap;
  @include media.mobileDevices {
    white-space: normal;
  }
  @include media.phone {
    @include variables.fs20;
  }
  @include media.phoneV {
    align-self: flex-start;
  }
  &:not(:last-child){
    margin-right: 20px;
  }
}

.selectorsWrapper {
  width: 100%;

  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1.1fr;

  @include media.tablet {
    gap: 10px;
  }
  @include media.phone {
    height: auto;
    grid-template-columns: 1fr;
    gap: 10px;
  }
}
.table {
  position: relative;
  width: calc(100% + 19px * 2);
  margin: -19px;
  margin-top: 0;
  margin-bottom: -10px;
  display: grid;
  background: variables.$bgWhite;
  border-radius: 19px;
  overflow: hidden;

  @include media.phoneV {
    width: calc(100% + 9px * 2);
    margin: 0 -9px;
  }
}
.tableCancel {
  position: relative;
  display: grid;

  grid-template-columns: repeat(7, auto);
  grid-template-areas:
    't t t t t t t'
    'l l l l l l l';

  background: variables.$bgWhite;
  border-radius: 19px;
  overflow: hidden;

  div:nth-child(1),
  div:nth-child(7n + 2):not(:nth-child(2)) {
    padding-left: 20px;
  }
  @include media.phoneH {
    grid-template-columns: repeat(6, auto);
    grid-template-areas:
      't t t t t t'
      'l l l l l l';
  }
  @include media.phoneV {
    grid-template-columns: repeat(4, auto);
    grid-template-areas:
      't t t t'
      'l l l l';
    div:nth-child(2),
    div:nth-child(7n + 3):not(.tableTitle) {
      padding-left: 20px;
    }
  }
}
.tableNoCancel {
  grid-template-columns: repeat(6, auto);
  grid-template-areas:
    't t t t t t'
    'l l l l l l';

  div:nth-child(1),
  div:nth-child(6n + 2):not(:nth-child(2)) {
    padding-left: 20px;
  }
  @include media.phoneH {
    grid-template-columns: repeat(5, auto);
    grid-template-areas:
      't t t t t'
      'l l l l l';
  }
  @include media.phoneV {
    grid-template-columns: repeat(3, auto);
    grid-template-areas:
      't t t'
      'l l l';
    div:nth-child(2),
    div:nth-child(6n + 3):not(.tableTitle) {
      padding-left: 20px;
    }
  }
}
.cell {
  width: 100%;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  color: variables.$fontBlack;
  white-space: nowrap;

  font-weight: 400;
  @include variables.fs14;

  @include media.phone {
    @include variables.fs12;
  }
}
.cellColoredBg {
  background: linear-gradient(135deg, #f9f2ff 0%, #faf2fc 100%);
}

.tableTitle {
  margin: 10px 0;

  font-weight: 500;
  @include variables.fs18;
  @include media.mobileDevices {
    @include variables.fs14;
  }
}

.line {
  grid-area: l;
  height: 3px;
  margin: 0 20px;
  background: variables.$clickColorForButtons;
}

.green {
  color: variables.$fontGreen;
}
.phoneGreen {
  @include media.phone {
    color: variables.$fontGreen;
  }
}
.red {
  color: variables.$fontRed;
}
.phoneRed {
  @include media.phone {
    color: variables.$fontRed;
  }
}
.phoneHidden {
  @include media.phone {
    display: none;
  }
}
.phoneVertHidden {
  @include media.phoneV {
    display: none;
  }
}
.phoneVertOnly {
  display: none;
  @include media.phoneV {
    display: block;
  }
}
.actionTitlePhone {
  @include media.phoneV {
    width: 0px;
    visibility: hidden;
  }
}
