$mediaMinWidthDesktopBig: 1400px
$mediaMaxWidthDesktopBig: 1399px
  
$mediaMinWidthDesktop: 1201px
$mediaMaxWidthDesktop: 1200px

$mediaMinWidthTablet: 992px
$mediaMaxWidthTablet: 991px

$mediaMinWidthTabletPort: 768px
$mediaMaxWidthTabletPort: 767px

$mediaMinWidthMobile: 580px
$mediaMaxWidthMobile: 579px

@mixin mediaMinWidthDesktopBig
  @media (min-width: #{$mediaMinWidthDesktopBig})
    @content
@mixin mediaMaxWidthDesktopBig
  @media (max-width: #{$mediaMaxWidthDesktopBig})
    @content

@mixin mediaMinWidthDesktop
  @media (min-width: #{$mediaMinWidthDesktop})
    @content
@mixin mediaMaxWidthDesktop
  @media (max-width: #{$mediaMaxWidthDesktop})
    @content

@mixin mediaMinWidthTablet
  @media screen and (min-width: #{$mediaMinWidthTablet})
    @content

@mixin mediaMaxWidthTablet
  @media screen and (max-width: #{$mediaMaxWidthTablet})
    @content

@mixin mediaMinWidthTabletPort
  @media screen and (min-width: #{$mediaMinWidthTabletPort})
    @content

@mixin mediaMaxWidthTabletPort
  @media screen and (max-width: #{$mediaMaxWidthTabletPort})
    @content

@mixin mediaMinWidthMobile
  @media screen and (min-width: #{$mediaMinWidthMobile})
    @content

@mixin mediaMaxWidthMobile
  @media screen and (max-width: #{$mediaMaxWidthMobile})
    @content

