@use '../../../../media' as media;
@use '../../../../variables' as variables;

.wrapper {
  width: 442px;

  @include media.tablet {
    width: 295px;
  }

  @include media.phone {
    width: 142px;
  }
}

.image {
  width: 29px;
  height: 20px;

  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);

  cursor: pointer;

  @include media.phone {
    width: 23px;
    height: 16px;
  }
}
