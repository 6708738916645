@use 'src/media' as media;
@use 'src/variables' as variables;

.titleFirst {
  grid-area: title1;
  border-radius: 0px 18px 0px 0px;
}
.titleSecond {
  grid-area: title2;
  background-color: variables.$bgWhite;
}
.titleLast {
  grid-area: title3;
  border-radius: 0px 0px 18px 0px;
}
.windowFirst {
  grid-area: window1;
  width: 100%;
  height: 100%;

  background: #fff2f6;

  &::-webkit-scrollbar {
    width: 4px; /* ширина scrollbar */
  }
  &::-webkit-scrollbar-track {
    background: #fff2f6;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ff004d;
    border-radius: 999px;
  }

  .tableItem span:nth-of-type(1) {
    color: #ff004d;
  }
}
.windowSecond {
  grid-area: window2;
  width: 100%;
  height: 100%;

  background: #f5fdfd;

  &::-webkit-scrollbar {
    width: 4px; /* ширина scrollbar */
  }
  &::-webkit-scrollbar-track {
    background: #f5fdfd;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #00d09e;
    border-radius: 999px;
  }

  .tableItem span:nth-of-type(1) {
    color: #00d09e;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: variables.$bgWhite;

  h3 {
    font-weight: 500;
    @include variables.fs20;
  }
}

.tableItem {
  display: grid;
  height: 25px;
  grid-template-columns: 24% 26% 19% 1fr;
  padding: 0 8px;

  h3 {
    align-self: center;
    font-weight: 500;
    opacity: .5;
    @include variables.fs14;

    @include media.tablet {
      @include variables.fs14;
    }
  }

  span {
    align-self: center;
    font-weight: 500;
    @include variables.fs16;
    font-family: "Inter";
    white-space: nowrap;

    @include media.tablet {
      @include variables.fs12;
    }
  }
}

.list {
  height: fit-content;
}
.line {
  height: 1px;
  background-color: #e9e7e7;
}
