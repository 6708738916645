@import '../helper/media';
@import '../helper/variables';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;600;700&display=swap');

html,
body,
div,
span,
applet,
object,
iframe,
main,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  outline: none;
  vertical-align: baseline;
  box-sizing: border-box;
}

main,
article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  display: block;
}

// DEFAULT
html {
  color: $colorPrimary400;
  font-size: 4vw !important;
  line-height: 1.5rem !important;
  font-family: 'Roboto' !important;
  background-color: $colorPrimary100;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  @include mediaMinWidthMobile {
    font-size: 1.9vw !important;
    line-height: 1.25rem !important;
  }
  @include mediaMinWidthTablet {
    font-size: 16px !important;
    line-height: 1.25rem !important;
  }
}

.layout,
.layout__header,
.layout__footer {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: $colorPrimary400;
    letter-spacing: -0.06rem;
    &:not(:last-child) {
      margin: 0 0 1.25rem;
    }

    a {
      color: $colorPrimary400;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    strong {
      color: $colorQuartyty500;
      font-weight: inherit;
    }
  }

  h1,
  .h1 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
    @include mediaMinWidthTablet {
      font-size: 3rem !important;
      line-height: 3.5rem !important;
    }
  }

  h2,
  .h2 {
    font-size: 1.125rem !important;
    line-height: 1.5rem !important;
    font-weight: 500;
    @include mediaMinWidthTablet {
      font-size: 2rem !important;
      line-height: 2.5rem !important;
    }
  }

  h3,
  .h3 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 500;
  }

  h4,
  .h4 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 400;
    @include mediaMinWidthMobile {
      font-weight: 500;
    }
  }

  h5,
  .h5 {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 400;
  }

  h6,
  .h6 {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
  }

  strong {
    font-weight: 500;
  }

  a {
    color: $colorPrimary400;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  ul,
  p,
  ol {
    padding: 0;
    margin-bottom: 0;
  }

  ul,
  ol {
    list-style: none;

    ul,
    ol {
      margin: 0.5rem 0;
    }

    li {
      margin: 0;
    }
  }

  img,
  svg {
    outline: none;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    height: auto;
  }

  // Special Text Elements
  sup,
  .sup {
    color: $colorQuartyty500;
    font-size: 0.75rem;
    position: relative;
    top: -0.25rem;
  }

  blockquote {
    color: $colorPrimary300;
    font-style: italic;
    padding-left: 1.25rem;
    margin-bottom: 2rem;
    border-left: 0.125rem solid $colorQuartyty500;
  }

  q,
  .q {
    quotes: '\00ab''\00bb';
    margin-bottom: 1.5rem;
    display: block;

    q {
      quotes: '\201e''\201c';
    }
  }

  dl {
    color: $colorPrimary300;
    font-size: 0.75rem;
    line-height: 1rem;
    width: calc(100% + 1.5rem);
    margin: 0 -0.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    dt,
    dd {
      padding: 0 0.75rem;
    }
  }

  // BASE
  // container
  .container {
    width: 75rem;
    max-width: calc(100vw - 2rem);
    margin: 0 auto;
    &.container_secondary {
      width: 66.5rem;
    }
  }

  // row
  .row {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 1.25rem);
    margin: 0 -0.625rem;
    &.row_secondary {
      @include mediaMinWidthTablet {
        width: calc(100% + 2.5rem);
        margin: 0 -1.25rem;
        .col {
          padding: 0 1.25rem;
        }
      }
    }
    &.row_t-revers {
      @include mediaMaxWidthTablet {
        flex-direction: column-reverse;
      }
    }
    &.row_m-revers {
      @include mediaMaxWidthMobile {
        flex-direction: column-reverse;
      }
    }
  }

  .col {
    display: flex;
    flex-direction: column;
    padding: 0 0.625rem;
    box-sizing: border-box;

    &.col_1 {
      width: 8.3333%;
    }

    &.col_2 {
      width: 16.666%;
    }

    &.col_3 {
      width: 25%;
    }

    &.col_4 {
      width: 33.3%;
    }

    &.col_5 {
      width: 41.666667%;
    }

    &.col_6 {
      width: 50%;
    }

    &.col_7 {
      width: 58.333333%;
    }

    &.col_8 {
      width: 66.666667%;
    }

    &.col_9 {
      width: 75%;
    }

    &.col_10 {
      width: 83.333333%;
    }

    &.col_11 {
      width: 91.666667%;
    }

    &.col_12 {
      width: 100%;
    }

    @include mediaMaxWidthDesktop {
      &.col_desktop-2 {
        width: 16.66%;
      }
      &.col_desktop-3 {
        width: 25%;
      }
      &.col_desktop-4 {
        width: 33.3%;
      }
      &.col_desktop-5 {
        width: 41.666667%;
      }
      &.col_desktop-6 {
        width: 50%;
      }
      &.col_desktop-7 {
        width: 58.333333%;
      }
      &.col_desktop-8 {
        width: 66.666667%;
      }
      &.col_desktop-9 {
        width: 75%;
      }
      &.col_desktop-10 {
        width: 83.333333%;
      }
      &.col_desktop-11 {
        width: 91.666667%;
      }
      &.col_desktop-12 {
        width: 100%;
      }
    }
    @include mediaMaxWidthTablet {
      &.col_tab-2 {
        width: 16.66%;
      }
      &.col_tab-3 {
        width: 25%;
      }
      &.col_tab-4 {
        width: 33.3%;
      }
      &.col_tab-5 {
        width: 41.666667%;
      }
      &.col_tab-6 {
        width: 50%;
      }
      &.col_tab-7 {
        width: 58.333333%;
      }
      &.col_tab-8 {
        width: 66.666667%;
      }
      &.col_tab-9 {
        width: 75%;
      }
      &.col_tab-10 {
        width: 83.333333%;
      }
      &.col_tab-11 {
        width: 91.666667%;
      }
      &.col_tab-12 {
        width: 100%;
      }
    }
    @include mediaMaxWidthMobile {
      &.col_mob-2 {
        width: 16.66%;
      }
      &.col_mob-3 {
        width: 25%;
      }
      &.col_mob-4 {
        width: 33.3%;
      }
      &.col_mob-5 {
        width: 41.666667%;
      }
      &.col_mob-6 {
        width: 50%;
      }
      &.col_mob-7 {
        width: 58.333333%;
      }
      &.col_mob-8 {
        width: 66.666667%;
      }
      &.col_mob-9 {
        width: 75%;
      }
      &.col_mob-10 {
        width: 83.333333%;
      }
      &.col_mob-11 {
        width: 91.666667%;
      }
      &.col_mob-12 {
        width: 100%;
      }
    }
  }

  .h-100 {
    height: 100% !important;
  }
  .w-100 {
    width: 100% !important;
  }
  .pb-0 {
    padding-bottom: 0 !important;
  }

  .align-center {
    align-items: center;
  }
  .align-start {
    align-items: flex-start;
  }
  .align-end {
    align-items: flex-end;
  }

  .justify-start {
    justify-content: flex-start !important;
  }
  .justify-center {
    justify-content: center !important;
  }
  .justify-between {
    justify-content: space-between !important;
  }

  .justify-end {
    justify-content: flex-end !important;
  }

  .mt-auto {
    margin-top: auto !important;
  }

  .text-center {
    text-align: center !important;
  }
  .text-end {
    text-align: right !important;
  }

  .colorSecondary300 {
    color: $colorSecondary300 !important;
  }

  .colorSecondary400 {
    color: $colorSecondary400 !important;
  }

  .colorSecondary500 {
    color: $colorSecondary500 !important;
  }

  .colorTertiary300 {
    color: $colorTertiary300 !important;
  }

  .colorTertiary400 {
    color: $colorTertiary400 !important;
  }

  .colorTertiary500 {
    color: $colorTertiary500 !important;
  }

  .colorPrimary200 {
    color: $colorPrimary200 !important;
  }

  .colorPrimary300 {
    color: $colorPrimary300 !important;
  }

  .colorPrimary400 {
    color: $colorPrimary400 !important;
  }

  .colorQuartyty200 {
    color: $colorQuartyty200 !important;
  }

  .colorQuartyty300 {
    color: $colorQuartyty300 !important;
  }

  .colorQuartyty400 {
    color: $colorQuartyty400 !important;
  }

  .colorQuartyty500 {
    color: $colorQuartyty500 !important;
  }

  // Стилизация скролла
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  ::-webkit-scrollbar-track {
    background: $colorWhite;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $colorSecondary400;

    &:hover {
      background-color: $colorSecondary500;
    }
  }

  .p-0 {
    padding: 0 !important;
  }
}
