// Button
.layout_Tokens
  .btn
    color: $colorWhite1000
    background-color: $colorSecondary1000
    font-size: .875rem
    line-height: 1.5rem
    font-weight: 700
    padding: 1rem
    border: 1px solid $colorSecondary1000
    border-radius: .375rem
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    outline: none
    text-align: center
    appearance: none
    @include transition
    @include mediaMinWidthMobile
      font-size: 1rem
      line-height: 1.25rem
      padding: 1.125rem
      border-radius: .75rem
    @include mediaMinWidthTablet
      padding: 1.5rem
      height: 5.125rem
    @include mediaMinWidthDesktop
      font-size: 1.25rem
      line-height: 1.5rem
    &:disabled
      background-color: $colorPrimary500
      border-color: transparent
      pointer-events: none
      cursor: unset
    &:hover,
    &:active

      background-color: $colorSecondary800 
    // button radius
    &.btn_radius-secondary
      @include mediaMinWidthTablet
        border-radius: .75rem
    // button large
    &.btn_large
      height: 4.5rem
      @include mediaMinWidthMobile
        height: 5.75rem
        border-radius: .75rem
      @include mediaMinWidthDesktop
        height: 6.75rem
        border-radius: 1.25rem
    // button tiny
    &.btn_tiny
      font-size: .75rem
      line-height: 1.125rem
      padding: .25rem 1rem
      height: 2rem
      @include mediaMinWidthMobile
        font-size: .875rem
      @include mediaMinWidthTablet
        font-size: 1rem
        line-height: 1.125rem
    // button transparent
    &.btn_transparent
      color: $colorSecondary1000
      background-color: transparent
      &:hover
        color: $colorWhite1000
        background-color: $colorSecondary1000
        border-color: $colorSecondary1000
    // button secondary
    &.btn_secondary
      background-color: $colorGreen400
      border-color: $colorGreen400 
      &:hover,
      &:active
        background-color: rgba($colorGreen400, 0.7)
        // background-color: transparent
        // color: $colorGreen400
    // button tertiary
    &.btn_tertiary
      background-color: $colorFifth1000
      border-color: $colorFifth1000
      &:hover,
      &:active
        background-color: $colorSixty1000

    .btn__text
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis