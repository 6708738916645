@use 'src/media' as media;
@use 'src/variables' as variables;

.block {
  position: relative;
  padding-top: 15px;
}

.body {
  display: flex;
  justify-content: space-between;
}
.infoBody {
  display: grid;
  justify-content: flex-start;
  width: 651px;

  @include media.phone {
    width: 100%;
  }
}

.title {
  font-weight: 500;
  @include variables.fs32;
  margin-bottom: 20px;

  @include media.phone {
    @include variables.fs20;
  }
}

.selectTitle {
  margin-top: 10px;
  margin-bottom: 10px;
  @include variables.fs18;

  @include media.phone {
    @include variables.fs16;
  }
}

.description {
  opacity: 0.7;
  @include variables.fs14;

  @include media.phone {
    @include variables.fs12;
  }
}

.buttonBody {
  display: flex;
  justify-content: flex-start;
  gap: 5px;

  margin-bottom: 20px;
}

.button {
  width: 134px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 400;
  @include variables.fs14;

  background-color: white;
  border-radius: 10px;

  @include media.phone {
    width: 100px;

    @include variables.fs16;
  }

  @include media.phoneV {
    @include variables.fs14;
  }
}

.buttonGreen {
  border: 1px solid #666666;
}

.buttonYellow {
  border: 1px solid #f9d519;
}

.buttonRed {
  border: 1px solid var(--danger-color);
}

.buttonGreenActive {
  border: 1px solid #666666;
  background: radial-gradient(107.25% 302.83% at 3.5% 11.32%, #ccfff3 0%, #d0f4f5 93.26%);
}

.buttonYellowActive {
  border: 1px solid #f9d519;
  background: #fff7ca;
}

.buttonRedActive {
  border: 1px solid #ff004d;
  background: #ffd2e0;
}

.dangerBody {
  width: 412px;
  @include media.phone {
    width: 100%;
  }
}

.danger {
  color: var(--danger-color);
  font-weight: 500;
  @include variables.fs14;

  @include media.phone {
    @include variables.fs12;
  }
}

.form {
  width: 651px;
  margin-bottom: 20px;

  @include media.phone {
    width: 100%;
  }
}

.formTitle {
  margin-top: 20px;
  margin-bottom: 0;
  align-self: flex-end;

  font-weight: 500;
  @include variables.fs18;

  @include media.phone {
    margin-top: 10px;
    @include variables.fs14;
  }

  &.commission {
    margin-top: 0;
  }
}

.input {
  margin-top: 10px;

  &::placeholder {
    background: linear-gradient(135deg, #8c00fd 0%, #a800c0 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    opacity: 0.2;
  }
}

.summWrapper {
  display: grid;
  grid-template-columns: auto repeat(2, 24%);
  grid-template-rows: min-content min-content;
  gap: 10px 20px;
  grid-template-areas:
    'sum sum com'
    'suminp button cominp';

  @include media.phone {
    gap: 10px;
  }
  @include media.phoneV {
    gap: 10px;
    grid-template-columns: auto 35%;
    grid-template-rows: repeat(4, min-content);
    grid-template-areas:
      'sum sum'
      'suminp button '
      'com  a'
      'cominp a';
  }
}

.summTitle {
  grid-area: sum;
}

.commission {
  grid-area: com;
  font-weight: 400;
  cursor: pointer;
}

.summInput {
  grid-area: suminp;
  margin-top: 0;
}

.summButton {
  grid-area: button;
}

.commissionDisabledInput {
  grid-area: cominp;
  padding-left: 20px;

  border-radius: 10px;
  border: 0;

  background: #f0f0f0;
  color: #000000;

  &::placeholder {
    color: #000000;
  }
}

.sendButton {
  width: 155px;
  margin-top: 20px;
}

.inputButtonWrapper {
  display: grid;
  grid-template-columns: auto 24%;
  align-items: flex-end;
  gap: 20px;

  @include media.phone {
    gap: 10px;
    grid-template-columns: auto 35%;
  }
}
