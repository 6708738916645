@use 'src/media' as media;
@use 'src/variables' as variables;

.block {
  position: relative;
  padding: 20px 0;
  border-bottom: 1px solid;
  border-image: var(--border-color) 1;
}

.title {
  width: 100%;
  font-weight: 500;
  @include variables.fs32;

  @include media.phone {
    @include variables.fs20;
  }
}

.methodWrapper {
  display: grid;
  gap: 10px 20px;
  margin-top: 20px;
  grid-template-columns: 30% auto;
  grid-template-rows: repeat(2, min-content);
  grid-template-areas:
    'title1 title2'
    'select instructions';

  p {
    margin-bottom: 0;
  }

  @include media.phone {
    grid-template-columns: 40% auto;
  }
  @include media.phoneV {
    grid-template-columns: 100%;
    grid-template-rows: repeat(4, min-content);
    grid-template-areas:
      'title1'
      'select'
      'title2'
      'instructions';
  }
}

.title1 {
  grid-area: title1;
}

.title2 {
  grid-area: title2;
}

.select {
  grid-area: select;
}

.instructions {
  grid-area: instructions;
}

.methodTitle {
  font-weight: 500;
  @include variables.fs18;

  @include media.phone {
    @include variables.fs14;
  }
}

.selectWrapper {
  position: relative;
  width: 100%;
  height: 40px;
  padding: 2px;
  background: radial-gradient(150.69% 118.33% at 123.17% 100%, #c6fff1 0%, #e7c8ff 100%);
  border-radius: 10px;

  @include media.phone {
    height: 35px;
  }
  @include media.phoneV {
    width: 200px;
    height: 30px;
  }
}

.methodSelect {
  position: absolute;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  padding: 0;

  @include variables.fs18;

  background: #f8f9fd;
  border-radius: 8px;
  border: 0;

  @include media.phone {
    @include variables.fs14;
  }
}

.selectTitleWrapper {
  position: absolute;
  display: flex;
  align-self: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  top: 0;
  padding: 0 18px;

  p {
    align-self: center;
    @include variables.fs18;

    @include media.phone {
      @include variables.fs14;
    }
  }

  img {
    width: 14px;
    height: 8px;
    align-self: center;
  }
}

.imgRotate {
  transform: rotate(180deg);
}

.selectListWrapper {
  position: absolute;
  top: 26px;
  left: 10px;
  width: calc(100% + 4px);
  border-radius: 10px;
  padding: 1px;
}

.selectList {
  width: calc(100%);
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  li {
    text-align: left;

    @include variables.fs18;

    @include media.phone {
      @include variables.fs14;
    }
  }
}

.selectedItem {
  background: linear-gradient(135deg, #8c00fd 0%, #a800c0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.instructionsWrapper {
  padding: 10px;
  border-radius: 10px;

  background: #f0f0f0;
}

.instructionsItem {
  margin-bottom: 0;
  @include variables.fs18;
  line-height: 150%;

  @include media.phone {
    @include variables.fs14;
  }
}

.withdrawalStambulWrapper {
  display: grid;
  width: 651px;
  margin-top: 20px;
  gap: 10px;
  grid-template-columns: auto 175px 133px;
  grid-template-rows: repeat(3, repeat(2, min-content) 0px);
  grid-template-areas:
    'title1 title1 title2'
    'input1 button1 input2'
    'a a a'
    'title3 title3 title3'
    'input3 input3 b'
    'c c c'
    'title4 title4 title4'
    'input4 button2 d'
    'button3 button3 button3';

  @include media.phone {
    width: 100%;
    grid-template-columns: auto 23% 15%;
  }

  @include media.phoneV {
    grid-template-columns: auto 147px;
    grid-template-rows: repeat(3, repeat(2, min-content) 0px);
    grid-template-areas:
      'title1 title1'
      'input1 button1'
      'a a'
      'title3 title2'
      'input3 input2'
      'c c'
      'title4 title4'
      'input4 button2'
      'button3 button3';
  }
}

.firstTitle {
  grid-area: title1;
  width: fit-content;
}

.secondTitle {
  grid-area: title2;
  width: fit-content;
}

.thirdTitle {
  grid-area: title3;
  width: fit-content;
}

.fourthTitle {
  grid-area: title4;
  width: fit-content;
}

.firstInput {
  grid-area: input1;
  width: 100%;
}

.secondInput {
  grid-area: input2;
  width: 100%;
}

.thirdInput {
  grid-area: input3;
  width: 100%;
}

.fourthInput {
  grid-area: input4;
  width: 100%;
}

.firstButton {
  grid-area: button1;
  width: 100%;
  font-weight: 500;
}

.secondButton {
  grid-area: button2;
  width: 100%;
  font-weight: 500;
}

.thirdButton {
  grid-area: button3;
  width: 181px;
  @include media.phone {
    width: 153px;
  }
}

.paragraph {
  grid-area: paragraph;
}

.buttonsWrapper {
  grid-area: buttons;
  display: flex;
  gap: 20px;
}

.input {
  @include media.phone {
    @include variables.fs14;
  }
}

.disabledInput {
  width: 100%;
  height: 40px;
  padding-left: 20px;

  @include variables.fs18;

  border-radius: 10px;
  background: #f0f0f0;
  border: 0;

  @include media.phone {
    height: 35px;
    @include variables.fs14;
  }
  @include media.phoneV {
    height: 30px;
  }
}
