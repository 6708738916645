@use 'src/media' as media;
@use 'src/variables' as variables;

.itemImgBlock {
  display: flex;
  align-items: center;
}

.arrows {
  height: 12px;
  width: 7px;
  margin-right: 9px;
  margin-left: 4px;

  @include media.mobileDevices {
    margin-right: 5px;
  }
}

.img {
  width: 30px;
  height: 30px;
  margin-right: 10px;

  @include media.mobileDevices {
    margin-right: 5px;
  }
  @include media.phone {
    width: 25px;
    height: 25px;
  }
}

.itemTitle {
  font-weight: 500;
  @include variables.fs18;

  @include media.phone {
    @include variables.fs14;
  }
}

.itemDescription {
  opacity: 0.7;
  @include variables.fs14;

  @include media.phone {
    @include variables.fs12;
  }
}

.active {
  background: var(--active-color);
}

.data {
  display: flex;
  font-weight: 600;
  @include variables.fs14;

  @include media.phone {
    @include variables.fs12;
  }
}

.phoneHidden {
  @include media.phone {
    display: none;
  }
}
.depositWithdrawBlock {
  //width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;

  min-width: 180px;
  @include media.phone {
    min-width: 160px !important;
  }
}
