.layout_Tokens
  .layout__section
    padding: 2.25rem 0
    @include mediaMinWidthMobile
      padding: 3.5rem 0
    @include mediaMinWidthTablet
      padding: 3.75rem 0
    &.layout__section_secondary
      background-color: $colorTertiary400
    .container
      display: flex
      flex-direction: column
      gap: 2.5rem
      @include mediaMinWidthMobile
        gap: 3.5rem
      @include mediaMinWidthTablet
        gap: 3.75rem
    .section__header
      gap: 1.25rem

      display: flex
      flex-direction: column
      @include mediaMinWidthMobile
        gap: 2rem
      &:not(.section__header_secondary)
        @include mediaMinWidthTablet
        text-align: center
        align-items: center
      &.section__header_with-form
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
    .section__title
      max-width: 44rem
    .section__form
      width: 12.5rem
      @include mediaMinWidthMobile
        width: 27.5rem
      @include mediaMinWidthDesktop
        width: 32.5rem
      .table__list
        gap: 0
      .form__field
        border: 0
        box-shadow: 0px 4px 32px 0px #00000014
      .table__link
        padding: .5rem 1.25rem
        width: 100%
        justify-content: flex-start!important
        background-color: $colorWhite1000
        @include transition
        &:hover
          background-color: $colorFifth40
    .section__tabs
      display: flex
      justify-content: center
      &.section__tabs_secondary
        .tabs__list

          background-color: $colorWhite1000
          .tabs__item
            background-color: $colorWhite1000
            &.tabs__item_active,
            &:hover
              color: $colorWhite1000
              background-color: $colorSecondary1000
      .tabs__list
        padding: .375rem
        gap: .375rem
        border-radius: 2rem
        background-color: $colorTertiary400
        display: flex
        align-items: center
        overflow: hidden
        overflow-x: auto
      .tabs__item
        padding: .75rem 1.25rem
        border-radius: 2rem
        color: $colorPrimary800
        background-color: $colorWhite1000
        font-size: 1rem
        line-height: 1.5rem
        font-weight: 500
        flex-shrink: 0
        cursor: pointer
        @include transition
        @include mediaMinWidthTablet
          font-size: 1.25rem
          line-height: 1.5rem
        &.tabs__item_active,
        &:hover
          color: $colorWhite1000
          background-color: $colorSecondary1000



