@mixin desktop {
  @media (min-width: 1240px) {
    @content;
  }
}

@mixin mobileDevices {
  @media (max-width: 1240px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 767.999px) and (max-width: 1239.999px) {
    // @media (min-width: 880px) and (max-width: 1239.999px) {
    @content;
  }
}

@mixin phoneH {
  @media (min-width: 575.999px) and (max-width: 767.998px) {
    // @media (min-width: 610px) and (max-width: 767.998px) {
    // @media (min-width: 610px) and (max-width: 879.999px) {
    @content;
  }
}

@mixin phoneV {
  @media (max-width: 575.998px) {
    // @media (max-width: 609.999px) {
    @content;
  }
}

@mixin phone {
  @media (max-width: 767.998px) {
    // @media (max-width: 879.999px) {
    @content;
  }
}

$mediaMinWidthDesktop: 1201px;
$mediaMaxWidthDesktop: 1200px;

$mediaMinWidthTablet: 992px;
$mediaMaxWidthTablet: 991px;

$mediaMinWidthMobile: 768px;
$mediaMaxWidthMobile: 767px;

/*mediaWidthDesktop -1201*/
@mixin mediaMinWidthDesktop {
  @media (min-width: #{$mediaMinWidthDesktop}) {
    @content;
  }
}

@mixin mediaMaxWidthDesktop {
  @media (max-width: #{$mediaMaxWidthDesktop}) {
    @content;
  }
}

/*WidthTabletLand-992*/
@mixin mediaMinWidthTablet {
  @media screen and (min-width: #{$mediaMinWidthTablet}) {
    @content;
  }
}

@mixin mediaMaxWidthTablet {
  @media screen and (max-width: #{$mediaMaxWidthTablet}) {
    @content;
  }
}

/*WidthTabletLand-580*/
@mixin mediaMinWidthMobile {
  @media screen and (min-width: #{$mediaMinWidthMobile}) {
    @content;
  }
}

@mixin mediaMaxWidthMobile {
  @media screen and (max-width: #{$mediaMaxWidthMobile}) {
    @content;
  }
}
