@import '../../helper/media';
@import '../../helper/variables';

// info
.p2p__info {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;
  @include mediaMinWidthMobile {
    grid-template-columns: 3rem auto;
  }
  @include mediaMinWidthTablet {
    grid-template-columns: 3rem auto 18rem;
  }
  .info__media {
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
  }
  .info__footer {
    @include mediaMinWidthMobile {
      padding: 0 3rem;
      display: grid;
      grid-column: span 2;
    }
    @include mediaMinWidthTablet {
      padding: 0;
      grid-column: unset;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn {
      width: auto;
      @include mediaMinWidthTablet {
        width: 100%;
      }
    }
  }
  .info__main {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.75rem;
  }
  .info__title {
    .title__text {
      color: $colorPrimary400;
      font-size: 1.25rem;
      line-height: 1.5rem;
      font-weight: 500;
    }
  }
  .info__data {
    margin: 0 !important;
    ul {
      color: $colorPrimary400;
      font-size: 0.875rem;
      line-height: 1rem;
      opacity: 0.5;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0.75rem;
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
  .info__help {
    color: $colorQuartyty500;
    font-size: 0.875rem;
    line-height: 1rem;
    display: grid;
    grid-template-columns: 1rem auto;
    grid-gap: 1rem;
  }
  .info__subtitle {
    color: $colorPrimary400;
    font-size: 0.75rem;
    line-height: 1rem;
    opacity: 0.5;
    @include mediaMinWidthMobile {
      font-size: 0.875rem;
      line-height: 1rem;
    }
  }
}
.appeal__help {
  width: 100%;
  max-width: 35rem;
  margin: 0 auto 1.5rem;
  display: grid;
  justify-content: center;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  @include mediaMinWidthMobile {
    grid-template-columns: 1fr 1fr;
  }
}

// SECTION
.p2p__head {
  display: flex;
  flex-wrap: wrap;
  @include mediaMinWidthMobile {
    align-items: center;
    flex-wrap: nowrap;
  }
  .head__media {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    @include mediaMinWidthMobile {
      width: 3rem;
      height: 3rem;
    }
    &:not(:last-child) {
      margin-right: 0.5rem;
      @include mediaMinWidthMobile {
        margin-right: 1.5rem;
      }
    }
  }
  .head__main {
    margin-bottom: 0.5rem;
    width: calc(100% - 2rem);
    display: flex;
    flex-direction: column;
    @include mediaMinWidthMobile {
      margin-bottom: 0;
      margin-right: 1.5rem;
    }
  }
  .head__title {
    color: $colorPrimary400;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 500;
    @include mediaMinWidthMobile {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }
  .head__subtitle {
    color: $colorPrimary400;
    font-size: 0.75rem;
    line-height: 1rem;
    opacity: 0.5;
    @include mediaMinWidthMobile {
      font-size: 0.875rem;
      line-height: 1rem;
    }
  }
  .head__footer {
    width: 100%;
    display: flex;
    justify-content: center;
    @include mediaMinWidthMobile {
      width: auto;
      justify-content: flex-start;
      margin-left: auto;
    }
  }
  .head__clock {
    display: flex;
    align-items: center;
    .clock__item {
      font-size: 1.25rem;
      line-height: 1.5rem;
      font-weight: 600;
      color: $colorWhite;
      background-color: $colorQuartyty500;
      border-radius: 1.25rem;
      height: 2rem;
      width: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    .clock__item_small {
      font-size: 1rem;
      line-height: 1rem;
      font-weight: 400;
      color: $colorWhite;
      background-color: $colorQuartyty500;
      border-radius: 1.25rem;
      height: 1.5rem;
      width: 2rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    .clock__delivery {
      color: $colorQuartyty500;
      font-size: 1.25rem;
      line-height: 1.5rem;
      margin: 0 0.25rem;
    }
  }
}

// BANNER
.p2p__banner {
  color: $colorWhite;
  background-color: $colorPrimary400;
  padding: 1.5rem 0;
  min-height: 100%;
  text-align: center;
  position: relative;
  @include mediaMinWidthTablet {
    padding: 3rem 0;
  }
  .banner__layout {
    position: relative;
    z-index: 3;
  }
  .banner__header {
    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }
  }
  .banner__media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .media__item {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      &.media__item_before {
        justify-content: flex-start;
      }
      &.media__item_after {
        justify-content: flex-end;
      }
      img,
      svg {
        object-fit: cover;
        width: auto;
        height: 100%;
      }
    }
  }
}
