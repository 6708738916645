@import 'src/media';
@import 'src/variables';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

.layout {
  font-size: .9375rem;
  line-height: 1.125rem;
  color: #202639;
  background-color: #F6F6F6;
  @include mediaMinWidthDesktop {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  &.layout_second {
    background-color: #F8F9FD;
  }
}

.layout__main {
  font-family: $mainFont;
  display: flex;
  flex-direction: column;


  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: $colorPrimary400;
    font-weight: 400;
    line-height: 1.25;

    &:not(:last-child) {
      margin: 0 0 1px;
      @include mediaMinWidthDesktop {
        margin: 0 0 24px;
      }
    }

    a {
      color: $colorPrimary400;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    strong {
      color: $colorQuartyty400;
    }
  }

  h1,
  .h1 {
    font-size: 1.5rem;
    font-weight: 600;
    @include mediaMinWidthDesktop {
      font-size: 3.25rem;
    }

    strong {
      font-weight: 600;
    }
  }

  h2,
  .h2 {
    font-size: 1.125rem;
    @include mediaMinWidthDesktop {
      font-size: 2rem;
    }
  }

  h3,
  .h3 {
    font-size: 1.25rem;
    @include mediaMinWidthDesktop {
      font-size: 1.5rem;
    }
  }

  h4,
  .h4 {
    font-size: 1.25rem;
    @include mediaMinWidthDesktop {
      font-size: 1.25rem;
    }
  }

  h5,
  .h5 {
    font-size: 1rem;
    @include mediaMinWidthDesktop {
      font-size: 1.25rem;
    }
  }

  h6,
  .h6 {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  strong {
    font-weight: 500;
  }

  a {
    color: $colorPrimary400;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  ul,
  p,
  ol {
    padding: 0;
    margin: 0;


    &:empty {
      display: none;
    }
  }

  ul,
  ol {
    list-style: none;

    ul,
    ol {
      margin: 0.5em 0;
    }

    li {
      margin: 0 0 0.5rem;
    }
  }

  img,
  svg {
    outline: none;
    max-width: 100%;
    object-fit: cover;
    height: auto;
  }

  // Special Text Elements
  sup,
  .sup {
    color: $colorSecondary400;
    font-size: 0.75rem;
    position: relative;
    top: -0.25rem;
  }

  blockquote {
    font-style: italic;
    padding-left: 1.25rem;
    margin-bottom: 2rem;
    border-left: 1.125em solid $colorPrimary300;
  }

  q,
  .q {
    quotes: "\00ab" "\00bb";
    margin-bottom: 1.5rem;
    display: block;

    q {
      quotes: "\201e" "\201c";
    }
  }

  dl {
    display: flex;
    margin-bottom: 1rem;

    &:not(:last-child) {
      margin-bottom: .5rem;
    }

    dt {
      color: $colorPrimary300;
      min-width: 5rem;

      &:not(:last-child) {
        margin-right: .5rem;
      }
    }
  }


  // container
  .container {
    width: 75rem;
    max-width: calc(100vw - 2em);
    margin: 0 auto;

    &.container_second {
      width: 50rem;
    }
  }

  // row
  .row {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 1em);
    margin: 0 -.5rem;
    @include mediaMinWidthDesktop {
      width: calc(100% + 1.5em);
      margin: 0 -.75rem;
    }

    &.row_second {
      @include mediaMinWidthDesktop {
        width: calc(100% + 8.5rem);
        margin: 0 -4.25rem;
        > .col {
          padding: 0 4.25rem;
        }
      }
    }
  }

  .col {
    display: flex;
    flex-direction: column;
    padding: 0 .5rem;
    box-sizing: border-box;
    @include mediaMinWidthDesktop {
      padding: 0 .75rem;
    }

    &.col_1 {
      width: 8.3333%;
    }

    &.col_2 {
      width: 16.666%;
    }

    &.col_3 {
      width: 25%;
    }

    &.col_4 {
      width: 33.3%;
    }

    &.col_5 {
      width: 41.666667%;
    }

    &.col_6 {
      width: 50%;
    }

    &.col_7 {
      width: 58.333333%;
    }

    &.col_8 {
      width: 66.666667%;
    }

    &.col_9 {
      width: 75%;
    }

    &.col_10 {
      width: 83.333333%;
    }

    &.col_11 {
      width: 91.666667%;
    }

    &.col_12 {
      width: 100%;
    }


    @include mediaMaxWidthDesktop {
      &.col_desktop_2 {
        width: 16.66%;
      }
      &.col_desktop_3 {
        width: 25%;
      }
      &.col_desktop_4 {
        width: 33.3%;
      }
      &.col_desktop_5 {
        width: 41.666667%;
      }
      &.col_desktop_6 {
        width: 50%;
      }
      &.col_desktop_7 {
        width: 58.333333%;
      }
      &.col_desktop_8 {
        width: 66.666667%;
      }
      &.col_desktop_9 {
        width: 75%;
      }
      &.col_desktop_10 {
        width: 83.333333%;
      }
      &.col_desktop_11 {
        width: 91.666667%;
      }
      &.col_desktop_12 {
        width: 100%;
      }
    }
    @include mediaMaxWidthTablet {
      &.col_tab_3 {
        width: 25%;
      }
      &.col_tab_4 {
        width: 33.3%;
      }
      &.col_tab_5 {
        width: 41.666667%;
      }
      &.col_tab_6 {
        width: 50%;
      }
      &.col_tab_7 {
        width: 58.333333%;
      }
      &.col_tab_8 {
        width: 66.666667%;
      }
      &.col_tab_9 {
        width: 75%;
      }
      &.col_tab_10 {
        width: 83.333333%;
      }
      &.col_tab_11 {
        width: 91.666667%;
      }
      &.col_tab_12 {
        width: 100%;
      }
    }
    @include mediaMaxWidthMobile {
      &.col_mob_3 {
        width: 25%;
      }
      &.col_mob_4 {
        width: 33.3%;
      }
      &.col_mob_5 {
        width: 41.666667%;
      }
      &.col_mob_6 {
        width: 50%;
      }
      &.col_mob_7 {
        width: 58.333333%;
      }
      &.col_mob_8 {
        width: 66.666667%;
      }
      &.col_mob_9 {
        width: 75%;
      }
      &.col_mob_10 {
        width: 83.333333%;
      }
      &.col_mob_11 {
        width: 91.666667%;
      }
      &.col_mob_12 {
        width: 100%;
      }
    }
  }


  /* SECTION */
  .layout__section {
    padding: 2em 0;
    background-color: $colorPrimary100;
    @include mediaMinWidthDesktop {
      padding: 4em 0;
    }

    .section__header,
    .section__main,
    .section__footer {
      &:not(:last-child) {
        margin-bottom: 2rem;
        @include mediaMinWidthDesktop {
          margin-bottom: 3.5rem;
        }
      }
    }

    .section__header {

    }

    &.section_secondary {
      background-color: $colorWhite;
    }
  }

  /* WYSIWYG */
  .wysiwyg {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    img,
    svg {
      display: block;
      margin-bottom: 1.5rem;
    }

    ul,
    p,
    ol {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }

    ul {
      list-style: none;

      ul {
        margin-left: 0;
      }

      li {
        position: relative;
        padding-left: 2rem;

        &:before {
          content: "";
          left: .5rem;
          top: .4rem;
          width: .5rem;
          height: .5rem;
          border-radius: 50%;
          background-color: $colorSecondary400;
          position: absolute;
        }
      }
    }

    ol {
      list-style: decimal;
      padding-left: 1rem;

      li {
        margin: 0 0 0.5rem;
      }
    }

    a {
      color: $colorSecondary400;
      position: relative;
      white-space: nowrap;

      &:hover {
        &:before {
          opacity: 1;
        }
      }

      &:before {
        content: "";
        position: absolute;
        top: calc(100% + .125em);
        left: 0;
        height: 1px;
        width: 100%;
        background-color: $colorSecondary400;
        opacity: .2;
        @include transition;
      }
    }
  }


  /* BTN */
  .btn {
    color: $colorWhite;
    background-color: $colorPrimary400;
    border: .125rem solid $colorPrimary400;;
    font-size: 1.125rem;
    line-height: 1.125rem;
    font-weight: 600;
    padding: 1.25rem;
    border-radius: 2.5rem;
    margin: 0;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    text-align: center;
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    @include transition;
    @include mediaMinWidthDesktop {
      font-size: 1.25rem;
      line-height: 1.25rem;
      padding: 1.5rem;
    }

    &:hover {
      color: $colorWhite;
      background-color: $colorQuartyty400;
      border-color: $colorQuartyty400;
      text-decoration: none;
    }

    &.btn_second {
      color: $colorPrimary400;
      border-color: $colorPrimary400;
      background-color: $colorWhite;

      &:hover {
        color: $colorQuartyty400;
        border-color: $colorQuartyty400;
      }
    }

    &.btn_tertiaty {
      color: $colorQuartyty400;
      border-color: $colorQuartyty400;
      background-color: transparent;

      &:hover {
        color: $colorWhite;
        background-color: $colorQuartyty400;
        border-color: $colorQuartyty400;
      }
    }

    &.btn_tiny {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.25rem;
      padding: 0.5rem 1.25rem;
    }

    &:disabled {
      color: $colorWhite;
      background-color: $colorPrimary300;
    }

    .btn__text,
    .btn__ico {
      &:not(:last-child) {
        margin-right: .5rem;
      }
    }
  }

  .buttons {
    &:not(:last-child) {
      margin-bottom: 2.5rem;
    }

    .buttons__list {
      margin: 0 -.5rem -.5rem;
      width: calc(100% + 1rem);
      display: flex;
    }

    .buttons__item {
      margin: 0 .5rem .5rem;
    }
  }


  .textCenter {
    text-align: center !important;
  }

  .justifyCenter {
    justify-content: center !important;
  }

  .colorTertiary500 {
    color: $colorTertiary500 !important;
  }

  .colorTertiary {
    color: $colorQuartyty400 !important;
  }

  /* BOX */
  .boxes {
    .boxes__list {
      margin-bottom: -1rem;
      @include mediaMinWidthDesktop {
        margin-bottom: -1.25rem;
      }
    }

    .boxes__item {
      margin-bottom: 1rem;
      padding: 1.5rem;
      height: 100%;
      border-radius: 1.5rem;
      background-color: $colorWhite;
      display: flex;
      flex-direction: column;
      @include box-shadow;
      @include transition;
      @include mediaMinWidthDesktop {
        padding: 2rem;
        margin-bottom: 1.25rem;
      }
      &:hover{
        background-color: $colorPrimary100;
        @include box-shadowSecondary;
      }
    }

    .boxes__media,
    .boxes__header,
    .boxes__main,
    .boxes__footer {
      &:not(:last-child) {
        margin-bottom: 1rem;
        @include mediaMinWidthDesktop {
          margin-bottom: 2rem;
        }
      }
    }

    .boxes__media {
      height: 3rem;
      width: 3rem;
      display: flex;
      align-items: flex-start;

      &:not(:last-child) {
        margin-bottom: 2rem;
        @include mediaMinWidthDesktop {
          margin-bottom: 2.5rem;
        }
      }

      &.boxes__media_second {
        width: auto;
        height: 4.5rem;
        @include mediaMinWidthDesktop {
          height: 9.25rem;
        }

        &:not(:last-child) {
          margin-bottom: 1rem;
          @include mediaMinWidthDesktop {
            margin-bottom: 4rem;
          }
        }
      }

      img,
      svg {
        width: auto;
        height: 100%;
      }
    }

    .boxes__header {
      display: flex;
      align-items: flex-start;

      .wysiwyg {
        &:not(:last-child) {
          margin-bottom: 0;
          margin-right: 1rem;
        }
      }

      .boxes__number {
        margin-left: auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .number__text {
          color: $colorQuartyty400;
          font-style: italic;
        }
      }
    }

    .boxes__main {
      color: $colorPrimary300;
    }
  }


  /* TABS */

  .tabs {
    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }
    .tabs__header {
      display: flex;
      justify-content: center;

      &:not(:last-child) {
        margin-bottom: 2rem;
        @include mediaMinWidthDesktop {
          margin-bottom: 4.5rem;
        }
      }

      &.tabs__header_second {
        @include mediaMinWidthDesktop {
          margin-bottom: 1.25rem;
        }
      }

      .tabs__list {
        padding: .5rem;
        background-color: $colorWhite;
        border-radius: 4rem;
        width: 100%;
        display: flex;
        justify-content: center;
        @include box-shadow;

        .tabs__item {
          font-size: 1.25rem;
          line-height: 1.25rem;
          font-weight: 600;
          width: 100%;
          padding: 1.25rem;
          color: $colorQuartyty400;
          background-color: $colorWhite;
          border-radius: 4rem;
          display: flex;
          justify-content: center;
          cursor: pointer;
          @include transition;

          &:not(:last-child) {
            margin-right: .5rem;
          }

          &:hover,
          &.tabs__item_active {
            color: $colorWhite;
            background-color: $colorQuartyty400;
          }
        }
      }
    }
    &.tabs_second {
      .tabs__header {
        margin-bottom: 1.25rem;
        .tabs__item {
          color: $colorPrimary400;
          font-size: 1rem;
          line-height: 1.25rem;
          font-weight: 600;
          padding: .75rem;
        }
      }
    }
  }


  /* FORM */
  .form__group {
    font-size: 1rem;
    line-height: 1.5rem;

    &:not(:last-child) {
      &:not(.form__group_second) {
        margin-bottom: 1.25rem;
        padding-bottom: 1.25rem;
        border-bottom: 1px solid #EEEEEE;
      }
    }

    .group__header {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: .75rem;
      }
    }

    .group__title {
      &:not(:last-child) {
        margin-right: .75rem;
      }

      .title__text {
        color: $colorPrimary300;
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 500;
      }
    }
  }

  /* TOOLTIP */
  .form__tooltip {
    position: relative;

    &:hover {
      .tooltip__dropdown {
        opacity: 1;
        visibility: visible;
      }
    }

    .tooltip__media {
      width: 1rem;
      height: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      img,
      svg {
        width: 100%;
        height: 100%;
      }
    }

    .tooltip__dropdown {
      font-size: .875rem;
      line-height: 1rem;
      position: absolute;
      top: 0;
      left: calc(100% + 1rem);
      border-radius: 1rem;
      padding: 1rem;
      color: $colorWhite;
      background-color: $colorPrimary400;
      z-index: 1;
      width: 15rem;
      opacity: 0;
      visibility: hidden;
      @include transition;
    }
  }

  .formItems {
    display: flex;

    .formItem {
      width: 100%;
    }

    .formClips {
      width: 2.5rem;
      min-width: 2.5rem;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;

      img,
      svg {
        width: 1.25rem;
        height: auto;
      }
    }
  }

  // Ячейка формы
  .formItem {
    margin-bottom: 1.25rem;
  }

  .formItem__header {
    &:not(:last-child) {
      margin-bottom: .75rem;
    }

    .formItem__label {
      color: $colorPrimary300;
      font-size: 1rem;
      line-height: 1.25rem;
      font-weight: 500;
      cursor: pointer;
      display: block;
    }
  }

  .formItem__main {
    position: relative;
    &:not(:last-child) {
      margin-bottom: .5rem;
    }
  }

  .formItem__field {
    width: 100%;
    display: flex;
    align-items: flex-start;
    position: relative;

    &.formItem__field_before {
      .formItem__media {
        left: 0;
      }

      .formItem__input {
        padding-left: 3rem;
      }
    }

    &.formItem__field_after {
      .formItem__media {
        right: 0;
      }

      .formItem__input {
        padding-right: 3rem;
      }
    }

    &.formItem__field_tooltipBefore,
    &.formItem__field_tooltipAfter {
      .formItem__tooltip {
        left: 1.25rem;
        right: auto;
        text-align: left;
      }

      .formItem__input {
        & + .formItem__tooltip {
          left: auto;
          right: 1.25rem;
          text-align: right;
        }
      }
    }

    &.formItem__field_tooltipBefore {
      .formItem__input {
        padding-left: 4rem;
      }
    }

    &.formItem__field_tooltipAfter {
      .formItem__input {
        padding-right: 4rem;
      }
    }
  }

  .formItem__data {
    color: $colorPrimary300;
    font-size: .75rem;
    line-height: .75rem;
    font-weight: 500;

    .data__list {
      display: flex;
      flex-direction: column;
    }

    .data__item {
      display: flex;
      justify-content: flex-start;

      &:not(:last-child) {
        margin-bottom: .25rem;
      }
    }

    .data__key {
      &:not(:last-child) {
        margin-right: .25rem;
      }
    }

    .data__value {
      margin-left: auto;
    }
  }

  .formItem__progress {
    color: $colorPrimary200;
    font-size: 1.125rem;
    line-height: 1.5rem;
    position: absolute;
    right: .5rem;
    bottom: .5rem;
  }


  // Иконка формы
  .formItem__media {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 2.5rem;
    height: 2.5rem;
    top: 0;

    &:hover {
      .media__ico {
        stroke: $colorSecondary500;
      }
    }

    .media__ico {
      width: 1.5rem;
      height: 1.5rem;
      stroke: $colorPrimary300;
      fill: transparent;
      @include transition;
    }
  }

  // Тултип
  .formItem__tooltip {
    color: $colorPrimary300;
    font-size: 1.125rem;
    line-height: 1.375rem;
    font-weight: 500;
    width: 3.25rem;
    position: absolute;
    top: 50%;
    right: 1.25rem;
    text-align: right;
    z-index: 2;
    @include transform-y;
  }

  // Поле ввода
  .formItem__input {
    color: $colorPrimary500;
    background-color: $colorWhite;
    font-size: 1.125rem;
    line-height: 1.375rem;
    font-weight: 500;
    font-family: $mainFont;
    padding: .5rem 1.25rem;
    width: 100%;
    border-radius: 1.25rem;
    border: 1px solid $colorPrimary400;
    margin: 0;
    text-overflow: ellipsis;
    box-sizing: border-box;
    appearance: none;
    outline: none;
    @include transition;

    &.formItem__input_second {
      background-color: #EEEEEE;
      border: 0;
    }

    // заблокированно
    &:disabled {
      opacity: .5;
    }

    // ошибка
    &.error {
      border-color: $colorTertiary500;
    }

    // подсказка
    &::placeholder {
      opacity: 1;
      color: $colorPrimary400;
    }

    // активное поле
    &:focus,
    &:hover {
      border-color: $colorPrimary400;
    }

    // екстареа
    &.formItem__input_textarea {
      width: 100% !important;
      height: 6.25rem !important;
      resize: none;
    }
  }

  .toggle {
    .toggle__list {
      margin: 0 -1.25rem -.5rem 0;
      display: flex;
      justify-content: center;
    }

    .toggle__item {
      margin: 0 1.25rem .5rem 0;
      position: relative;
    }

    .toggle__label {
      cursor: pointer;
    }

    .toggle__input {
      position: absolute;
      left: -999999px;

      &:checked + .toggle__text {
        color: $colorWhite;
        background-color: $colorQuartyty400;
      }
    }

    .toggle__text {
      color: $colorQuartyty400;
      padding: .5rem 1rem;
      font-size: 1rem;
      line-height: 1.5rem;
      border-radius: 2rem;
      background-color: $colorWhite;
      border: .125rem solid $colorQuartyty400;
      @include transition;
      @include mediaMinWidthMobile {
        font-size: 1.125rem;
        line-height: 1.5rem;
        padding: .5rem 1.25rem;
      }
      @include mediaMinWidthDesktop {
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }
  }


  .color_300 {
    color: $colorPrimary300;
  }

  .bg_300 {
    background-color: #EEEEEE;
  }

  .w_100 {
    width: 100% !important;
  }

  .b_0 {
    border: 0;
  }

  .p_0 {
    padding: 0 !important;
  }

  .m_0 {
    margin: 0 !important;
  }

  .justifyCenter {
    justify-content: center !important;
  }

  /* MODAL */
  .modal__layout {
    .modal__header,
    .modal__main {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }

      .modal__title {
        font-size: 1.25rem;
        line-height: 1.25rem;
        font-weight: 600;
      }
    }

    .modal__group {
      &:not(:last-child) {
        padding-bottom: 1.25rem;
        margin-bottom: 1.25rem;
        border-bottom: 1px solid #EEEEEE;
      }
    }

    .modal__tooltip {
      text-align: center;
      max-width: 29rem;
      margin: 0 auto;

      &:not(:last-child) {
        margin-bottom: 1.25rem;
      }

      .tooltip__text {
        color: #FF004D;
        font-size: 1rem;
        line-height: 1.25rem;
        font-weight: 600;
      }
    }
  }

  /* TOOLTIP */
  .tooltip {
    padding: 1rem;
    border-radius: .75rem;
    color: $colorTertiary500;
    background-color: $colorTertiary300;
    display: flex;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    .tooltip__media {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      &:not(:last-child) {
        margin-right: 1rem;
      }

      img,
      svg {
        width: 100%;
        height: 100%;
      }
    }

    .tooltip__main {
      font-size: 1rem;
      line-height: 1.125rem;
    }
  }

  /* DATASET */
  .dataset {
    .dataset__list {
      margin-bottom: -1.25rem;

      .dataset__item {
        margin-bottom: 1.25rem;
        display: flex;
        flex-direction: column;
      }

      .dataset__title {
        &:not(:last-child) {
          margin-bottom: .25rem;
        }

        .title__text {
          color: $colorPrimary300;
          font-size: .875rem;
          line-height: 1.25rem;
          font-weight: 500;
          display: block;
        }
      }

      .dataset__value {
        .data__text {
          color: $colorPrimary400;
          font-size: 1rem;
          line-height: 1.25rem;
          font-weight: 500;
          display: block;
        }
      }


      &.dataset__list_second {
        margin-bottom: -.75rem;

        .dataset__item {
          margin-bottom: .75rem;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        .dataset__title {
          &:not(:last-child) {
            margin-bottom: 0;
            margin-right: .25rem;
          }
        }
      }
    }
  }

}




