/* quill-output-styles.css */
.quill-content {
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

.quill-content h1,
.quill-content h2,
.quill-content h3 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.quill-content h1 {
  font-size: 2em;
  font-weight: bold;
}

.quill-content h2 {
  font-size: 1.5em;
  font-weight: bold;
}

.quill-content p {
  margin-bottom: 1em;
}

.quill-content ul,
.quill-content ol {
  margin-bottom: 1em;
  padding-left: 1.5em;
}

.quill-content a {
  color: #0066cc;
  text-decoration: none;
}

.quill-content a:hover {
  text-decoration: underline;
}

.quill-content .ql-align-right {
  text-align: right;
}
.quill-content .ql-align-center {
  text-align: center;
}
.quill-content .ql-align-justify {
  text-align: justify;
}

.quill-content strong {
  font-weight: bold;
}

.quill-content em {
  font-style: italic;
}

.quill-content u {
  text-decoration: underline;
}

.quill-content s {
  text-decoration: line-through;
}

.quill-content blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}

.quill-content .ql-size-small,
.ql-editor .ql-size-small {
  font-size: 0.75em !important;
}

.quill-content .ql-size-large,
.ql-editor .ql-size-large {
  font-size: 1.5em !important;
}

.quill-content .ql-size-huge,
.ql-editor .ql-size-huge {
  font-size: 2.5em !important;
}

.ql-editor {
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

.ql-editor h1 {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.ql-editor h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.ql-editor p {
  margin-bottom: 1em;
}

.ql-editor strong {
  font-weight: bold;
}

.ql-editor em {
  font-style: italic;
}

.ql-editor u {
  text-decoration: underline;
}

.ql-editor s {
  text-decoration: line-through;
}

.ql-editor blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}

.ql-editor ul,
.ql-editor ol {
  padding-left: 1.5em;
  margin-bottom: 1em;
}

.ql-editor a {
  color: #007bff;
  text-decoration: underline;
}

.ql-editor .ql-size-small {
  font-size: 0.75em;
}

.ql-editor .ql-size-large {
  font-size: 1.5em;
}

.ql-editor .ql-size-huge {
  font-size: 2.5em;
}
