@use 'src/media' as media;
@use 'src/variables' as variables;

.wrapper {
  width: 100%;
  height: fit-content;
  margin-top: 28px;
}

.borderWrapper {
  position: relative;
  width: 100%;
  padding-bottom: 0.016px;
  z-index: 2;
  border-radius: 10px 20px 20px 20px;
}

.navWrapper {
  position: relative;
  z-index: 2;
  display: flex;
  height: 42px;
  gap: 5px;
  z-index: 1;

  @include media.phone {
    height: 29px;
  }
  @include media.phoneV {
    gap: 2px;
  }
}

.navButton {
  width: 200px;
  height: 42px;
  padding-top: 4px;
  margin: 0;
  @include variables.fs20;
  color: variables.$fontBlack;
  background: variables.$mainColorForButtons;
  border: 1px solid variables.$mainColorForButtonBorder;
  border-bottom: 0;
  border-radius: 10px 10px 0px 0px;

  @include media.phone {
    width: 132px;
    height: 29px;

    padding-top: 3px;

    @include variables.fs14;
  }
  @include media.phoneV {
    width: 85px;
    height: 29px;

    padding-top: 3px;

    @include variables.fs14;
  }
  span {
    color: variables.$fontBlack;
  }
}

.navButtonActive {
  width: 196px;
  height: 44px;
  padding-top: 0px;

  background: variables.$bgWhite;
  border-radius: 8px 8px 0px 0px;
  border: 2px solid variables.$blockBorderColor;
  border-bottom: 0;

  span {
    font-weight: 600;

    background: linear-gradient(135deg, #8c00fd 0%, #a800c0 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  @include media.phone {
    width: 128px;
    height: 31px;
  }
  @include media.phoneV {
    width: 81px;
  }
}

.typeActionButtonsWrapper {
  position: absolute;
  width: 262px;
  height: 29px;
  z-index: 3;
  top: 0;
  right: 0;

  display: none;
  justify-content: center;

  @include media.phoneV {
    width: 169px;
  }

  button {
    width: 142px;
    height: 100%;

    font-weight: 500;
    @include variables.fs14;
    color: #909193;
    // background-color: #cdced1;
    background-repeat: no-repeat;

    @include media.phoneV {
      width: 93px;
      background-image: url('./images/BuyInactivePhone.svg');
    }

    &.green {
      position: absolute;
      left: 0;

      background-image: url('./images/BuyInactive.svg');

      @include media.phoneV {
        background-image: url('./images/BuyInactivePhone.svg');
      }
    }
    &.red {
      position: absolute;
      right: 0;

      background-image: url('./images/SellInactive.svg');

      @include media.phoneV {
        background-image: url('./images/SellInactivePhone.svg');
      }
    }
    &.activeGreen {
      z-index: 4;
      width: 131px;
      font-weight: 600;
      color: variables.$bgWhite;
      // background: variables.$fontGreen;

      background-image: url('./images/BuyActive.svg');
      @include media.phoneV {
        width: 87px;
        background-image: url('./images/BuyActivePhone.svg');
      }

      @media (hover: hover) {
        &:hover {
          background-image: url('./images/BuyActiveHover.svg');
          @include media.phoneV {
            background-image: url('./images/BuyActivePhoneHover.svg');
          }
        }
      }
      &:active {
        background-image: url('./images/BuyActiveHover.svg');
        @include media.phoneV {
          background-image: url('./images/BuyActivePhoneHover.svg');
        }
      }
    }

    &.activeRed {
      z-index: 4;
      width: 131px;
      font-weight: 600;
      color: variables.$bgWhite;
      // background: variables.$fontRed;

      background-image: url('./images/SellActive.svg');
      @include media.phoneV {
        width: 87px;
        background-image: url('./images/SellActivePhone.svg');
      }

      @media (hover: hover) {
        &:hover {
          background-image: url('./images/SellActiveHover.svg');
          @include media.phoneV {
            background-image: url('./images/SellActivePhoneHover.svg');
          }
        }
      }
      &:active {
        background-image: url('./images/SellActiveHover.svg');
        @include media.phoneV {
          background-image: url('./images/SellActivePhoneHover.svg');
        }
      }
    }
  }

  @include media.phone {
    display: flex;
  }
}

.chevron {
  z-index: 5;
}

.content {
  z-index: 0;
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 0px 35px 10px 35px;
  overflow: hidden;

  background: variables.$bgWhite;
  border-radius: 0px 18px 18px 18px;
  border: 2px solid variables.$blockBorderColor;

  @include media.tablet {
    padding: 1px 10px 8px 10px;
  }
  @include media.phone {
    padding: 13px 20px 20px 20px;
    border-radius: 0px 0px 18px 18px;
  }
  @include media.phoneV {
    padding: 13px 10px 10px 10px;
  }
}

.crossBg {
  position: absolute;
  z-index: 1;
  width: calc(100% - 70px);
  height: calc(100% - 20px);
  top: 10px;

  background: variables.$blockBorderColor;

  @include media.tablet {
    width: calc(100% - 20px);
  }
  @include media.phone {
    width: calc(100% - 40px);
    height: calc(100% - 35px);
    top: 13px;
  }
  @include media.phoneV {
    width: calc(100% - 20px);
  }
}

.table {
  width: 100%;
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  grid-template-rows: 42px 1px min-content;
  grid-template-areas:
    'title1 line1 title2'
    'line2 line2 line2'
    'cell1 line3 cell2';

  @include media.phone {
    grid-template-columns: 1fr;
    grid-template-rows: 42px 1px min-content;
    grid-template-areas:
      'title1'
      'line2'
      'cell1';
  }
}
.title1 {
  grid-area: title1;
}
.title2 {
  grid-area: title2;
}
.cell1 {
  grid-area: cell1;
}
.cell2 {
  grid-area: cell2;
}
.line1 {
  grid-area: line1;
  @include media.phone {
    display: none;
  }
}
.line2 {
  grid-area: line2;
  @include media.phoneV {
    width: calc(100% - 20px);
  }
}
.line3 {
  grid-area: line3;
  @include media.phone {
    display: none;
  }
}

.titleRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: variables.$bgWhite;

  &.titleRowLeft {
    padding-right: 30px;

    @include media.tablet {
      padding-right: 10px;
    }
    @include media.phone {
      padding-right: 0px;
    }
  }

  &.titleRowRight {
    padding-left: 18px;
    padding-right: 10px;

    @include media.tablet {
      padding-left: 10px;
      padding-right: 0px;
    }

    @include media.phone {
      display: none;
    }
  }
}

.tableTitle {
  font-weight: 500;
  @include variables.fs20;
  @include media.tablet {
    @include variables.fs18;
  }
  @include media.phoneV {
    @include variables.fs16;
  }
}

.balanceWrapper {
  display: flex;
  align-items: center;
  gap: 8px;

  .balanceTitle {
    font-weight: 400;
    @include variables.fs16;

    @include media.phoneV {
      @include variables.fs12;
    }
  }
  .balanceValue {
    font-weight: 400;
    @include variables.fs20;

    @include media.mobileDevices {
      @include variables.fs16;
    }
    @include media.phoneV {
      @include variables.fs14;
    }
  }
  .balanceCurrency {
    @include variables.fs18;

    background: radial-gradient(150.69% 118.33% at 123.17% 100%, #003629 0%, #7f00e2 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    opacity: 0.6;

    @include media.phoneV {
      @include variables.fs14;
    }
  }
}

.cell {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px 20px 0px 0px;

  background: variables.$bgWhite;

  &.cellRight {
    padding: 10px 0px 0px 20px;

    @include media.phone {
      display: none;
    }
  }

  @include media.tablet {
    padding: 10px 10px 0px 0px;
  }
  @include media.phone {
    padding: 0px;
    padding-top: 20px;
  }
}

.fieldWrapper {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}

.fieldName {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-weight: 400;
  @include variables.fs18;

  @include media.phoneV {
    @include variables.fs16;
  }
}

.fieldInput {
  text-align: right;
  padding-right: 70px;

  font-weight: 400;
  @include variables.fs20;

  border: 1px solid #ab49f8;
  border-radius: 10px;
  outline: none;

  &:disabled {
    background-image: none;
    background-color: #f0f0f0;
    border: 0;
  }

  @include media.tablet {
    @include variables.fs16;
  }
  @include media.phone {
    @include variables.fs20;
  }
  @include media.phoneV {
    @include variables.fs18;
  }
}
.fieldInputMax {
  padding-right: 124px;
}

.fieldCurrency {
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  top: 0;
  right: 9px;

  background: radial-gradient(150.69% 118.33% at 123.17% 100%, #003629 0%, #7f00e2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  opacity: 0.6;
  @include variables.fs18;
  @include media.phoneV {
    @include variables.fs16;
  }
}
.fieldCurrencyMax {
  right: 69px;
}
.fieldMaxBtn {
  width: 58px;
  height: calc(100% - 2px);
  z-index: 3;
  position: absolute;
  display: flex;
  align-items: center;

  top: 1px;
  right: 1px;

  p {
    width: 100%;
    height: 100%;
    display: grid;
    place-content: center;

    @include variables.fs18;
    @include variables.titleColorAlt;

    @media (hover: hover) {
      &:hover {
        background: transparent;
        -webkit-text-fill-color: variables.$bgWhite;
      }
    }
    &:active {
      background: transparent;
      -webkit-text-fill-color: variables.$bgWhite;
    }
  }
}

.percentWrapper {
  position: relative;
  z-index: 2;
  display: grid;
  margin-top: 5px;
  margin-right: 14px;
  gap: 4px 77px;
  grid-template-columns: repeat(5, 9px);
  grid-template-rows: 9px min-content;

  @include media.tablet {
    gap: 4px 48px;
  }
  @include media.phoneV {
    gap: 4px 59px;
  }

  .circle {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    border-radius: 100%;

    background: #d9d9d9;

    &.active {
      background: #ab49f8;
    }
  }

  span {
    justify-self: center;

    font-weight: 400;
    @include variables.fs14;

    color: #8d8d8d;

    &.active {
      background: linear-gradient(135deg, #8c00fd 0%, #a800c0 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      opacity: 0.5;
    }
  }
}

.progressLine {
  position: absolute;
  z-index: 1;
  left: 4px;
  width: calc(100% - 4px * 2);
  height: 1px;

  margin-top: 4px;
  background: #d9d9d9;
}

.mt5 {
  margin-top: 5px;
}
.mt10 {
  margin-top: 10px;
}

.comissionWrapper {
  margin-top: 5px;
  display: flex;
  gap: 27px;
  align-self: flex-start;

  span {
    font-weight: 400;
    @include variables.fs14;

    opacity: 0.5;
  }

  @include media.mobileDevices {
    gap: 5px;
    flex-direction: column;
    align-items: flex-end;
  }
}

.infoWrapper {
  display: flex;

  span:nth-of-type(2) {
    margin-left: 10px;
  }
  span:nth-of-type(3) {
    margin-left: 8px;
  }
  span:nth-of-type(4) {
    margin-left: 5px;
  }
}

.infoCommission {
  text-decoration-line: underline;

  cursor: pointer;
}

.buttonGreen {
  width: 178px;
  margin-top: 5px;

  font-weight: 600;

  background: #00d09e;
  color: variables.$bgWhite;
  border-radius: 10px;
  border: 0;

  @media (hover: hover) {
    &:hover {
      background: #00ba8d;
    }
  }
  &:active {
    background: #00ba8d;
  }
  &:disabled {
    opacity: 0.5;
  }
}
.buttonRed {
  width: 178px;
  margin-top: 5px;

  font-weight: 600;

  background: #ff004d;
  color: variables.$bgWhite;
  border-radius: 10px;
  border: 0;

  @media (hover: hover) {
    &:hover {
      background: #d52b1e;
    }
  }
  &:active {
    background: #d52b1e;
  }
  &:disabled {
    opacity: 0.5;
  }
}

.hideNumberInputArrows {
  /* Chrome, Safari, и Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  -moz-appearance: textfield;

  appearance: textfield;
}
