@use 'src/media' as media;
@use 'src/variables' as variables;

.wrapper {
  display: none;
  width: 100%;
  height: fit-content;
  margin-top: 20px;

  @include media.phone {
    display: block;
  }
}

.borderWrapper {
  display: none;
  width: 100%;
  height: fit-content;
  padding: 1px;

  border: 2px solid variables.$blockBorderColor;
  border-radius: 20px 20px 0px 0px;

  @include media.phone {
    display: block;
  }
}

.content {
  width: 100%;
  height: fit-content;
  padding-top: 5px;

  background: variables.$bgWhite;
  border-radius: 19px 19px 0px 0px;
}

.firstRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px 10px 20px;

  h3 {
    font-weight: 400;
    background: radial-gradient(150.69% 118.33% at 123.17% 100%, #003629 0%, #7f00e2 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
}

.filterBlock {
  width: auto;
  height: 21px;
  display: flex;
  align-items: center;
  gap: 8px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 21px;
    height: 21px;

    img {
      width: 19px;
      height: 19px;
    }
  }
}

.filterActive {
  background: radial-gradient(150.69% 118.33% at 123.17% 100%, #c6fff1 0%, #e7c8ff 100%);
}

.table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}
.tableSelected {
  grid-template-columns: 1fr;

  .right {
    .cellRow {
      grid-auto-flow: row;
      direction: ltr;
    }
    span:nth-of-type(2) {
      direction: ltr;
      justify-self: flex-start;
    }
  }
}

.left {
  .containerWrapper {
    background: radial-gradient(
      107.25% 302.83% at 3.5% 11.32%,
      rgba(204, 255, 243, 0.2) 0%,
      rgba(208, 244, 245, 0.2) 93.26%
    );
  }
  :not(.cellFirstRow) span:nth-of-type(3) {
    color: variables.$fontGreen;
  }
}
.right {
  .containerWrapper {
    background-color: #fff2f6;
  }
  .cellRow {
    grid-auto-flow: dense;
    direction: rtl;
  }
  :not(.cellFirstRow) span:nth-of-type(3) {
    color: variables.$fontRed;
  }

  span:nth-of-type(2) {
    direction: ltr;
    justify-self: flex-end;
  }
}

.containerWrapper {
  height: 269px;
}

.cellRow {
  height: 25px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 20% 1fr;
  padding: 0 10px;

  font-weight: 400;
  @include variables.fs12;

  span:nth-of-type(3) {
    justify-self: flex-end;
    font-weight: 500;
  }

  @include media.phoneV {
    grid-template-columns: 1fr 1fr;

    &:not(.cellRowSelected) span:nth-of-type(2) {
      display: none;
    }
  }
}
.cellRowSelected {
  grid-template-columns: 1.1fr 0.8fr 1fr 0.8fr;

  span:nth-of-type(3) {
    justify-self: flex-start;
  }
  span:nth-of-type(4) {
    justify-self: flex-end;
  }
}

.cellFirstRow {
  font-weight: 500;
}

.gradientLine {
  width: 100%;
  height: 1px;

  background: linear-gradient(135deg, #8c00fd 0%, #a800c0 100%);
}

.grayLine {
  width: 100%;
  height: 1px;

  background-color: #e9e7e7;
}

.buttonsWrapper {
  width: 100%;
  height: 40px;
  display: flex;
  gap: 10px;
  margin-top: 20px;

  button {
    width: 100%;
    height: 100%;

    font-weight: 600;
    @include variables.fs20;
    color: variables.$bgWhite;

    border-radius: 10px;

    @include media.phoneV {
      @include variables.fs18;
    }
  }

  .green {
    background: variables.$fontGreen;

    @media (hover: hover) {
      &:hover {
        background: variables.$buttonGreenHover;
      }
    }
    &:active {
      background: variables.$buttonGreenHover;
    }
  }
  .red {
    background: variables.$fontRed;
    @media (hover: hover) {
      &:hover {
        background: variables.$buttonRedHover;
      }
    }
    &:active {
      background: variables.$buttonRedHover;
    }
  }
}
