@import 'src/media';
@import 'src/variables';

.services {
  .boxes__row {
    @include mediaMinWidthTablet {
      display: grid;
      grid-auto-rows: 1fr;
      gap: 0 20px;
      margin: 0;
      width: auto;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .boxes__col {
    @include mediaMinWidthTablet {
      width: 100%!important;
      padding: 0!important;
    }
  }
}
