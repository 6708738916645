// Markets
.layout_Tokens
    .layout__markets
        gap: .25rem
        display: flex
        flex-direction: column
    .markets__section
        gap: .25rem
        display: flex
        flex-direction: column
    .markets__list
        gap: .25rem
        overflow: hidden
        overflow-x: auto
        display: flex
        @include mediaMinWidthTabletPort
            grid-template-columns: 1fr 1fr 1fr
            display: grid
        .markets__item
            width: 20.125rem
            flex-shrink: 0
            @include mediaMinWidthTabletPort
                width: auto
    .markets__item
        border-radius: 1.25rem
        padding: 1rem
        background-color: $colorWhite1000
        display: grid
        flex-shrink: 0
        grid-gap: .75rem
        @include mediaMinWidthMobile
            grid-gap: 1rem
        @include mediaMinWidthDesktop
            grid-gap: 1.25rem
            padding: 1.25rem
        &.markets__item_secondary
            padding: 0
            background: none
        .markets__title
            font-size: 1.125rem
            line-height: 1.25rem
            font-weight: 700
            @include mediaMinWidthDesktop
                font-size: 1.25rem
                line-height: 1.5rem   
    .market__main
        &:not(:last-child)
            border-bottom: 1px solid $colorPrimary40
    .market__footer
        padding-top: 1rem
        display: flex
        justify-content: flex-end  
    // MARKETS TABLE
    .market__table
        max-width: calc(100vw - 4rem)
        overflow: hidden
        overflow-x: auto
        .table__list
            gap: .5rem
            display: grid
            grid-template-columns: auto 4rem 4.25rem
            @include mediaMinWidthTablet
                gap: .5rem
                grid-template-columns: auto 4.25rem 4.25rem
            @include mediaMinWidthDesktop
                grid-template-columns: auto 7.25rem 5.25rem
            &.table__list_secondary
                @include mediaMinWidthTabletPort
                    grid-template-columns: auto 5.25rem 6.25rem 5.25rem 5.25rem 9.25rem 10rem
                @include mediaMinWidthDesktop
                    grid-template-columns: auto 9.25rem 7.25rem 6.25rem 6.25rem 10.25rem 10rem
                @include mediaMinWidthDesktopBig
                    grid-template-columns: auto 10.25rem 9.25rem 7.25rem 7.25rem 15.25rem 10rem
                &:not(:last-child)
                    @include mediaMinWidthDesktop
                        border-bottom: 1px solid $colorPrimary40
                .table__item
                    &:nth-child(3)
                        justify-content: flex-end
                        @include mediaMinWidthTabletPort
                            justify-content: unset
                    &:nth-child(4),
                    &:nth-child(5),
                    &:nth-child(6),
                    &:nth-child(7)
                        display: none
                        @include mediaMinWidthTabletPort
                            display: flex
            &.table__list_tertiary
                grid-template-columns: auto auto 4.25rem 5.875rem
                @include mediaMinWidthTablet
                    grid-template-columns: auto 7.25rem 6rem 7.25rem
                @include mediaMinWidthDesktop
                    grid-template-columns: auto 9.25rem 7rem 7.25rem
                .table__item,
                .table__link
                    @include mediaMinWidthDesktop
                        padding: .75rem 0
            &.table__list_quarty
                grid-template-columns: unset
                .table__item
                    padding: 0
                    justify-content: flex-start
        .table__title
            color: $colorPrimary400
            font-size: .75rem
            line-height: 1rem
            font-weight: 500
            @include mediaMinWidthDesktop
                font-size: .875rem
                line-height: 1.125rem
            &.table__title_secondary
                font-size: .75rem
                line-height: 1rem
            .table__subtitle
                display: none
                @include mediaMinWidthDesktop
                    display: unset
        .table__sort
            width: .75rem
            height: .75rem
            flex-shrink: 0
            cursor: pointer
            display: none
            @include mediaMinWidthTablet
                display: unset
        .table__item,
        .table__link
            gap: .25rem
            padding: .25rem 0
            display: flex
            align-items: center
            @include mediaMinWidthDesktop
                padding: .5rem 0
                gap: .75rem
            &:last-child
                justify-content: flex-end
        .table__text
            font-size: .75rem
            line-height: 1rem
            font-weight: 600
            overflow: hidden
            text-overflow: ellipsis
            white-space: nowrap
            @include mediaMinWidthDesktop
                font-size: 1.125rem
                line-height: 1.5rem
            &.table__text_secondary
                color: $colorGreen400
            &.table__text_tertiary
                color: $colorRed400
        .table__ico
            width: 1.5rem
            height: 1.5rem
            flex-shrink: 0
            border-radius: 50%  
            object-fit: cover   
            overflow: hidden  
            @include mediaMinWidthDesktop
                width: 2.25rem
                height: 2.25rem
        .table__ico
            & ~ .table__text
                display: block

        .table__tooltip
            color: $colorSecondary1000
            background-color: $colorSecondary100
            font-size: .625rem
            line-height: .875rem
            font-weight: 600
            padding: .25rem .75rem
            border-radius: .25rem
            display: none
            overflow: hidden
            text-overflow: ellipsis
            white-space: nowrap
            @include mediaMinWidthTablet
                display: unset
            @include mediaMinWidthDesktop
                font-size: .75rem
                line-height: .875rem 

    // MARKETS FILTERS
    .markets__filters
        .filters__list
            grid-gap: .25rem
            display: flex
            flex-direction: column
        .filters__item
            border-radius: 1.25rem
            padding: 1rem
            background-color: $colorWhite1000
            display: flex
            justify-content: space-between
            align-items: center
            overflow: hidden
            overflow-x: auto
            gap: .75rem
            @include mediaMinWidthMobile
                gap: 1rem
            @include mediaMinWidthDesktop
                gap: 1.25rem
                padding: 1.25rem
        .filter__list
            gap: .25rem
            display: flex
            @include mediaMinWidthMobile
                gap: .75rem
            @include mediaMinWidthDesktopBig
                gap: 1.125rem
            &.filter__list_secodnary
                @include mediaMinWidthDesktop
                    gap: .75rem
                .filter__btn
                    font-weight: 600
        .filter__item
            cursor: pointer
            white-space: nowrap
            &.filter__item_active,
            &:hover
                .filter__text
                    color: $colorPrimary1000
                .filter__btn
                    color: $colorSecondary1000
                    background-color: $colorSecondary100
        .filter__text
            color: $colorPrimary500
            font-size: .875rem
            line-height: 1rem
            font-weight: 700
            @include transition
            @include mediaMinWidthMobile
                font-size: 1.125rem
                line-height: 1.125rem
            @include mediaMinWidthDesktop
                font-size: 1.25rem
                line-height: 1.5rem
        .filter__btn
            color: $colorFifth500
            background-color: $colorFifth40
            font-size: .875rem
            line-height: 1.125rem
            font-weight: 400
            padding: .25rem 1.125rem
            border-radius: .375rem
            @include transition            

    .fixed_layout 
        display: flex
        flex-direction: column
        height: 100%
    .fixed_layout__item 
        display: flex
        flex-direction: column
        height: 100%
    .fixed_layout__content 
        flex-grow: 1
        display: flex
        flex-direction: column
        min-height: 0
    .fixed_layout__table-content 
        flex-grow: 1
        display: flex
        flex-direction: column
        justify-content: flex-start
        overflow-y: auto
  