@import '../../helper/media';
@import '../../helper/variables';
// dataset
.dataset {
  margin: 0 -0.75rem -0.75rem;
  width: calc(100% + 1.5rem);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .dataset__item {
    margin: 0 0.75rem 0.75rem;
    display: flex;
    flex-direction: column;
  }
}
