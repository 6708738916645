.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f5f5f5;
}
.header-title {
  font-size: 1.5rem;
  font-weight: bold;
}
.search-container {
  position: relative;
  width: 100%;
  max-width: 300px;
  align-self: flex-end;
  // margin-left: auto;
}
.search-wrapper {
  position: relative;
  width: 100%;
}

.search-input {
  width: 100%;
  padding: 0.5rem 2.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.375rem;
  font-size: 1rem;
  line-height: 1.5;
  background-color: #ffffff;
}
.search-icon,
.close-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1.5rem;
  height: 1.5rem;
  pointer-events: none;
}

.search-icon {
  left: 0.75rem;
}

.close-icon {
  right: 0.75rem;
  cursor: pointer;
  pointer-events: auto;
}
.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-top: none;
  border-radius: 0 0 0.375rem 0.375rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  list-style: none;
  padding: 0;
  margin: 0;
}
.search-result-item {
  a {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    text-decoration: none;
    color: #333;
    cursor: pointer;
    &:hover {
      background-color: #f5f5f5;
    }
    img {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      margin-right: 0.75rem;
    }
    span {
      font-size: 0.875rem;
    }
  }
}
@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .search-container {
    margin-top: 1rem;
    align-self: flex-end;
    margin-left: 0;
  }
}
