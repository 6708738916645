// Poster
.layout_Tokens
    .layout__poster
        .poster__media
            border-radius: 1.5rem
            display: flex
            align-items: flex-start
            overflow: hidden
            img
                min-height: 11rem






