// Partners
.layout_Tokens
    .layout__partners
        .partners__list
            grid-gap: .125rem
            display: grid
            grid-template-columns: repeat(2,1fr)
            @include mediaMinWidthMobile
            grid-template-columns: repeat(4,1fr)
            @include mediaMinWidthTablet
                grid-template-columns: repeat(8,1fr)
        .partners__item
            &:hover
                .partners__link
                    border-color: $colorPrimary200
            &.partners__item_secondary
                &:hover
                    .partners__link
                        background-color: $colorSecondary900  
                .partners__link
                    color: $colorWhite1000
                    background-color: $colorSecondary1000
                    border-color: $colorSecondary1000 
        .partners__link
            padding: 1rem
            height: 6.25rem
            border: 1px solid $colorTertiary400
            display: flex
            justify-content: center
            align-items: center
            @include transition
            @include mediaMinWidthMobile
                height: 9rem
            @include mediaMinWidthTablet
                height: 7.5rem
        .partners__img
            height: 4rem
            width: auto
            object-fit: contain
            @include mediaMinWidthMobile
                height: 4rem
            @include mediaMinWidthTablet
                height: 2.75rem       
            @include mediaMinWidthTablet
                height: 5rem
        .partners__ico
            height: 4rem
            @include mediaMinWidthTablet
                height: 5rem
            @include mediaMinWidthDesktop
                height: 3.25rem
