@import 'src/media';
@import 'src/variables';

.instructions {
  .instructions__list {
    margin-bottom: -1.25rem;
  }

  .instructions__item {
    padding: 2rem;
    border-radius: 1.5rem;
    margin-bottom: 1.25rem;
    background-color: #ffffff;
    box-shadow: 0 .25em .75em rgba(0, 0, 0, .3);
  }

  .instructions__header {
    display: flex;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: 7.75rem;
    }
  }

  .instructions__title {
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
