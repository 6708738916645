.layout_Tokens
    // Roadmap
    .layout__roadmap
        gap: 2em
        display: grid
        @include mediaMinWidthMobile
            gap: 4.5rem
        .roadmap__list
            gap: 2rem
            display: flex
            flex-direction: column
            @include mediaMinWidthDesktop
                gap: 3rem
        .roadmap__item
            gap: 1rem
            display: grid
            align-items: flex-start
            @include mediaMinWidthMobile
                gap: 2rem
                grid-template-columns: 8.75rem auto
            @include mediaMinWidthTablet
                grid-template-columns: 13.5rem auto
            @include mediaMinWidthDesktop
                grid-template-columns: 19.5rem auto
        .roadmap__header
            @include mediaMinWidthMobile
                display: flex
                justify-content: flex-end
            .roadmap__title
                font-size: 1rem
                line-height: 1.5rem
                font-weight: 500
                width: 9.5rem
                gap: .5rem
                display: flex
                align-items: center
                @include mediaMinWidthMobile
                    font-size: 1.5rem
                    line-height: 3rem
                &::before
                    content: ""
                    width: .5rem
                    height: .5rem
                    border-radius: 50%
                    background-color: $colorSecondary1000
                    @include mediaMinWidthMobile
                        width: .75rem
                        height: .75rem
        .roadmap__events
            .events__list
                gap: .5rem
                display: flex
                flex-wrap: wrap
            .events__item
                border: 1px solid $colorFifth100
                border-radius: .375rem
                overflow: hidden
                @include transition
                &.events__item_secondary,
                &:hover
                    border-color: $colorSecondary1000
                    .events__link
                        color: $colorWhite1000
                        background-color: $colorSecondary1000
                    .events__ico,
                    .events__arrow
                        fill: $colorWhite1000
            .events__link
                color: $colorPrimary1000
                padding: .75rem 1.5rem
                font-weight: 500
                background-color: $colorWhite1000
                gap: .75rem
                display: flex
                align-items: center
                @include transition
            .events__ico
                width: 1.5rem
                height: 1.5rem
                flex-shrink: 0
                fill: $colorSecondary1000
                @include transition
            .events__arrow
                width: 1.25rem
                height: 1.25rem
                flex-shrink: 0
                fill: $colorPrimary1000
                @include transition
        .roadmap__action
            display: flex
            justify-content: center
            .btn
                max-width: 26.25rem






