// TOKENS
.layout_Tokens
    .tokens__form
        display: flex
        flex-direction: column
        position: relative
        grid-gap: 2rem
        @include mediaMinWidthDesktop
            display: grid
            align-items: flex-start
            grid-template-columns: 1fr 1fr
            grid-gap: .25rem
    // Sidebar
    .tokens__sidebar
        order: 2
        @include mediaMinWidthDesktop
            border-radius: 1.25rem
            padding: 1.5rem
            max-width: 40.5rem
            width: calc(50vw - 2rem)
            background-color: $colorTertiary400
            grid-gap: 2rem
            position: absolute
            padding: 2rem
            display: grid
            &.tokens__sidebar_fixed
                position: fixed
                top: 0
            &.tokens__sidebar_bottom
                bottom: 0
                top: auto
                position: absolute
        .sidebar__header,
        .sidebar__main
            display: none
            @include mediaMinWidthDesktop
                display: block
        .sidebar__title
            font-weight: 700

    // Navigation
    .tokens__nav
        .nav__list
            border: 1px solid $colorPrimary100
            display: flex
            flex-direction: row
            @include mediaMinWidthTablet
                flex-direction: column
        .nav__item
            position: relative
            &::before
                content: ""
                position: absolute
                top: 0
                left: 0
                width: .125rem
                height: 100%
                background-color: transparent
                @include transition
            &:not(:last-child)
                border-bottom: 1px solid $colorPrimary100
            &:hover,
            &.nav__item_active
                &::before
                &::before
                    background-color: $colorSecondary1000
                .nav__link
                    color: $colorSecondary1000  
        .nav__link
            color: $colorPrimary500
            font-size: 1.125rem
            line-height: 1.25rem
            font-weight: 700
            padding: .75rem 1.125rem
            cursor: pointer
            display: block
            @include transition
            @include mediaMinWidthDesktop
                font-size: 1.25rem
                line-height: 1.5rem

    // tokens__main
    .tokens__main
        grid-column: -2
        gap: .5em
        display: grid
        align-items: flex-start


    // Group
    .tokens__group
        border-radius: 1.25rem
        padding: 1rem .75rem
        background-color: $colorTertiary400
        display: grid
        grid-gap: .75rem
        @include mediaMinWidthMobile
            padding: 1.5rem
            grid-gap: 1.5rem
        @include mediaMinWidthDesktop
            padding: 2rem
            grid-gap: 2rem
        .group__title
            font-weight: 700
        .group__main
            gap: 1rem
            display: grid
            @include mediaMinWidthDesktop
                gap: 2rem
            &.group__main_secondary
                gap: 1rem







