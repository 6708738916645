@import '../../helper/media';
@import '../../helper/variables';
// HEADER
.p2p_header {
  width: 100%;
  padding: 0.75rem 0;
  background-color: #202639;
  .header__layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header__logo {
    .header__link {
      color: $colorWhite;
      display: flex;
      align-items: flex-start;
      @include transition;
      &:hover,
      &.active {
        color: $colorQuartyty400;
      }
    }
    .header__text {
      font-size: 0.875rem;
      line-height: 1rem;
      font-weight: 400;
      @include mediaMinWidthMobile {
        font-size: 1.25rem;
        line-height: 1.25rem;
        font-weight: 500;
      }
    }
  }
  .header__menu {
    .menu__list {
      display: flex;
      justify-content: flex-end;
    }
    .menu__item {
      display: flex;
      &:not(:last-child) {
        margin-right: 2.5rem;
        @include mediaMinWidthTablet {
          margin-right: 40px;
        }
      }

      &:nth-child(1),
      &:nth-child(4) {
        .menu__media {
          @include mediaMinWidthTablet {
            width: 24px;
            height: 24px;
          }
          img,
          svg {
            stroke: $colorWhite;
            fill: none;
          }
        }
        .menu__link {
          &.active,
          &:hover {
            .menu__media {
              img,
              svg {
                stroke: $colorQuartyty400;
              }
            }
          }
        }
      }
      &:nth-child(4) {
        .menu__media {
          @include mediaMinWidthTablet {
            &:not(:last-child) {
              margin-right: 0.25rem;
            }
          }
        }
        .menu__title {
          display: none;
          @include mediaMinWidthTablet {
            display: block;
          }
        }
      }
      .menu__link {
        color: $colorWhite;
        display: flex;
        align-items: center;
        position: relative;
        &[class*='active'],
        &:hover {
          color: $colorQuartyty400;
          .menu__title {
            .title__text {
              color: $colorQuartyty400;
            }
          }
          .menu__media {
            img,
            svg {
              fill: $colorQuartyty400;
            }
          }
        }
      }

      .menu__media {
        width: 1rem;
        height: 1rem;
        @include mediaMinWidthMobile {
          width: 1.25rem;
          height: 1.25rem;
          &:not(:last-child) {
            margin-right: 0.25rem;
          }
        }
        img,
        svg {
          width: 100%;
          height: 100%;
          fill: $colorWhite;
          @include transition;
        }
      }
      .menu__title {
        display: none;
        @include mediaMinWidthMobile {
          display: block;
        }
        .title__text {
          font-size: 1.125rem;
          line-height: 1.5rem;
          font-weight: 400;
          @include transition;
        }
      }
      .menu__tooltip {
        width: 1rem;
        height: 1rem;
        background-color: $colorWhite;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
        top: -0.5rem;
        right: -0.25rem; 
        
        .tooltip__text {
          color: $colorQuartyty400;
          font-size: 0.75rem;
          line-height: 0.875rem;
        }
      }
    }
  }
}

.p2p {
  .section {
    position: relative;
    .section__header,
    .section__main {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
    &:not(:last-child) {
      margin-bottom: 3rem;
      &:before {
        content: '';
        width: 75rem;
        height: 1px;
        max-width: calc(100vw - 1rem);
        background-color: $colorPrimary200;
        position: absolute;
        top: calc(100% + 1.5rem);
        left: 50%;
        @include transform-x;
      }
    }
  }
}
