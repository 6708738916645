// Navigation
.layout_Tokens
  .layout__nav
    display: flex
    gap: .375rem
    .nav__list
      gap: .375rem
      display: flex
    .nav__item
      &:hover,
      &.nav__item_active
        .nav__link
          color: $colorWhite1000
          background-color: $colorSecondary1000
        .nav__ico
          fill: $colorWhite1000
    .nav__link
      color: $colorPrimary1000
      font-size: .75rem
      line-height: 1rem
      font-weight: 600
      width: 2rem
      height: 2rem
      border-radius: .375rem
      border: 1px solid $colorFifth60
      display: flex
      justify-content: center
      align-items: center
      text-align: center
      cursor: pointer
      @include transition
      @include mediaMinWidthMobile
        font-size: .875rem
        line-height: 1rem
      @include mediaMinWidthTablet
        width: 2.75rem
        height: 2.75rem
        font-size: 1rem
        line-height: 1.25rem
    .nav__ico
      width: 1.5rem
      height: 1.5rem
      fill: $colorPrimary1000
      @include transition