@import 'src/media';
@import 'src/variables';

.steps {
  .steps__main {
    margin-bottom: 2rem;
    @include mediaMinWidthDesktop {
      margin-bottom: 0;
    }
  }
}
