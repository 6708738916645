@import '../../helper/media';
@import '../../helper/variables';
// LAYOUT
.layout__account {
  padding: 1.25rem 0;

  @include mediaMinWidthTablet {
    padding: 3rem 0;
  }
  .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
    @include mediaMinWidthTablet {
      grid-template-columns: 14rem auto;
      grid-gap: 1.5rem 1.25rem;
    }
  }
  .account__header {
    @include mediaMinWidthTablet {
      grid-column: span 2;
    }
  }
  .account__main {
    background: $colorWhite;
    border-radius: 20px;
    padding: 0.75rem;
    box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.062745098);
    @include mediaMinWidthMobile {
      padding: 1.5rem;
    }
  }
}
