@import '../../helper/media';
@import '../../helper/variables';
.layout__authorization{
  .authorization__layout{
    background-color: $colorWhite;
    border-radius: 1.5rem;
    padding: 1.5rem;
    @include box-shadowSecond;
    @include mediaMaxWidthMobile {
      display: none;
      &.authorization__layout_active{
        display: block;
      }
    }
  }
  .authorization__header{
    text-align: center;
    &:not(:last-child){
      margin-bottom: 1.5rem;
    }
  }
  .authorization__main{
    &:not(:last-child){
      margin-bottom: 1.5rem;
    }
  }
  .authorization__group{
    &:not(:last-child){
      margin-bottom: 1.5rem;
    }
  }
  .authorization__forgot{
    color: $colorPrimary400;
    font-weight: 500;
    text-align: center;
    opacity: .5;
    cursor: pointer;
    @include transition;
    &:hover{
      color: $colorQuartyty500;
      opacity: 1;
    }
  }
  .authorization__change{
    color: $colorQuartyty500;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    display: block;
    @include transition;
    @include mediaMinWidthTablet{
      display: none;
    }
    &:hover{
      color: $colorQuartyty300;
    }
  }
}