// @import 'default';
@import 'media';
@import 'variables';

.project {
  background-color: $colorTertiary1000;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-weight: 400;
    a {
      color: $colorMain400;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  h1,
  .h1 {
    font-size: 1.5rem;
    line-height: 2rem;
    @include mediaMinWidthMobile {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    @include mediaMinWidthDesktop {
      font-size: 2.5rem;
      line-height: 3rem;
    }
  }
  h2,
  .h2 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    @include mediaMinWidthMobile {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    @include mediaMinWidthDesktop {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }
  h3,
  .h3 {
    font-size: 1rem;
    line-height: 1.25rem;
    @include mediaMinWidthMobile {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
    @include mediaMinWidthDesktop {
      font-size: 1.5rem;
      line-height: 1.75rem;
    }
  }
  h4,
  .h4 {
    font-size: 1rem;
    line-height: 1.25rem;
    @include mediaMinWidthDesktop {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
  h5,
  .h5 {
    font-size: 1rem;
    line-height: 1.25rem;
    @include mediaMinWidthDesktop {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }
  h6,
  .h6 {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  img,
  svg {
    outline: none;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    height: auto;
  }
  ul,
  ol {
    list-style: none;
  }
  a {
    text-decoration: none;
  }

  // BUTTON
  .btn {
    color: $colorWhite;
    background-color: $colorSecondary1000;
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 600;
    padding: 1rem 1.25rem;
    border: 1px solid $colorSecondary1000;
    border-radius: 6.25rem;
    gap: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    text-align: center;
    appearance: none;
    @include transition;
    @include mediaMinWidthTablet {
      font-size: 1rem;
      line-height: 1rem;
    }
    @include mediaMinWidthDesktop {
      font-size: 1rem;
      line-height: 1.25rem;
      padding: 1.5rem 4.75rem;
    }
    &:disabled {
      background-color: $colorMain500;
      border-color: transparent;
      pointer-events: none;
    }
    &:hover {
      background-color: $colorMain1000;
      border-color: $colorMain1000;
    }
    img,
    svg {
      width: 1rem;
      height: 1rem;
      flex-shrink: 0;
      fill: $colorWhite;
      @include transition;
      @include mediaMinWidthTablet {
        width: 1.25rem;
        height: 1.25rem;
      }
      &.btn_transparent {
        color: $colorSecondary1000;
        border-color: $colorSecondary1000;
        background-color: transparent;
        &:hover {
          color: $colorWhite;
          background-color: $colorSecondary1000;
          border-color: $colorSecondary1000;
          img,
          svg {
            fill: $colorWhite;
          }
        }
        img,
        svg {
          fill: $colorSecondary1000;
        }
      }
    }
    &.btn_secondary {
      color: $colorWhite;
      background-color: $colorMain1000;
      border-color: $colorMain1000;
      &:hover {
        color: $colorWhite;
        background-color: $colorSecondary1000;
        border-color: $colorSecondary1000;
      }
    }
    &.btn_tertiary {
      color: $colorMain1000;
      background-color: $colorMain200;
      border-color: transparent;
      &:disabled {
        background-color: transparent;
      }
      &:hover {
        color: $colorWhite;
        background-color: $colorMain1000;
        border-color: transparent;
      }
    }
    &.btn_transparent {
      color: $colorMain1000;
      border-color: $colorMain1000;
      background-color: transparent;
      &:hover {
        color: $colorWhite;
        background-color: $colorMain1000;
        border-color: $colorMain1000;
        img,
        svg {
          fill: $colorWhite;
        }
      }
      img,
      svg {
        fill: $colorMain1000;
      }
    }
    &.btn_tiny {
      padding: 0.75rem 1.25rem;
    }
  }
  // SOCIALS
  .socials {
    .socials__list {
      gap: 0.7rem;
      display: flex;
      flex-wrap: wrap;
    }
    .socials__link {
      width: 3.675rem;
      height: 3.675rem;
      background-color: $colorWhite;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mediaMinWidthTablet {
        width: 5.675rem;
        height: 5.675rem;
      }
    }
    img,
    svg {
      width: 1.75rem;
      height: 1.7rem;
      fill: #2196f3;
      @include mediaMinWidthTablet {
        width: 2.75rem;
        height: 2.7rem;
      }
    }
  }
  // WYSIWYG
  .wysiwyg {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
    h1,
    h2 {
      color: $colorMain1000;
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
    h3,
    h4,
    h5,
    h6 {
      color: $colorMain800;
      &:not(:last-child) {
        margin-bottom: 1.25rem;
      }
    }
    img {
      display: block;
      margin: 3.25rem 0;
    }
    p,
    ul,
    ol {
      &:not(:last-child) {
        margin-bottom: 2.5rem;
      }
    }
    img {
      border-radius: 1rem;
      overflow: hidden;
    }
    strong {
      font-weight: 600;
    }
    ul {
      list-style: none;
      ul {
        margin-left: 0;
      }
      li {
        position: relative;
        padding-left: 1.25rem;
        &:before {
          content: '';
          left: 0;
          top: 0.4rem;
          width: 0.625rem;
          height: 0.625rem;
          border-radius: 50%;
          background-color: $colorSecondary400;
          position: absolute;
          &:not(:last-child) {
            margin-bottom: 1.25rem;
          }
        }
      }
    }
    ol {
      list-style: decimal;
      padding-left: 1rem;
      li {
        margin: 0 0 0.5rem;
      }
    }
    a {
      color: $colorSecondary1000;
      position: relative;
      white-space: nowrap;
      text-decoration: none;
    }
  }
  .container {
    width: 85rem;
    max-width: calc(100vw - 2rem);
    margin: 0 auto;
    @include mediaMinWidthMobile {
      max-width: calc(100vw - 3rem);
    }
    @include mediaMinWidthTablet {
      max-width: calc(100vw - 4rem);
    }
    &.container_secondary {
      width: 53.625rem;
    }
  }
  select {
    color: #202639;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 600;
    height: 3.25rem;
    min-width: 12.5rem;
    border-radius: 0.75rem;
    border: 1px solid #20263933;
    padding: 1rem;
    background-color: $colorWhite;
    background-image: url('../img/arrowBottom.png');
    background-repeat: no-repeat;
    background-position: 90% 50%;
    appearance: none;
    outline: none;
    cursor: pointer;
  }

  // PICTURE
  .project__picture {
    padding-top: 45%;
    margin-bottom: -1.25rem;
    position: relative;
    display: flex;
    @include mediaMinWidthMobile {
      padding-top: 22.222%;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  // HEADER
  .project__header {
    padding: 1rem 1rem 0;
    border-radius: 0.75rem 0.75rem 0 0;
    background-color: $colorWhite;
    position: relative;
    z-index: 2;
    @include mediaMinWidthMobile {
      padding: 2rem 2rem 0;
      border-radius: 1.25rem 1.25rem 0 0;
    }
    @include mediaMinWidthDesktop {
      padding: 2.5rem 2.5rem 0;
      border-radius: 1.5rem 1.5rem 0 0;
    }
    .container {
      display: flex;
      flex-direction: column;
      grid-gap: 1.25rem;
    }
    .project__data {
      .data__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
        @include mediaMinWidthMobile {
          grid-gap: 1.125rem;
        }
        @include mediaMinWidthTablet {
          grid-template-columns: repeat(4, 1fr);
        }
        @include mediaMinWidthDesktop {
          grid-gap: 1.25rem;
        }
      }
      .data__item {
        border-radius: 0.75rem;
        padding: 1.25rem;
        background-color: $colorTertiary1000;
        display: grid;
        align-items: center;
        gap: 0 1.25rem;
        @include mediaMinWidthMobile {
          gap: 0 1.5rem;
          align-items: flex-start;
        }
        @include mediaMinWidthDesktop {
          gap: 0 2rem;
        }
        &.data__item_secondary {
          grid-column: span 2;
          grid-template-columns: 7.5rem auto;
          @include mediaMinWidthMobile {
            grid-column: span 2;
            grid-row: span 2;
            grid-template-columns: 10.375rem auto;
          }
          @include mediaMinWidthDesktop {
            gap: 0 2rem;
            grid-template-columns: 12.375rem auto;
          }
        }
      }
      .data__media {
        width: 7.5rem;
        height: 7.5rem;
        border-radius: 0.75rem;
        grid-row: span 2;
        background-color: $colorWhite;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.75rem;
        @include mediaMinWidthMobile {
          margin-bottom: 0;
          width: 10.375rem;
          height: 10.375rem;
          grid-row: span 3;
        }
        @include mediaMinWidthDesktop {
          width: 12.375rem;
          height: 12.375rem;
        }
        img {
          width: 5.125rem;
          height: 5.125rem;
          @include mediaMinWidthMobile {
            width: 7.25rem;
            height: 7.25rem;
          }
          @include mediaMinWidthDesktop {
            width: 8.75rem;
            height: 8.75rem;
          }
        }
      }

      .data__header {
        @include mediaMinWidthMobile {
          &:not(:last-child) {
            margin-bottom: 0.75rem;
          }
        }
      }
      .data__status {
        gap: 0.5rem;
        display: flex;
        align-items: center;
        img,
        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
        .status__text {
          color: #2196f3;
          font-size: 1rem;
          line-height: 1.25rem;
          transform: translateY(0.125rem);
        }
      }
      .data__main {
        display: grid;
        grid-gap: 0.125rem;
        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }
      }
      .data__subtitle {
        color: $colorMain800;
        opacity: 0.5;
        font-size: 0.75rem;
        line-height: 0.875rem;
        @include mediaMinWidthMobile {
          font-size: 1rem;
          line-height: 1.25rem;
        }
        @include mediaMinWidthDesktop {
          font-size: 1.25rem;
          line-height: 1.5rem;
        }
      }
      .data__title {
        color: $colorMain1000;
        font-size: 1.5rem;
        line-height: 2rem;
        @include mediaMinWidthMobile {
          font-size: 1.875rem;
          line-height: 2rem;
        }
        @include mediaMinWidthDesktop {
          font-size: 2.25rem;
          line-height: 2.5rem;
        }
        &.data__title_secondary {
          color: #00b31d;
        }
      }
      .data__footer {
        grid-column: span 2;
        display: grid;
        gap: 1rem;
        grid-template-columns: auto 4.25rem;
        @include mediaMinWidthMobile {
          grid-column: unset;
        }
        .btn {
          width: 100%;
        }
      }
      .data__btn {
        width: 4.25rem;
        height: 4.25rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $colorMain200;
        background-color: $colorWhite;
        cursor: pointer;
        @include transition;
        &:hover {
          border: 1px solid $colorMain1000;
        }
        svg {
          width: 1rem;
          height: 1rem;
        }
      }
    }
    .project__tags {
      .tags__list {
        display: flex;
        flex-wrap: wrap;
        gap: 1.25rem;
      }
      .tags__item {
        gap: 0.75rem;
        border-radius: 0.5rem;
        padding: 0.75rem 1.5rem;
        background-color: $colorTertiary1000;
        display: flex;
        align-items: center;
      }
      img,
      svg {
        width: 1.5rem;
        height: 1.5rem;
        flex-shrink: 0;
      }
      .tags__text {
        color: $colorMain800;
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }

    .project__tabs {
      .tabs__header {
        .tabs__list {
          gap: 3.25rem;
          display: flex;
          overflow: hidden;
          overflow-x: auto;
        }
        .tabs__item {
          color: #20263980;
          gap: 0.25rem;
          padding: 2rem 0;
          display: flex;
          cursor: pointer;
          position: relative;
          @include transition;
          &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0%;
            height: 0.25rem;
            border-radius: 0.75rem 0.75rem 0 0;
            background-color: $colorSecondary1000;
            @include transition;
          }
          &:hover,
          &.tabs__item_active {
            color: $colorMain1000;
            &:before {
              width: 100%;
            }
          }
        }
        .tabs__text {
          font-size: 1.25rem;
          line-height: 1.5rem;
          white-space: nowrap;
        }
        .tabs__count {
          color: $colorSecondary1000;
          font-size: 0.75rem;
          line-height: 0.875rem;
        }
      }
    }
  }
  // main
  .project__main {
    padding: 2rem 0;
    background: no-repeat url('../img/background.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @include mediaMinWidthTablet {
      padding: 2.5rem 0;
    }
  }
  // footer
  .project__footer {
    padding: 1.25rem 0;
    background-color: $colorWhite;
  }
  .project__action {
    .action__list {
      gap: 0.75rem;
      display: flex;
      flex-direction: column;
      @include mediaMinWidthTablet {
        gap: 1.25rem;
        flex-direction: row;
        align-items: center;
      }
    }
    .action__item {
      width: 100%;
    }
  }

  // INFORMATION
  .project__information {
    .container {
      grid-gap: 1.25rem;
      display: grid;
      @include mediaMinWidthTablet {
        grid-template-columns: auto 30.125rem;
      }
    }
  }
  .information__main {
    display: grid;
    grid-gap: 1.25rem;
  }
  // section
  .information__section {
    background-color: rgba(255, 255, 255, 0.4);
    border: 1px solid $colorWhite;
    backdrop-filter: blur(0.5rem);
    border-radius: 1.5rem;
    padding: 1.25rem;
  }
  // graphic
  .information__graphic {
    border-radius: 1.5rem;
    padding: 1.25rem;
    background-color: rgba(255, 255, 255, 0.6);
    border: 1px solid $colorWhite;
    display: grid;
    .graphic__header {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      grid-gap: 1rem;
      @include mediaMinWidthTablet {
        grid-gap: 2rem 1rem;
        flex-wrap: nowrap;
      }
      &:not(:last-child) {
        margin-bottom: 2.5rem;
      }
      .graphic__ico {
        width: 3.25rem;
        height: 3.25rem;
        background-color: $colorWhite;
        border-radius: 0.25rem;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        img,
        svg {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
      .graphic__title {
        .graphic__text {
          color: $colorMain1000;
          font-size: 1rem;
          line-height: 1.5rem;
          @include mediaMinWidthTablet {
            font-size: 1.25rem;
            line-height: 1.5rem;
          }
          @include mediaMinWidthDesktop {
            font-size: 1.5rem;
            line-height: 2rem;
          }
        }
      }
      .graphic__subtitle {
        .graphic__text {
          color: $colorMain500;
          font-size: 0.875rem;
          line-height: 1rem;
          @include mediaMinWidthTablet {
            font-size: 1rem;
            line-height: 1.25rem;
          }
          @include mediaMinWidthDesktop {
            font-size: 1.25rem;
            line-height: 1.5rem;
          }
        }
      }
      .graphic__sellect {
        @include mediaMinWidthTablet {
          margin-left: auto;
          display: flex;
          justify-content: flex-end;
        }
        select {
          @include mediaMinWidthTablet {
            width: 12.5rem;
          }
        }
      }
    }
    .graphic__main {
      &:not(:last-child) {
        margin-bottom: 3.625rem;
      }
    }
    .graphic__cost {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
      .cost__title {
        color: $colorMain1000;
        font-size: 2.25rem;
        line-height: 2.5rem;
      }
      .cost__subtitle {
        color: #00b31d;
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
    .graphic__schedule {
      display: grid;
      grid-gap: 1.25rem;
      @include mediaMinWidthTablet {
        grid-template-columns: auto 4.5rem;
      }
      .schedule__calendar {
        .calendar__list {
          display: flex;
          align-items: center;
          gap: 1rem;
        }
        .calendar__item {
          cursor: pointer;
          &:hover,
          &.calendar__item_active {
            .calendar__text {
              color: $colorSecondary1000;
            }
          }
        }
        .calendar__text {
          color: #202639cc;
          opacity: 0.8;
          font-size: 1rem;
          line-height: 1.25rem;
          @include transition;

          @include mediaMinWidthDesktop {
            font-size: 1.25rem;
            line-height: 1.5rem;
          }
        }
      }

      .schedule__media {
        padding-top: 74%;
        border-right: 1px solid $colorMain200;
        position: relative;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      .schedule__times {
        .times__list {
          gap: 2rem;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        .times__item {
          cursor: pointer;
          &:hover {
            .times__text {
              color: $colorSecondary1000;
            }
          }
        }
        .times__text {
          color: #202639cc;
          opacity: 0.8;
          font-size: 0.875rem;
          line-height: 1rem;
        }
      }

      .schedule__cost {
        grid-row: span 3;
        display: none;
        flex-direction: column;
        gap: 1.5rem;
        @include mediaMinWidthTablet {
          display: flex;
        }
        .cost__title {
          color: $colorMain1000;
          font-size: 1.25rem;
          line-height: 1.5rem;
        }
        .cost__list {
          gap: 1.5rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .cost__item {
          cursor: pointer;
          &:hover {
            .cost__text {
              color: $colorSecondary1000;
            }
          }
        }
        .cost__text {
          color: #202639cc;
          opacity: 0.8;
          font-size: 0.875rem;
          line-height: 1rem;
          @include transition;
        }
      }
    }
    .graphic__footer {
      .graphic__text {
        color: $colorMain500;
        font-size: 0.875rem;
        line-height: 1rem;
        @include mediaMinWidthTablet {
          font-size: 1rem;
          line-height: 1.25rem;
        }
        @include mediaMinWidthDesktop {
          font-size: 1.25rem;
          line-height: 1.5rem;
        }
      }
    }
  }
  // description
  .information__description {
    display: grid;
    .description__head {
      gap: 0.25rem;
      display: flex;
      flex-direction: column;
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
      .description__title {
        color: $colorMain500;
        font-size: 0.875rem;
        line-height: 1.25rem;
        @include mediaMinWidthTablet {
          font-size: 1rem;
          line-height: 1.25rem;
        }
        @include mediaMinWidthDesktop {
          font-size: 1.25rem;
          line-height: 1.5rem;
        }
      }
      .description__subtitle {
        color: $colorMain1000;
        font-size: 1.25rem;
        line-height: 1.5rem;
        @include mediaMinWidthTablet {
          font-size: 1.5rem;
          line-height: 1.25rem;
        }
        @include mediaMinWidthDesktop {
          font-size: 2rem;
          line-height: 2.5rem;
        }
      }
    }
    .description__media {
      border-radius: 0.75rem;
      padding-top: 51%;
      position: relative;
      overflow: hidden;
      &:not(:last-child) {
        margin-bottom: 2.5rem;
        @include mediaMinWidthDesktop {
          margin-bottom: 3.25rem;
        }
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .description__header {
      display: grid;
      grid-gap: 1rem;
      &:not(:last-child) {
        margin-bottom: 1.25rem;
      }
    }
    .description__title {
      color: $colorMain1000;
      font-size: 1.25rem;
      line-height: 1.5rem;
      @include mediaMinWidthTablet {
        font-size: 1.5rem;
        line-height: 1.25rem;
      }
      @include mediaMinWidthDesktop {
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }
    .description__subtitle {
      color: $colorMain800;
      font-size: 1rem;
      line-height: 1.25rem;
      @include mediaMinWidthTablet {
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
      @include mediaMinWidthDesktop {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
    .description__main {
      color: $colorMain500;
      font-size: 0.875rem;
      line-height: 1.25rem;
      @include mediaMinWidthTablet {
        font-size: 1rem;
        line-height: 1.25rem;
      }
      @include mediaMinWidthDesktop {
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }
  }
  // sidebar
  .information__sidebar {
    gap: 1.25rem;
    display: flex;
    flex-direction: column;
    order: -1;
    @include mediaMinWidthTablet {
      order: unset;
    }
    .sidebar__group {
      padding: 1.25rem;
      border-radius: 1.5rem;
      background-color: rgba(255, 255, 255, 0.4);
      border: 1px solid $colorWhite;
      backdrop-filter: blur(0.5rem);
      display: grid;
      gap: 1.5rem;
      .group__header {
        .group__title {
          color: $colorMain500;
          font-size: 1rem;
          line-height: 1.25rem;
          @include mediaMinWidthDesktop {
            font-size: 1.25rem;
            line-height: 1.5rem;
          }
        }
      }
      .group__list {
        display: grid;
        gap: 0.75rem;
      }
      .group__item {
        background-color: $colorTertiary1000;
        border: 1px solid $colorWhite;
        padding: 1.25rem;
        border-radius: 0.75rem;
      }
      .group__title {
        width: 100%;
        color: $colorMain500;
        font-size: 1rem;
        line-height: 1.25rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @include mediaMinWidthDesktop {
          font-size: 1.25rem;
          line-height: 1.5rem;
        }
        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }
      .group__subtitle {
        color: $colorMain1000;
        font-size: 1.5rem;
        line-height: 2rem;
        width: calc(100% - 2.25rem);
        gap: 0.5rem;
        display: flex;
        align-items: center;
        @include mediaMinWidthDesktop {
          font-size: 2rem;
          line-height: 2.5rem;
        }
        &:not(:last-child) {
          margin-bottom: 2rem;
        }
      }
      .group__tooltip {
        width: 1.125rem;
        height: 1.125rem;
        position: relative;
        &:hover {
          .tooltip__dropdown {
            display: block;
          }
        }
        .tooltip__ico {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          display: flex;
          align-items: flex-start;
          cursor: pointer;
        }
        .tooltip__dropdown {
          position: absolute;
          top: 50%;
          font-size: 1rem;
          line-height: 1.25rem;
          right: calc(100% + 0.5rem);
          border-radius: 0.5rem;
          padding: 1.5rem 1.25rem;
          width: 20rem;
          background-color: $colorWhite;
          border: 1px solid $colorMain200;
          display: none;
          @include transform-y;
          @include transition;
        }
      }
    }
  }
}

// ARTICLES
.articles {
  gap: 2.5rem;
  display: flex;
  flex-direction: column;
  @include mediaMinWidthTablet {
    gap: 3.25rem;
  }
  .articles__list {
    grid-gap: 1.25rem;
    display: flex;
    flex-direction: column;
  }
}
.article {
  border-radius: 1.5rem;
  padding: 1.25rem;
  background-color: rgba(255, 255, 255, 0.4);
  border: 1px solid $colorWhite;
  backdrop-filter: blur(0.5rem);
  display: flex;
  flex-direction: column;
  .article__header {
    gap: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
    .article__tag {
      border-radius: 10rem;
      padding: 0.75rem 1.25rem;
      background-color: $colorSecondary200;
      display: flex;
      @include mediaMinWidthTablet {
        padding: 0.75rem 1.25rem;
      }
      @include mediaMinWidthDesktop {
        padding: 0.75rem 1.25rem;
      }
      .tag__text {
        color: $colorSecondary1000;
        font-size: 0.75rem;
        line-height: 0.75rem;
        @include mediaMinWidthTablet {
          font-size: 1rem;
          line-height: 1.25rem;
        }
        @include mediaMinWidthDesktop {
          font-size: 1.25rem;
          line-height: 1.5rem;
        }
      }
    }
    .article__date {
      .date__text {
        color: $colorMain500;
        font-size: 0.875rem;
        line-height: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include mediaMinWidthMobile {
          font-size: 1rem;
          line-height: 1.25rem;
        }
        @include mediaMinWidthDesktop {
          font-size: 1.25rem;
          line-height: 1.5rem;
        }
      }
    }
  }
  .article__main {
    color: $colorMain500;
    font-size: 0.875rem;
    line-height: 1rem;
    @include mediaMinWidthMobile {
      font-size: 1rem;
      line-height: 1.25rem;
    }
    @include mediaMinWidthDesktop {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }

    &:not(:last-child) {
      margin-bottom: 3.25rem;
    }
  }
  .article__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .article__data {
      .data__list {
        gap: 1.25rem;
        display: flex;
        align-items: center;
      }
      .data__item {
        gap: 0.5rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
          .data__text {
            color: $colorMain1000;
          }
        }
      }
      img,
      svg {
        width: 1.25rem;
        height: 1.25rem;
        flex-shrink: 0;
        @include mediaMinWidthTablet {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
      .data__text {
        color: $colorMain500;
        font-size: 0.875rem;
        line-height: 1rem;
        font-weight: 600;
        @include transition;
        @include mediaMinWidthTablet {
          font-size: 1rem;
          line-height: 1.5rem;
        }
      }
    }
  }
}

// COMMENTS
.comments {
  grid-gap: 1.25rem;
  display: grid;
  .comments__header {
    display: grid;
    align-items: center;
    border-radius: 1.5rem;
    padding: 1.25rem;
    background-color: rgba(255, 255, 255, 0.4);
    border: 1px solid $colorWhite;
    backdrop-filter: blur(0.5rem);
    grid-gap: 0.25rem 1.5rem;
    @include mediaMinWidthMobile {
      grid-template-columns: auto 12.5rem;
    }
    .comments__title {
      color: $colorMain500;
      font-size: 1.25rem;
      line-height: 1.5rem;
      @include mediaMinWidthMobile {
        order: -2;
      }
    }
    .comments__subtitle {
      color: $colorMain1000;
      font-size: 1.5rem;
      line-height: 2rem;

      @include mediaMinWidthTablet {
        font-size: 2rem;
        line-height: 2.5rem;
      }
      &:not(:last-child) {
        margin-bottom: 0.5rem;
        @include mediaMinWidthMobile {
          margin-bottom: 0;
        }
      }
    }
    .comments__select {
      @include mediaMinWidthMobile {
        order: -1;
        grid-row: span 2;
      }
    }
  }
  .comments__list {
    gap: 1.25rem;
    display: flex;
    flex-direction: column;
  }
}
.comments__addComment {
  padding: 1.25rem;
  border-radius: 1.5rem;
  background-color: rgba(255, 255, 255, 0.4);
  border: 1px solid $colorWhite;
  .addComment__header {
    display: grid;
    grid-gap: 0.25rem 1.5rem;
    @include mediaMinWidthMobile {
      grid-template-columns: auto 12.5rem;
    }
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
    .comments__title {
      color: $colorMain500;
      font-size: 1.25rem;
      line-height: 1.5rem;
      @include mediaMinWidthMobile {
        order: -2;
      }
    }
    .comments__subtitle {
      color: $colorMain1000;
      font-size: 1.5rem;
      line-height: 2rem;

      @include mediaMinWidthTablet {
        font-size: 2rem;
        line-height: 2.5rem;
      }
      &:not(:last-child) {
        margin-bottom: 0.5rem;
        @include mediaMinWidthMobile {
          margin-bottom: 0;
        }
      }
    }
    .comments__select {
      @include mediaMinWidthMobile {
        order: -1;
        grid-row: span 2;
      }
    }
  }
  .addComment__main {
    display: grid;
    gap: 0.75rem;
    grid-template-columns: 2rem auto;
    @include mediaMinWidthMobile {
      grid-template-columns: 2.5rem auto;
      gap: 1.25rem;
    }
    @include mediaMinWidthTablet {
      grid-template-columns: 3.25rem auto;
    }
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
    .comments__media {
      border-radius: 0.75rem;
      overflow: hidden;
      width: 2rem;
      height: 2rem;
      position: relative;
      @include mediaMinWidthMobile {
        font-size: 2.5rem;
        line-height: 2.5rem;
      }
      @include mediaMinWidthTablet {
        width: 3.25rem;
        height: 3.25rem;
      }
    }
    .comments__text {
      color: $colorMain1000;
      background-color: transparent;
      font-size: 1.25rem;
      line-height: 2.5rem;
      padding: 0 0.75rem;
      min-height: 2.5rem;
      width: 100%;
      height: 100%;
      font-weight: 400;
      font-family: $mainFont;
      margin: 0;
      border: 0;
      height: 2.75rem;
      resize: none;
      border-bottom: 1px solid $colorMain200;
      text-overflow: ellipsis;
      box-sizing: border-box;
      appearance: none;
      outline: none;
      @include transition;
      &::placeholder {
        opacity: 1;
        color: $colorMain500;
      }
      &:focus {
        border-color: $colorMain500;
      }
    }
  }

  .addComment__action {
    .action__list {
      gap: 0.75rem;
      display: flex;
      justify-content: flex-end;
    }
  }
}
.comment {
  display: grid;
  align-items: flex-start;
  grid-gap: 0 1rem;
  padding: 1.25rem;
  border-radius: 1.5rem;
  grid-template-columns: 2rem auto;
  background-color: rgba(255, 255, 255, 0.4);
  border: 1px solid $colorWhite;
  backdrop-filter: blur(0.5rem);
  @include mediaMinWidthMobile {
    grid-template-columns: 2.5rem auto;
  }
  @include mediaMinWidthTablet {
    grid-template-columns: 3.25rem auto;
  }
  .comments__list {
    display: grid;
    grid-gap: 3rem;
  }
  .comments__item {
    grid-template-columns: 1.125rem auto;
    padding: unset !important;
    border: unset !important;
    background: unset !important;
    backdrop-filter: unset !important;
    @include mediaMinWidthMobile {
      grid-template-columns: 2rem auto;
    }
    @include mediaMinWidthTablet {
      grid-template-columns: 2.5rem auto;
    }
    .comment__media {
      border-radius: 0.25rem;
      width: 1.125rem;
      height: 1.125rem;
      position: relative;
      @include mediaMinWidthMobile {
        font-size: 2rem;
        line-height: 2rem;
      }
      @include mediaMinWidthTablet {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
    .comment__header {
      &:not(:last-child) {
        margin-bottom: 1.25rem;
      }
      .comment__title {
        font-size: 0.875rem;
        line-height: 1rem;
        @include mediaMinWidthMobile {
          font-size: 1rem;
          line-height: 1.25rem;
        }
        @include mediaMinWidthTablet {
          font-size: 1.125rem;
          line-height: 1.25rem;
        }
      }
      .comment__subtitle {
        color: $colorMain500;
        font-size: 0.75rem;
        line-height: 0.875rem;
      }
    }
  }
  .comment__media {
    border-radius: 1rem;
    width: 2rem;
    height: 2rem;
    grid-row: span 4;
    overflow: hidden;
    position: relative;
    @include mediaMinWidthMobile {
      font-size: 2.5rem;
      line-height: 2.5rem;
    }
    @include mediaMinWidthTablet {
      width: 3.25rem;
      height: 3.25rem;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .comment__header {
    gap: 1rem;
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
    .comment__title {
      color: $colorMain1000;
      font-size: 1rem;
      line-height: 1rem;
      font-weight: 400;
      @include mediaMinWidthMobile {
        font-size: 1.25rem;
        line-height: 1.25rem;
      }
      @include mediaMinWidthTablet {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
    .comment__subtitle {
      color: $colorMain500;
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 400;
      @include mediaMinWidthMobile {
        font-size: 0.875rem;
        line-height: 1rem;
      }
      @include mediaMinWidthTablet {
        font-size: 1rem;
        line-height: 1.125rem;
      }
    }
  }
  .comment__main {
    color: $colorMain800;
    font-size: 0.875rem;
    line-height: 1rem;
    @include mediaMinWidthMobile {
      font-size: 1rem;
      line-height: 1.25rem;
    }
    @include mediaMinWidthTablet {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
  .comment__footer {
    gap: 1.25rem;
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 0.875rem;
    }
    .comment__favorite {
      gap: 0.5rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        .favorite__text {
          color: $colorMain1000;
        }
      }
      img,
      svg {
        width: 1.5rem;
        height: 1.5rem;
        flex-shrink: 0;
      }
      .favorite__text {
        color: $colorMain500;
        font-size: 0.875rem;
        line-height: 1rem;
        font-weight: 600;
        @include transition;
        @include mediaMinWidthTablet {
          font-size: 1rem;
          line-height: 1.5rem;
        }
      }
    }
    .comment__answer {
      cursor: pointer;
      &:hover {
        .answer__text {
          color: $colorMain900;
        }
      }
      .answer__text {
        color: $colorMain1000;
        font-size: 1rem;
        line-height: 1.125rem;
        font-weight: 600;
        @include transition;
      }
    }
  }

  .comment__dropdown {
    gap: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .dropdown__header {
      color: $colorSecondary1000;
      background-color: $colorSecondary200;
      gap: 0.75rem;
      padding: 0.75rem;
      border-radius: 0.75rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      @include transition;
      @include mediaMinWidthTablet {
        padding: 1rem;
      }
      &:hover {
        color: $colorWhite;
        background-color: $colorSecondary1000;
        img,
        svg {
          fill: $colorWhite;
        }
      }
      img,
      svg {
        fill: $colorSecondary1000;
        width: 0.75rem;
        height: 0.5rem;
        @include transition;
      }
      .dropdown__text {
        font-size: 0.875rem;
        line-height: 1rem;
        font-weight: 600;
        @include mediaMinWidthTablet {
          font-size: 1rem;
          line-height: 1.125rem;
        }
      }
    }
  }
}

.project {
  &.project_dark {
    background-color: #010607;
    h1,
    h2 {
      color: #ffffff;
    }
    h3,
    h4,
    h5,
    h6 {
      color: #ffffff70;
    }
    .btn {
      &.btn_transparent {
        color: $colorWhite;
        border-color: $colorWhite;
        background-color: transparent;
        &:hover {
          color: #010607;
          background-color: $colorWhite;
          border-color: $colorWhite;
          img,
          svg {
            fill: #010607;
          }
        }
        img,
        svg {
          fill: $colorWhite;
        }
      }
    }
    // HEADER
    .project__header {
      background-color: #010607;
      .project__data {
        .data__item {
          background-color: #ffffff10;
        }
        .data__media {
          background-color: #ffffff10;
        }
        .data__subtitle {
          color: #ffffff70;
        }
        .data__title {
          color: #ffffff;
          &.data__title_secondary {
            color: #00b31d;
          }
        }
      }
      .project__tags {
        .tags__item {
          background-color: #ffffff;
        }
        .tags__text {
          color: #01060770;
        }
      }
      .project__tabs {
        .tabs__header {
          .tabs__item {
            color: #ffffff50;

            &:before {
              background-color: $colorSecondary1000;
            }
            &:hover,
            &.tabs__item_active {
              color: #ffffff;
            }
          }
          .tabs__count {
            color: $colorSecondary1000;
          }
        }
      }
    }
    // MAIN
    .project__main {
      background: no-repeat url('../img/background2.jpg');
    }
    // footer
    .project__footer {
      background-color: #ffffff10;
    }

    // INFORMATION
    // section
    .information__section {
      background-color: #01060740;
      border: 1px solid #ffffff20;
    }
    // graphic
    .information__graphic {
      background-color: #ffffff10;
      border: 1px solid #ffffff10;
      .graphic__header {
        .graphic__ico {
          background-color: $colorWhite;
        }
        .graphic__title {
          .graphic__text {
            color: #ffffff;
          }
        }
        .graphic__subtitle {
          .graphic__text {
            color: #ffffff50;
          }
        }
      }
      .graphic__cost {
        .cost__title {
          color: #ffffff;
        }
        .cost__subtitle {
          color: #00b31d;
        }
      }
      .graphic__schedule {
        .schedule__calendar {
          .calendar__item {
            cursor: pointer;
            &:hover,
            &.calendar__item_active {
              .calendar__text {
                color: #a717ff;
              }
            }
          }
          .calendar__text {
            color: #ffffff50;
          }
        }
        .schedule__media {
          border-right: 1px solid #ffffff10;
        }
        .schedule__times {
          .times__item {
            cursor: pointer;
            &:hover {
              .times__text {
                color: #ffffff;
              }
            }
          }
          .times__text {
            color: #ffffff50;
          }
        }
        .schedule__cost {
          .cost__title {
            color: #ffffff;
          }
          .cost__item {
            cursor: pointer;
            &:hover {
              .cost__text {
                color: #ffffff;
              }
            }
          }
          .cost__text {
            color: #ffffff50;
          }
        }
      }
      .graphic__footer {
        .graphic__text {
          color: #ffffff70;
        }
      }
    }
    // description
    .information__description {
      .description__head {
        .description__title {
          color: #ffffff50;
        }
        .description__subtitle {
          color: #ffffff;
        }
      }
      .description__title {
        color: #ffffff;
      }
      .description__subtitle {
        color: #ffffff50;
      }
      .description__main {
        color: #ffffff50;
      }
    }
    // sidebar
    .information__sidebar {
      .sidebar__group {
        background-color: #01060740;
        border: 1px solid #ffffff20;
        .group__header {
          .group__title {
            color: #ffffff50;
          }
        }
        .group__item {
          background-color: #ffffff10;
          border: 1px solid #ffffff10;
        }
        .group__title {
          color: #ffffff50;
        }
        .group__subtitle {
          color: #ffffff;
        }
        .group__tooltip {
          .tooltip__dropdown {
            color: #000000;
            background-color: $colorWhite;
            border: 1px solid #ffffff10;
          }
        }
      }
    }

    // ARTICLES
    .article {
      background-color: #01060740;
      border: 1px solid #ffffff20;

      .article__header {
        .article__tag {
          background-color: $colorSecondary200;

          .tag__text {
            color: $colorSecondary1000;
          }
        }
        .article__date {
          .date__text {
            color: #ffffff50;
          }
        }
      }
      .article__main {
        color: #ffffff50;
      }
      .article__footer {
        .article__data {
          .data__item {
            &:hover {
              .data__text {
                color: #ffffff;
              }
            }
          }
          img,
          svg {
            fill: #ffffff;
          }
          .data__text {
            color: #ffffff50;
          }
        }
      }
    }
    // COMMENTS
    .comments {
      .comments__header {
        background-color: #01060740;
        border: 1px solid #ffffff20;

        .comments__title {
          color: #ffffff50;
        }
        .comments__subtitle {
          color: #ffffff;
        }
      }
    }

    .comments__addComment {
      background-color: #01060740;
      border-color: #ffffff20;
      .addComment__header {
        .comments__title {
          color: #ffffff50;
        }
        .comments__subtitle {
          color: #ffffff;
        }
        .comments__select {
          @include mediaMinWidthMobile {
            order: -1;
            grid-row: span 2;
          }
        }
      }
      .addComment__main {
        .comments__text {
          color: rgba(255, 255, 255, 1);
          border-color: rgba(255, 255, 255, 0.2);
          &:focus {
            border-color: rgba(255, 255, 255, 0.5);
          }
          &::placeholder {
            opacity: 1;
            color: rgba(255, 255, 255, 0.5);
          }
        }
      }
    }
    .btn {
      &:disabled {
        color: rgba(255, 255, 255, 0.5);
        background-color: rgba(255, 255, 255, 0.2);
      }
      &.btn_tertiary {
        color: rgba(255, 255, 255, 1);
        background-color: rgba(255, 255, 255, 0.2);
        &:disabled {
          color: rgba(255, 255, 255, 0.5);
          background-color: transparent;
        }
      }
    }
    select {
      color: #ffffff;
      border-color: rgba(255, 255, 255, 0.2);
      background-color: rgba(255, 255, 255, 0.1);
      background-image: url('../img/arrowBottomWhite.png');
      background-size: auto 25%;
      option {
        color: #000000;
      }
    }
    .comment {
      background-color: #01060740;
      border: 1px solid #ffffff20;

      .comments__item {
        .comment__header {
          .comment__subtitle {
            color: #ffffff50;
          }
        }
      }

      .comment__header {
        .comment__title {
          color: #ffffff;
        }
        .comment__subtitle {
          color: #ffffff50;
        }
      }
      .comment__main {
        color: #ffffff50;
      }
      .comment__footer {
        .comment__favorite {
          &:hover {
            .favorite__text {
              color: #ffffff;
            }
          }
          img,
          svg {
            fill: #ffffff;
          }
          .favorite__text {
            color: #ffffff;
          }
        }
        .comment__answer {
          cursor: pointer;
          &:hover {
            .answer__text {
              color: #ffffff;
            }
          }
          .answer__text {
            color: #ffffff;
          }
        }
      }

      .comment__dropdown {
        .dropdown__header {
          color: #ffffff;
          background-color: $colorSecondary200;

          &:hover {
            color: $colorWhite;
            background-color: $colorSecondary1000;
            img,
            svg {
              fill: $colorWhite;
            }
          }
          img,
          svg {
            fill: #ffffff;
          }
        }
      }
    }
  }
  .project__return {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2rem;
    .return__link {
      background-color: #ffffff;
      border-radius: 0.75rem;
      padding: 1rem 1.5rem;
      gap: 1rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      @include transition;
      &:hover {
        background-color: $colorSecondary1000;
        .return__text {
          color: $colorWhite;
        }
        img,
        svg {
          fill: #ffffff;
        }
      }
      img,
      svg {
        width: 1rem;
        height: 0.75rem;
        fill: #202639;
      }
    }
    .return__text {
      color: $colorMain1000;
      font-size: 1rem;
      line-height: 1.25rem;
      font-weight: 600;
    }
  }
}
