$colorWhite: rgba(255, 255, 255, 1);
// primary color
$colorPrimary100: #f6f6f6;
$colorPrimary200: #eeeeee;
$colorPrimary300: #8f939c;
$colorPrimary400: #202639;
$colorPrimary500: #000000;
$colorPrimary600: #e5e5e5;
$colorPrimary700: #20263980;
$colorPrimary800: #20263950;

// secondary color
$colorSecondary200: rgba(0, 208, 158, 0.08);
$colorSecondary300: #ebfbf7;
$colorSecondary400: #1aefbc;
$colorSecondary500: #00d09e;
// tertiary color
$colorTertiary300: #ffebf1;
$colorTertiary400: #ff5285;
$colorTertiary500: #ff004c;
$colorTertiary600: #ff004d;
// quarty color
$colorQuartyty200: rgba(59, 0, 96, 0.15);
$colorQuartyty300: rgba(202, 116, 254, 0.5);
$colorQuartyty400: rgba(202, 116, 254, 1);
$colorQuartyty500: #a717ff;
// fonts
$mainFont: 'Roboto', sans-serif;
$secondFont: 'Inter', sans-serif;
// mixin
@mixin transition() {
  transition: all 0.4s ease-in-out;
}
@mixin transform() {
  transform: translate(-50%, -50%);
}
@mixin transform-x() {
  transform: translateX(-50%);
}
@mixin transform-y() {
  transform: translateY(-50%);
}
@mixin transform-none() {
  transform: translate(0, 0);
}
@mixin box-shadow() {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.03);
}
@mixin box-shadowSecond() {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.25);
}
@mixin box() {
  padding: 1.5rem;
  border-radius: 1.25rem;
  background-color: $colorWhite;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.03);
}
