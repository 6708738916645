@import '../helper/media';
@import '../helper/variables';
// HEADER
.layout__header {
  padding: .75rem 0;
  background-color: $colorWhite;
  font-size: 1.125rem;
  line-height: 1.5rem;
  position: relative;
  z-index: 4;
  @include transition;
  @include mediaMinWidthTablet{
    padding: 1rem 0;
  }
  &.layout__header_menu-active {
    position: fixed;
    background-color: transparent;
    z-index: 4;
    @include mediaMaxWidthDesktop {
      .header__burger {
        .burger__item {
          width: 2rem;
          margin-bottom: 0;
          background-color: $colorWhite;

          &:nth-child(1) {
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            display: none;
          }

          &:nth-child(3) {
            margin-top: -0.25rem;
            transform: rotate(-45deg);
          }
        }
      }
      .header__layout {
        transform: translateX(0%);
      }
      & ~ * {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $colorPrimary400;
          opacity: 0.9;
          z-index: 3;
        }
      }
    }
  }

  .container {
    width: 75rem;
    max-width: calc(100vw - 2rem);
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  // LAYOUT
  .header__layout {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    @include mediaMaxWidthDesktop {
      flex-direction: column;
      align-items: flex-start;
      position: fixed;
      top: 0;
      left: 0;
      padding: 1rem 1rem 2.5rem;
      width: 80vw;
      max-width: 30rem;
      height: 100vh;
      overflow: hidden;
      overflow-y: auto;
      z-index: 4;
      background-color: $colorWhite;
      transform: translateX(-100%);
      @include transition;
    }
  }

  // LOGO
  .header__logo {
    &:not(:last-child) {
      margin-right: 4.5rem;
    }

    .logo__link {
      width: 12.5rem;
      display: flex;
      align-items: flex-start;
    }

    img,
    svg {
      width: 100%;
      height: 100%;
    }
  }

  // NAVIGATION
  .header__nav {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
      @include mediaMinWidthDesktop {
        margin-bottom: 0;
        margin-right: 0.25rem;
      }
    }

    .nav__list {
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include mediaMinWidthDesktop {
        flex-direction: row;
      }

      &:hover {
        > .nav__item {
          > .nav__link:not(.active),
          > .nav__link:not(:hover) {
            .nav__text {
              color: $colorPrimary300;
            }
          }
        }
      }

      .nav__item {
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        position: relative;

        &:not(:last-child) {
          margin-bottom: 1.5rem;
          @include mediaMinWidthDesktop {
            margin-bottom: 0;
          }
        }

        &:hover {
          @include mediaMinWidthDesktop {
            z-index: 2;
            .nav__action {
              transform: rotate(180deg);
            }
            > .nav__link {
              > .nav__text {
                color: $colorQuartyty500 !important;
              }
            }
            .nav__submenu {
              max-height: 9999px;
              padding-top: 1.5rem;
              transition-delay: 0s;
              padding-bottom: 1.5rem;
            }
          }
        }

        &.nav__item_disabled {
          > .nav__link {
            pointer-events: none;
          }
        }

        &.nav__item_open {
          @include mediaMaxWidthDesktop {
            z-index: 2;
            .nav__action {
              transform: rotate(180deg);
            }
            > .nav__link {
              > .nav__text {
                color: $colorQuartyty500 !important;
              }
            }
            .nav__submenu {
              max-height: 9999px;
              padding-top: 1.5rem;
              transition-delay: 0s;
            }
          }
        }
      }
    }

    .nav__link {
      display: flex;
      text-decoration: none;
      @include mediaMinWidthDesktop {
        padding: 0.5rem 0.75rem;
        white-space: nowrap;
      }

      &:hover,
      &.active {
        > .nav__text {
          color: $colorQuartyty500 !important;
        }
      }
    }

    .nav__text {
      color: $colorPrimary400;
      @include transition;
    }

    .nav__action {
      width: 1.5rem;
      height: 1.5rem;
      margin-left: 1rem;
      transform: rotate(0deg);
      display: flex;
      justify-content: center;
      align-items: center;
      @include transition;
      @include mediaMinWidthDesktop {
        display: none;
      }

      img,
      svg {
        width: 100%;
        height: 100%;
      }
    }

    .nav__submenu {
      max-height: 0;
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      @include transition;
      transition-delay: 0.4s;
      @include mediaMinWidthDesktop {
        padding: 0 1.5rem;
        border-radius: 1.5rem;
        background-color: $colorWhite;
        position: absolute;
        width: auto;
        top: calc(100% + 1.75rem);
        left: 50%;
        align-items: center;
        z-index: 2;
        @include transform-x;
        @include box-shadow;
      }

      &:hover {
        > .nav__item {
          > .nav__link:not(.active),
          > .nav__link:not(:hover) {
            .nav__text {
              color: $colorPrimary300;
            }
          }
        }
      }
    }
  }

  // LANGUAGES
  .header__languages {
    position: relative;
    @include mediaMaxWidthDesktop {
      order: -1;
    }

    &:not(:last-child) {
      margin-bottom: 1.5rem;
      @include mediaMinWidthDesktop {
        margin-bottom: 0;
        margin-right: 1rem;
      }

      &:hover {
        .languages__header {
          background-color: $colorPrimary400;

          .languages__action {
            transform: rotate(-90deg);

            img,
            svg {
              path {
                fill: $colorWhite;
              }
            }
          }
        }

        .languages__main {
          background-color: $colorPrimary400;

          .languages__title {
            .languages__text {
              color: $colorWhite;
            }
          }
        }
      }
    }

    &.header__languages__active {
      .languages__header {
        @include mediaMinWidthDesktop {
          border-radius: 1rem 1rem 0 0;
        }
      }
    }

    .languages__header {
      border-radius: 1.5rem;
      padding: 0.25rem 0.5rem;
      background-color: $colorPrimary600;
      cursor: pointer;
      display: flex;
      align-items: center;
      @include transition;
    }

    .languages__main {
      padding: 0.25rem 0.75rem;
      border-radius: 1.5rem;
      background-color: $colorPrimary600;
      position: absolute;
      top: 50%;
      left: calc(100% + .5rem);
      @include transform-y;
      @include transition;
      @include mediaMinWidthDesktop {
        top: calc(100% + .25rem);
        width: 100%;
        border-radius: 0 0 1rem 1rem;
        left: 0;
        transform: none;
      }

      .languages__media {
        &:not(:last-child) {
          margin-right: 0.5rem;
        }
      }
    }

    .languages__list {
      display: flex;
      flex-direction: row;
      @include mediaMinWidthDesktop {
        flex-direction: column;
      }
    }

    .languages__item {
      cursor: pointer;
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-right: 1rem;
        @include mediaMinWidthDesktop {
          margin-right: 0;
        }
      }
    }

    .languages__media {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      align-items: flex-start;

      &:not(:last-child) {
        margin-right: 0.25rem;
      }

      img,
      svg {
        width: 100%;
        height: 100%;
      }
    }

    .languages__title {
      .languages__text {
        color: $colorPrimary400;
        font-size: .875rem;
        line-height: 1.5rem;
        font-weight: 500;
        text-transform: capitalize;
        @include transition;
      }
    }

    .languages__action {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      align-items: flex-start;
      transform: rotate(0deg);
      @include transition;

      img,
      svg {
        width: 100%;
        height: 100%;
        fill: $colorPrimary400;
        @include transition;
      }
    }
  }

  // ACCOUNT
  .header__account {
    @include mediaMinWidthDesktop {
      margin-left: auto;
    }

    .account__link {
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    .account__title {
      &:not(:last-child) {
        margin-right: 0.5rem;
      }

      .title__text {
        color: $colorPrimary400;
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 500;
      }
    }

    .account__media {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      align-items: flex-start;

      img,
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  // BURGER
  .header__burger {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
    @include mediaMinWidthDesktop {
      display: none;
    }

    .burger__item {
      width: 1rem;
      background-color: $colorPrimary400;
      height: 0.125rem;
      border-radius: 0.5rem;
      @include transition;

      &:not(:last-child) {
        margin-bottom: 0.25rem;
      }
    }
  }
}
