@import 'src/style/helper/variables';

.progress {
  :root {
    --scrollbar-width: calc(100vw - 100%);
  }

  body {
    &.no-scroll {
      padding-right: var(--scrollbar-width);
      overflow: hidden;
    }
  }

  &__templates {
    margin-top: 12px;
    margin-bottom: 12px;
    padding: 16px;
    background-color: $colorPrimary600;
    border-radius: 8px;
  }

  &__template {
    padding: 12px 16px;
    margin-bottom: 10px;
    background-color: $colorWhite;
    border: 1px solid $colorPrimary300;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.25s ease;
    font-size: 14px;
    color: $colorPrimary500;
    display: block;
    width: 100%;
    white-space: normal;
    line-height: 1.4;
    word-wrap: break-word;
    text-align: left;

    &:hover {
      background-color: $colorSecondary300;
      border-color: $colorSecondary400;
      color: $colorSecondary500;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transform: translateY(-2px);
    }

    &:active {
      transform: translateY(0);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    }

    &:last-child {
      margin-bottom: 0;
    }

    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
