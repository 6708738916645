@import '../../helper/media';
@import '../../helper/variables';
// notification
.p2p__notification {
  position: fixed;
  top: 50%;
  right: 1rem;
  z-index: 11;
  @include transform-y;
  cursor: move;
  user-select: none;
  touch-action: none;

  &.dragging {
    cursor: grabbing;
    opacity: 0.9;
  }

  &.p2p__notification_active {
    .notification__layout {
      padding: 0.75rem;
      max-height: 20rem;
      overflow: hidden;
      overflow-y: auto;
    }
  }

  .notification__preview {
    height: 2.5rem;
    color: $colorWhite;
    padding-right: 0;
    background-color: $colorQuartyty500;
    border: 0.25rem solid $colorQuartyty500;
    border-radius: 1.25rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    @include transition;

    &:hover {
      padding-right: 0.5rem;

      .preview__media {
        margin-right: 0.5rem;
      }

      .preview__ico {
        fill: $colorQuartyty500;
      }

      .preview__title {
        max-width: 20rem;
      }

      .preview__text {
        opacity: 1;
        visibility: visible;
        transition-delay: 0.2s;
      }
    }
  }
  .preview__media--blink:before {
    content: '';
    position: absolute;
    top: 0.3rem;
    right: 0.25rem;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    border: 0.125rem solid $colorWhite;
    background-color: $colorSecondary500;
    animation: hide 2s ease-in-out infinite;
  }

  .preview__media {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: $colorWhite;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 0;
    @include transition;
  }

  .preview__ico {
    width: 1rem;
    height: 1rem;
    fill: $colorPrimary400;
    @include transition;
  }

  .preview__title {
    max-width: 0;
    @include transition;
  }

  .preview__text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    @include transition;
    transition-delay: 0s;
  }

  .notification__layout {
    position: absolute;
    top: 50%;
    right: 0;
    width: 18rem;
    background-color: $colorWhite;
    border-radius: 1.25rem;
    max-height: 0;
    overflow: hidden;
    padding: 0 0.75rem;
    z-index: 12;
    @include transform-y;
    @include transition;
  }

  .notification__header {
    align-items: center;
    text-align: center;
    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }
  }

  .notification__main {
    background-color: $colorPrimary100;
    border-radius: 1.25rem;
    padding: 0.75rem;
  }

  .notification__list {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  .notification__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .notification__title {
    font-weight: 500;
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  .notification__text {
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: center;
  }
}
.notification__preview {
  &:hover,
  &.active {
    padding-right: 0.5rem;

    .preview__media {
      margin-right: 0.5rem;
    }

    .preview__ico {
      fill: $colorQuartyty500;
    }

    .preview__title {
      max-width: 20rem;
    }

    .preview__text {
      opacity: 1;
      visibility: visible;
      transition-delay: 0.2s;
    }
  }
}
.notification__title___buy {
  color: $colorSecondary500;
}
.notification__title__sell {
  color: $colorTertiary500;
}

@keyframes hide {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.notification__show {
  position: fixed;
  bottom: 0.75rem;
  right: 0.75rem;
  z-index: 11;
  background-color: $colorWhite;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @include mediaMinWidthTablet {
    bottom: 1rem;
    right: 1rem;
    font-size: 0.875rem;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
  }

  @include mediaMaxWidthMobile {
    font-size: 0.75rem;
    padding: 0.5rem 0.75rem;
    border-radius: 1.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
}

.notification__footer {
  color: $colorPrimary400;
  font-size: 0.75rem;
  padding: 0.5rem;
  cursor: pointer;
  text-align: center;
  border-top: 1px solid $colorPrimary100;

  &:hover {
    text-decoration: underline;
  }
}
